const LOCAL_STORAGE = "LOCAL_STORAGE";
const SESSION_STORAGE = "SESSION_STORAGE";
const COOKIES = "COOKIES";

const getStoredValue = (fieldName, storageName) => {
  if (storageName === LOCAL_STORAGE) {
    return localStorage.getItem(fieldName);
  } else if (storageName === SESSION_STORAGE) {
    return sessionStorage.getItem(fieldName);
  }
};

const setStorageValue = (fieldName, fieldValue, storageName) => {
  if (storageName === LOCAL_STORAGE) {
    localStorage.setItem(fieldName, fieldValue);
  } else if (storageName === SESSION_STORAGE) {
    sessionStorage.setItem(fieldName, fieldValue);
  }
};

export const getDomainSettings = () => {
  return JSON.parse(getStoredValue("domainSettings", LOCAL_STORAGE));
};
export const setDomainSettings = (DonainSettingsData) => {
  setStorageValue(
    "domainSettings",
    JSON.stringify(DonainSettingsData),
    LOCAL_STORAGE
  );
};
export const getUserConfigSettings = () => {
  return JSON.parse(getStoredValue("userConfig", LOCAL_STORAGE));
};
export const setUserConfigSettings = (userConfigData) => {
  setStorageValue("userConfig", JSON.stringify(userConfigData), LOCAL_STORAGE);
};
export const setSelectedPhoneId = (selectedPhoneData) => {
  setStorageValue(
    "selectedPhoneId",
    JSON.stringify(selectedPhoneData),
    LOCAL_STORAGE
  );
};
export const getSelectedPhoneId = () => {
  return JSON.parse(getStoredValue("selectedPhoneId", LOCAL_STORAGE));
};
export const getStorageObj = () => {
  return JSON.parse(getStoredValue("storageObj", LOCAL_STORAGE));
};
export const setSorageObj = (storageObjData) => {
  setStorageValue("storageObj", JSON.stringify(storageObjData), LOCAL_STORAGE);
};

export const clearLocalStorage = (excludeKeys) => {
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (!excludeKeys.includes(key)) {
      localStorage.removeItem(key);
      i--;
    }
  }
};

import { Button, Card, Col, Form, Input, Row, notification } from 'antd';
import { useImperativeHandle, useState } from 'react';
import { TEXT_AREA_SIZE } from '../../constants/SizeLimit';
import MessagePreview from '../MessagePreview/MessagePreview';
const { TextArea } = Input;

const SendTextMessage = ({ childFormRef, formData, messageForm,isHideField = false }) => {
	const [textValue, setTextValue] = useState('');
	const [variableCount,setVariableCount] = useState(0);
	const resetformData = () => {
		setTextValue('');
	};
	const getFormData = () => {
		return new Promise((resolve, reject) => {
			messageForm
				.validateFields()
				.then((values) => {
					if (!textValue) {
						notification.error({
							message: 'Error',
							description: 'Please Enter Text Message!!',
						});
						return true;
					}
					formData.append('text', textValue);
					if(isHideField && variableCount === 1) {
						formData.append('header_text', values.header_text);
					}
					resolve(formData);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	useImperativeHandle(childFormRef, () => ({
		formData: getFormData,
		resetData: resetformData,
	}));
	const textAreaChangeHandler = (e) => {
		setTextValue(e.target.value);
		if(isHideField) {
			const str = e.target.value
			const openingCount = (str.match(/[\(\[\{]/g) || []).length;
			const closingCount = (str.match(/[\)\]\}]/g) || []).length;
			if(openingCount !== closingCount) {
				setVariableCount(0);
			} else if(openingCount!== 0 && closingCount !== 0) {
				setVariableCount(1)
			}
		}
	};
	const addVariableHandler = () => {
		setVariableCount(1);
		let updatedTextValue = textValue;
		const countText = `{{${1}}}`
		updatedTextValue = updatedTextValue ?  updatedTextValue + ' ' + countText : countText;
		setTextValue(updatedTextValue);
	}

	return (
		<div
			style={{
				padding: '20px 0px',
			}}
		>
			<Row style={{ display: 'flex', justifyContent: 'space-between' }}>
				<Col xs={24} sm={15} md={15} lg={15} xl={15}>
					<Card style={{ width: '100%', marginBottom: 20 }}>
						<Form.Item>
							<TextArea
								showCount
								maxLength={TEXT_AREA_SIZE}
								placeholder={`Enter text message upto ${TEXT_AREA_SIZE}`}
								style={{
									height: 120,
									resize: 'none',
								}}
								value={textValue}
								onChange={textAreaChangeHandler}
							/>
						</Form.Item>
						{isHideField &&
						<>
						 <Form.Item>
							<Button onClick={addVariableHandler} disabled = {variableCount === 1}>Add Variable</Button>
						</Form.Item>
						{variableCount === 1 && 
							<Form.Item name="header_text" label = "{{1}}">
								<Input placeholder='Enter Conter for {{1}}'/>
							</Form.Item>
						}
						</>
						}
					</Card>
				</Col>
				{!isHideField && <MessagePreview type="Text" payload={{ textValue: textValue }} title="Preview" />}
			</Row>
		</div>
	);
};

export default SendTextMessage;

import { Form, Input, InputNumber } from 'antd';

const CustomForm = (props) => {
	const { label, isRequired, name, placeholder, prefix, requiredMessage, inputType, addonBefore, width } = props.data;
	const {validateRule} = props;
	return (
		<Form.Item
			label={label}
			name={name}
			rules={[
				{
					required: isRequired,
					message: requiredMessage,
				},
				{
					validator: inputType === 'Password' && validateRule ? validateRule : undefined,
				},
			]}
		>
			{inputType === 'Normal' && <Input prefix={prefix} placeholder={placeholder} style={{ width: width }} />}
			{inputType === 'Password' && <Input.Password prefix={prefix} placeholder={placeholder} style={{ width: width }} />}
			{inputType === 'Number' && <InputNumber addonBefore={addonBefore} prefix={prefix} placeholder={placeholder} style={{ width: width }} />}
		</Form.Item>
	);
};

export default CustomForm;

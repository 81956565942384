import { Divider, Input, Upload, notification } from "antd";
import { useState } from "react";

import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  MAX_AUDIO_SIZE,
  MAX_DOCUMENT_SIZE,
  MAX_IMAGE_SIZE,
  MAX_VIDEO_SIZE,
} from "../../../../constants/SizeLimit";
import "./UploadFile.css";
const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};
const UploadFile = (props) => {
  const [fileName, setFileName] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [inputValue, setInputValue] = useState("");
  const tabName = useSelector((state) => state.selectedTab);
  useEffect(() => {
    setFileName("");
    setFileUrl("");
    setInputValue("");
  }, [tabName, props.resetValue]);
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });
  const handleFileChange = async (info) => {
    const file = info.file;
    if (file) {
      // Check file type based on MIME type or file extension
      const fileType = file.type || file.name.split(".").pop();

      // Perform checks for different file types
      if (
        tabName === "Image" ||
        tabName === "IMAGE" ||
        props.documentUplod === "image"
      ) {
        if (!fileType.startsWith("image/")) {
          notification.error({
            message: "Error",
            description: "Please upload Image File Only..",
          });
          return;
        }
        if (file.size > MAX_IMAGE_SIZE * 1024 * 1024) {
          notification.error({
            message: "Error",
            description: "Maximum Image Size is 5MB",
          });
          return;
        }
      }
      if (
        tabName === "Video" ||
        tabName === "VIDEO" ||
        props.documentUplod === "video"
      ) {
        if (!fileType.startsWith("video/")) {
          notification.error({
            message: "Error",
            description: "Please upload Video File Only..",
          });
          return;
        }
        if (file.size > MAX_VIDEO_SIZE * 1024 * 1024) {
          notification.error({
            message: "Error",
            description: "Maximum Video Size is 16MB",
          });
          return;
        }
      }
      if (tabName === "Audio" || props.documentUplod === "audio") {
        if (!fileType.startsWith("audio/")) {
          notification.error({
            message: "Error",
            description: "Please upload Audio File Only..",
          });
          return;
        }
        if (file.size > MAX_AUDIO_SIZE * 1024 * 1024) {
          notification.error({
            message: "Error",
            description: "Maximum Audio Size is 16MB",
          });
          return;
        }
      }
      if (
        tabName === "Document" ||
        tabName === "DOCUMENT" ||
        props.documentUplod === "document"
      ) {
        if (!fileType.startsWith("application/")) {
          notification.error({
            message: "Error",
            description: "Please upload Document File Only..",
          });
          return;
        }
        if (file.size > MAX_DOCUMENT_SIZE * 1024 * 1024) {
          notification.error({
            message: "Error",
            description: "Maximum Audio Size is 16MB",
          });
          return;
        }
      }
    }
    var reader = new FileReader();
    reader.onload = function (e) {
      // Set the 'src' attribute of the <img> tag to the data URL of the uploaded image
      setFileUrl(e.target.result);
      setFileName(info.fileList[info.fileList.length - 1].name);
      props.uploadFileChangeHandler(
        info.fileList[info.fileList.length - 1],
        true,
        e.target.result,
        props.sectionName ? props.sectionName : ""
      );
    };
    reader.readAsDataURL(file);
  };
  const changeInputHandler = (e) => {
    setInputValue(e.target.value);
  };
  const inputBlurHandler = () => {
    if (!fileName && inputValue) {
      props.uploadFileChangeHandler(
        inputValue,
        false,
        fileUrl,
        props.sectionName ? props.sectionName : ""
      );
    } else if (fileName && inputValue) {
      notification.error({
        message: "Error",
        description: "Please Enter Either File Or URL..",
      });
    }
  };
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid",
        flexDirection: "column",
        padding: 20,
        marginBottom: 10,
      }}
    >
      <Upload
        customRequest={dummyRequest}
        name="document"
        listType="picture-circle"
        className="document-uploader"
        showUploadList={false}
        beforeUpload={() => false}
        onChange={handleFileChange}
      >
        {fileName ? (
          <a
            href={fileUrl}
            target="_blank"
            style={{ width: "100%", wordWrap: "break-word", padding: 5 }}
            rel="noopener noreferrer"
          >
            {fileName}
          </a>
        ) : (
          <p>{props.uploadFileName}....</p>
        )}
      </Upload>
      {!props.isHideField && (
        <>
          <Divider>OR</Divider>

          <div
            style={{ width: "100%", display: "flex", flexDirection: "column" }}
          >
            <label style={{ marginBottom: 5 }}>{props.tabName} URL:</label>
            <Input
              style={{ flex: 1 }}
              placeholder="Enter URL..."
              onChange={changeInputHandler}
              disabled={fileName}
              value={inputValue}
              onBlur={inputBlurHandler}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default UploadFile;

import { CheckCircleOutlined, CloseCircleOutlined, SyncOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

const statusColorIconMap = {
	accepted: { color: 'default', icon: <SyncOutlined /> },
	sent: { color: 'blue', icon: <CheckCircleOutlined /> },
	delivered: { color: 'lime', icon: <CheckCircleOutlined /> },
	read: { color: 'green', icon: <CheckCircleOutlined /> },
	failed: { color: 'red', icon: <CloseCircleOutlined /> },
	deleted: { color: 'gray' },
};

export const DEFAULT_PAGE_SIZE = 10;
export const rangePresets = [
	{ label: 'Last 5 Min', value: [dayjs().add(-5, 'minutes'), dayjs()] },
	{ label: 'Last 1 Hour', value: [dayjs().add(-1, 'hour'), dayjs()] },
	{ label: 'Last 6 Hours', value: [dayjs().add(-6, 'hours'), dayjs()] },
	{ label: 'Last 1 Day', value: [dayjs().add(-1, 'day'), dayjs()] },
	{ label: 'Last 7 Days', value: [dayjs().add(-7, 'day'), dayjs()] },
];
export const TypeFilerDropDownValue = [
	{ text: 'audio', value: 'audio' },
	{ text: 'button', value: 'button' },
	{ text: 'contacts', value: 'contacts' },
	{ text: 'document', value: 'document' },
	{ text: 'location', value: 'location' },
	{ text: 'text', value: 'text' },
	{ text: 'template', value: 'template' },
	{ text: 'image', value: 'image' },
	{ text: 'interactive', value: 'interactive' },
	{ text: 'order', value: 'order' },
	{ text: 'reaction', value: 'reaction' },
	{ text: 'sticker', value: 'sticker' },
	{ text: 'video', value: 'video' },
];

export const StatusFilterDropDownValue = [
	{ text: 'accepted', value: 'accepted' },
	{ text: 'sent', value: 'sent' },
	{ text: 'delivered', value: 'delivered' },
	{ text: 'read', value: 'read' },
	{ text: 'failed', value: 'failed' },
	{ text: 'deleted', value: 'deleted' },
];

export const getColorWithIconCode = (status) => {
	return statusColorIconMap[status] || { color: '', icon: null };
};

import { Flex, Table, Typography } from "antd";
import {
  getDateFromTimeStamp,
  toTimestamp,
} from "../../constants/DateConstants";
import { useEffect, useRef, useState } from "react";
import { postApi } from "../../constants/ApiConstant";
import { InitialTablePostData } from "../../constants/Constant";
import { DEFAULT_PAGE_SIZE } from "../../constants/ReportConstant";
import {
  auditByFilterJSON,
  auditTypeFilterJSON,
  getDateColumnSearchProps,
  getTextColumnSearchProps,
} from "./AuditConstant";
import { useLocation } from "react-router-dom";

const Audit = () => {
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [auditTableData, setAuditTableData] = useState([]);
  const [auditTableParams, setAuditrTableParams] =
    useState(InitialTablePostData);
  const [value, setValue] = useState(null);
  const [dates, setDates] = useState(null);
  const location = useLocation();
  const customerId = location?.state?.Id || "";
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    setAuditrTableParams({
      ...auditTableParams,
      search: [{ fieldName: dataIndex, value: selectedKeys[0] }],
    });
  };
  const columns = [
    {
      title: "Creation Time",
      dataIndex: "creationTime",
      key: "creationTime",
      ...getDateColumnSearchProps(
        "creationTime",
        "Creation Time",
        setDates,
        setValue,
        handleSearch
      ),
      sorter: (a, b) => a.creationTime - b.creationTime,
      render: (_, record) => getDateFromTimeStamp(record.creationTime),
    },
    {
      title: "Customer Id",
      dataIndex: "customerId",
      key: "customerId",
      sorter: (a, b) => a.customerId - b.customerId,
      ...getTextColumnSearchProps(
        "customerId",
        "Customer Id",
        searchInput,
        handleSearch
      ),
    },
    {
      title: "PhoneNumber Id",
      dataIndex: "phoneNumberId",
      key: "phoneNumberId",
      sorter: (a, b) => a.phoneNumberId - b.phoneNumberId,
      ...getTextColumnSearchProps(
        "phoneNumberId",
        "PhoneNumber Id",
        searchInput,
        handleSearch
      ),
    },
    {
      title: "Audit type",
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => a.type - b.type,
      filters: auditTypeFilterJSON,
    },
    {
      title: "Audit by",
      dataIndex: "by",
      key: "by",
      sorter: (a, b) => a.by - b.by,
      filters: auditByFilterJSON,
    },
    {
      title: "Old value",
      dataIndex: "oldValue",
      key: "oldValue",
      sorter: (a, b) => a.oldValue - b.oldValuedValue,
      ...getTextColumnSearchProps(
        "oldValue",
        "Old value",
        searchInput,
        handleSearch
      ),
    },
    {
      title: "New value",
      dataIndex: "newValue",
      key: "newValue",
      sorter: (a, b) => a.newValue - b.newValue,
      ...getTextColumnSearchProps(
        "newValue",
        "New value",
        searchInput,
        handleSearch
      ),
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
      sorter: (a, b) => a.comment - b.comment,
      ...getTextColumnSearchProps(
        "comment",
        "Comment",
        searchInput,
        handleSearch
      ),
    },
  ];
  useEffect(() => {
    const updataedParams = { ...auditTableParams };
    const paginationObj = {
      current: updataedParams?.pagination?.current ?? 1,
      pageSize: updataedParams?.pagination?.pageSize ?? DEFAULT_PAGE_SIZE,
    };
    updataedParams.pagination = paginationObj;
    updataedParams.search = [];
    if (customerId) {
      updataedParams.search.push({
        fieldName: "customerId",
        value: [customerId],
      });
    }
    getAuditTableData(updataedParams);
  }, [customerId]);
  const getAuditTableData = async (obj) => {
    const response = await postApi("/api/v1/admin/audit", obj);
    if (response && response.status === 200) {
      setAuditTableData(response.data.results);
      setAuditrTableParams({
        ...auditTableParams,
        pagination: {
          ...obj.pagination,
          total: response.data.total,
        },
        search: obj.search,
        order: obj.order,
      });
    }
  };
  const handleTableChange = (pagination, filters, sorter) => {
    const paginationObj = {
      current: pagination?.current ?? 1,
      pageSize: pagination?.pageSize ?? DEFAULT_PAGE_SIZE,
    };
    const sortObj = sorter.order
      ? [
          {
            fieldName: sorter.columnKey,
            dir: sorter.order === "descend" ? "desc" : "asc",
          },
        ]
      : InitialTablePostData.order;
    const searchObj = Object.entries(filters ?? {})
      .map(([key, value]) => ({
        fieldName: key,
        value:
          value !== null
            ? key !== "creationTime"
              ? value
              : value.map(toTimestamp)
            : null,
      }))
      .filter(({ value }) => value);
    setAuditrTableParams({
      ...auditTableParams,
      pagination: paginationObj,
      search: searchObj,
      order: sortObj,
    });
    getAuditTableData({
      pagination: paginationObj,
      search: searchObj,
      order: sortObj,
    });
  };
  return (
    <Flex vertical gap={"large"}>
      <Flex justify="space-between">
        <Typography.Title level={4} style={{ margin: 0 }}>
          Audit List
        </Typography.Title>
      </Flex>
      <Table
        size="small"
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={auditTableData}
        scroll={{
          x: 1600,
        }}
        onChange={handleTableChange}
        pagination={{
          ...auditTableParams.pagination,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]} - ${range[1]} of ${total} records`,
        }}
      ></Table>
    </Flex>
  );
};

export default Audit;

import { Card } from 'antd';
import TemplateBody from './TemplateBody';
import TemplateButton from './TemplateButton';
import TemplateHeader from './TemplateHeader';
import { handleBodyChange, handleButtonChange, handleHeaderChange } from './TemplateUtil';

const allowdButtonValue = ['QUICK REPLY', 'URL'];
const TemplateCarousel = ({ templateData, setTemplateData }) => {
	return (
		<>
			{Object.keys(templateData).map((rec, index) => (
				<Card
					title={`Card ${index + 1}`}
					key={rec}
					style={{
						padding: '0px 30px 0px 10px',
						margin: '20px 0px',
					}}
				>
					<TemplateHeader
						key={`HEADER_${rec}`}
						allowdDropDownValue={['Video', 'Image']}
						label="Card Header"
						templateHeaderData={templateData[rec].templateHeader}
						setTemplateHeaderData={(updatedHeader) => handleHeaderChange(rec, updatedHeader, templateData, setTemplateData)}
						id={rec}
					/>
					<TemplateBody
						key={`BODY_${rec}`}
						templateBodyData={templateData[rec].templateBody}
						label="Card Body"
						setTemplateBodyData={(updatedBody) => handleBodyChange(rec, updatedBody, templateData, setTemplateData)}
						id={rec}
					/>
					<TemplateButton
						allowdButtonValue={allowdButtonValue}
						key={`BUTTON_${rec}`}
						templateButtonData={templateData[rec].templateButton}
						setTemplateButtonData={(updatedButton) => handleButtonChange(rec, updatedButton, templateData, setTemplateData)}
						buttonLabel="Add Card Buttons"
						id={rec}
					/>
				</Card>
			))}
		</>
	);
};

export default TemplateCarousel;

import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Descriptions,
  Divider,
  Flex,
  Input,
  Row,
  Space,
  Table,
  Tag,
  Timeline,
  Typography,
  notification,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getColorCode } from "../../constants/Constant";
import {
  getDateFromTimeStamp,
  toTimestamp,
} from "../../constants/DateConstants";
import {
  DEFAULT_PAGE_SIZE,
  TypeFilerDropDownValue,
  // StatusFilterDropDownValue,
  getColorWithIconCode,
  rangePresets,
} from "../../constants/ReportConstant";
import MessageReportingPreview from "../MessagePreview/MessageReporingPreview";
import { postApi } from "../../constants/ApiConstant";
import OutSendMessagePreview from "../MessagePreview/OutSendMessagePreview";
import { useLocation } from "react-router-dom";

const { RangePicker } = DatePicker;

const Report = () => {
  const [tableData, setTableData] = useState([]);
  const InitialTablePostData = {
    pagination: { current: 1, pageSize: DEFAULT_PAGE_SIZE },
    order: [{ fieldName: "creationTime", dir: "desc" }],
    search: [
      {
        fieldName: "creationTime",
        value: [new Date().setDate(new Date().getDate() - 30), "now"],
      },
    ],
  };
  const [tableParams, setTableParams] = useState(InitialTablePostData);
  const selectedPhoneRec = useSelector((state) => state.selectedPhoneRec);
  const [loading, setLoading] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [value, setValue] = useState(null);
  const [dates, setDates] = useState(null);
  const searchInput = useRef(null);
  const location = useLocation();
  const phoneNumberId = location?.state?.Id || "";
  const disabledDate = (current) => {
    if (!dates) return false;
    const tooLate = dates[0] && current.diff(dates[0], "days") >= 30;
    const tooEarly = dates[1] && dates[1].diff(current, "days") >= 30;
    return tooEarly || tooLate;
  };
  const getTextColumnSearchProps = (dataIndex, name) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <Flex
        gap="small"
        vertical
        style={{ padding: 8 }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${name}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
          >
            Search
          </Button>
          <Button type="link" onClick={() => close()} size="small">
            Close
          </Button>
        </Space>
      </Flex>
    ),
  });

  const getDateColumnSearchProps = (dataIndex, name) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <Flex
        gap="small"
        vertical
        style={{ padding: 8 }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <RangePicker
          presets={[...rangePresets]}
          disabledDate={disabledDate}
          showTime
          onCalendarChange={(val) => setDates(val)}
          format="YYYY-MM-DD HH:mm:ss"
          onChange={(dates, dateStrings) => {
            setSelectedKeys([dateStrings[0], dateStrings[1]]);
            setValue(dates);
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button type="link" size="small" onClick={() => close()}>
            Close
          </Button>
        </Space>
      </Flex>
    ),
  });
  const columns = [
    {
      title: "To",
      dataIndex: "to",
      key: "to",
      ...getTextColumnSearchProps("to", "To"),
    },
    {
      title: "Creation Time",
      dataIndex: "creationTime",
      key: "creationTime",
      sorter: true,
      defaultSortOrder: "descend",
      defaultFilteredValue: [dayjs().add(-30, "day"), dayjs()],
      ...getDateColumnSearchProps("creationTime", "Creation Time"),
      render: (_, record) => getDateFromTimeStamp(record.creationTime),
    },
    {
      title: "Type",
      dataIndex: ["message", "type"],
      key: "type",
      filters: TypeFilerDropDownValue,
      onFilter: (value, record) => {
        return record.message.type === value;
      },
      sorter: true,
    },
    {
      title: "Last Status",
      dataIndex: "lastStatus",
      key: "lastStatus",
      // filters: StatusFilterDropDownValue,
      render: (lastStatus) => {
        const color = getColorCode(lastStatus);
        return (
          <Tag color={color} key={lastStatus}>
            {lastStatus?.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: "Whatsapp Id",
      dataIndex: "waId",
      key: "waId",
      ...getTextColumnSearchProps("waId", "Whatsapp Id"),
      render: (text) => (
        <Typography.Text style={{ overflow: "wrap" }}>{text}</Typography.Text>
      ),
    },
    {
      title: "System Id",
      dataIndex: "id",
      key: "id",
      ...getTextColumnSearchProps("id", "System Id"),
      render: (text) => (
        <Typography.Text style={{ overflow: "wrap" }}>{text}</Typography.Text>
      ),
    },
  ];

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  useEffect(() => {
    getTableData(tableParams);
  }, [selectedPhoneRec, phoneNumberId]);
  const handleTableChange = (pagination, filters, sorter) => {
    const paginationObj = {
      current: pagination?.current ?? 1,
      pageSize: pagination?.pageSize ?? DEFAULT_PAGE_SIZE,
    };
    const sortObj =
      Object.keys(sorter).length !== 0
        ? [
            {
              fieldName: sorter.columnKey,
              dir: sorter.order === "descend" ? "desc" : "asc",
            },
          ]
        : InitialTablePostData.order;
    const searchObj = Object.entries(filters ?? {})
      .map(([key, value]) => ({
        fieldName: key,
        value:
          value !== null
            ? key !== "creationTime"
              ? value
              : value.map(toTimestamp)
            : null,
      }))
      .filter(({ value }) => value);

    getTableData({
      pagination: paginationObj,
      search: searchObj,
      order: sortObj,
    });
  };

  const getTimelineData = (record) => {
    return record.statuses.map((status) => {
      const { color, icon } = getColorWithIconCode(status.status);
      return {
        color: color,
        label: status.status,
        children: getDateFromTimeStamp(status.timestamp * 1000),
        dot: icon,
      };
    });
  };

  const getErrorDescription = (error) => {
    let returnArray = [
      { label: "Error Code", children: error.code },
      { label: "Error Message", children: error.message },
    ];
    if (error.errorData) {
      returnArray.push({
        label: "Error Details",
        children: error.errorData.details,
      });
    }
    return returnArray;
  };
  const getTableData = async (obj) => {
    const payload = {
      ...obj,
      pagination: {
        current: obj.pagination.current,
        pageSize: obj.pagination.pageSize,
      },
    };
    let url = "/api/v1/whatsapp/report/sentMessages";
    if (phoneNumberId) {
      url = `/api/v1/admin/report/${phoneNumberId}`;
    }
    try {
      setLoading(true);
      const response = await postApi(url, payload);
      if (
        response &&
        response.data &&
        response.data.results &&
        response.data.results.length > 0
      ) {
        setLoading(false);
        setTableData([...response.data.results]);
        setTableParams({
          ...tableParams,
          pagination: {
            ...obj.pagination,
            total: response.data.total,
          },
        });
      } else {
        setTableData([]);
      }
    } catch (error) {
      setTableData([]);
    } finally {
      setLoading(false);
    }
  };
  const [expandabledata, setExpandableData] = useState({});
  const handleRowExpand = (expanded, record) => {
    setExpandableData({});
    if (expanded) {
      let data = {
        type: record?.message?.type,
      };
      if (data.type === "template") {
        data.language = record?.message?.template.language?.code;
        data.name = record?.message?.template.name;
      }
    }
  };
  return (
    <Flex vertical gap={"large"}>
      <Typography.Title level={4} style={{ margin: "0" }}>
        Sent Items Report
      </Typography.Title>
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={{
          ...tableParams.pagination,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]} - ${range[1]} of ${total} records`,
        }}
        loading={loading}
        onChange={handleTableChange}
        expandable={{
          onExpand: handleRowExpand,
          expandedRowRender: (record) => (
            <Row style={{ alignItems: "center" }}>
              {record.statuses && (
                <Col span={9} offset={1}>
                  <Card hoverable>
                    <Timeline mode={"left"} items={getTimelineData(record)} />
                  </Card>
                </Col>
              )}
              {record.statuses && record.error && (
                <Col span={4}>
                  <Divider
                    type="vertical"
                    style={{ borderColor: "black", height: "100%" }}
                  ></Divider>
                </Col>
              )}
              {record.error && (
                <Col span={7} offset={1}>
                  <Card>
                    <Descriptions
                      size="small"
                      column={1}
                      bordered
                      items={getErrorDescription(record.error)}
                    ></Descriptions>
                  </Card>
                </Col>
              )}

              <Col span={10} offset={1}>
                <OutSendMessagePreview data={record.message} />
                {/* <MessageReportingPreview data={record.message} /> */}
              </Col>
            </Row>
          ),
        }}
        rowKey={(record) => record.id}
      />
    </Flex>
  );
};

export default Report;

import { useEffect, useState } from "react";
import { postApi } from "../../constants/ApiConstant";
import { EnterOutlined } from "@ant-design/icons";
import { Button, Carousel, Divider, Image } from "antd";
import ImageMessagePreview from "./ImageMessagePreview";
import VideoMessagePreview from "./VideoMessagePreview";
import DocumentMessagePreview from "./DocumentMessagePreview";
import CarouselMessagePreview from "./CarouselMessagePreview";
import { useDispatch, useSelector } from "react-redux";
import { setMessageData } from "../../Store/action";
const initialMediaLoad = {
  image: false,
  video: false,
  document: false,
  audio: false,
  text: false,
};

const TemplateMessagePreview = ({ data, isNotLoadingData = false }) => {
  const [typeParameterMap, setTypeParameterMap] = useState({});
  const [formattedMessageData, setFormattedMessageData] = useState("");
  const [previewButtonJSON, setPreviewButtonJSON] = useState([]);
  const [isMediaUpload, setMediaUpload] = useState(initialMediaLoad);
  const [mediaUrl, setMediaUrl] = useState("");
  const [footerdata, setFooterData] = useState({});
  const [isCarouselType, setIsCarouselType] = useState(false);
  const [carouselData, setCarouselData] = useState([]);
  const regex = /\{\{(\d+)\}\}/g;
  const createPreviewMessage = (Data) => {
    setFormattedMessageData("");
    setPreviewButtonJSON([]);
    setMediaUrl("");
    setFormattedMessageData((prevFormattedMessage) => {
      let formattedMessage = prevFormattedMessage;
      if (Data && Data.length > 0) {
        for (let i = 0; i < Data.length; i++) {
          if (
            Data[i].type === "HEADER" &&
            typeParameterMap["header"] !== null
          ) {
            if (typeParameterMap["header"][0].type === "image") {
              const updatedMediaMessage = { ...isMediaUpload };
              updatedMediaMessage["image"] = true;
              setMediaUrl(Data[i].example.header_handle[0]);
              setMediaUpload(updatedMediaMessage);
            } else if (typeParameterMap["header"][0].type === "document") {
              const updatedMediaMessage = { ...isMediaUpload };
              updatedMediaMessage["document"] = true;
              setMediaUrl(Data[i].example.header_handle[0]);
              setMediaUpload(updatedMediaMessage);
            } else if (typeParameterMap["header"][0].type === "video") {
              const updatedMediaMessage = { ...isMediaUpload };
              updatedMediaMessage["video"] = true;
              setMediaUrl(Data[i].example.header_handle[0]);
              setMediaUpload(updatedMediaMessage);
            } else if (
              Data[i].type === "HEADER" &&
              typeParameterMap["header"][0].type === "text"
            ) {
              formattedMessage +=
                "\n" +
                Data[i].text.replace(regex, (match, capture) => {
                  const variableIndex = parseInt(capture, 10);
                  if (
                    typeParameterMap["header"][variableIndex - 1].type ===
                    "text"
                  ) {
                    return (
                      typeParameterMap["header"][variableIndex - 1].text ||
                      match
                    );
                  }
                });
            }
          } else if (
            Data[i].type === "HEADER" &&
            typeParameterMap["header"] === null
          ) {
            formattedMessage += "\n" + Data[i].text;
          } else if (Data[i].type === "BODY") {
            formattedMessage +=
              "\n" +
              Data[i].text.replace(regex, (match, capture) => {
                const variableIndex = parseInt(capture, 10);
                if (Data[i].type === "BODY") {
                  if (
                    typeParameterMap["body"][variableIndex - 1].type === "text"
                  ) {
                    return (
                      typeParameterMap["body"][variableIndex - 1].text || match
                    );
                  }
                }
              });
          } else if (Data[i].type === "BUTTONS") {
            setPreviewButtonJSON(Data[i].buttons);
          }
        }

        return formattedMessage;
      }
    });
  };
  const dispatch = useDispatch();
  const messageData = useSelector((state) => state.messageData);
  useEffect(() => {
    if (!isNotLoadingData) {
      const postData = {
        language: data.language.code,
        name: data.name,
      };

      postApi("api/v1/messageTemplate/getTemplateDetail", postData).then(
        (response) => {
          if (response) {
            const footer = response.data.components.find((rec) => {
              return rec.type === "FOOTER";
            });
            setFooterData(footer);
            dispatch(setMessageData(response.data.components));
            data.components.forEach((item) => {
              if (item.type === "carousel") {
                setCarouselData(item);
                setIsCarouselType(true);
              } else {
                typeParameterMap[item.type] = item.parameters;
              }
            });
            createPreviewMessage(response.data.components);
          }
        }
      );
    }
  }, []);
  useEffect(() => {
    if (isNotLoadingData) {
      data.components.forEach((item) => {
        typeParameterMap[item.type] = item.parameters;
      });
      const filterRec = messageData.filter((rec) => {
        return rec.type === "CAROUSEL";
      });
      if (filterRec.length === 1) {
        createPreviewMessage(filterRec[0].cards[data.card_Index].components);
      }
    }
  }, [data]);

  const getButtonTemplate = (rec, index) => {
    let template = "";
    if (rec.type === "URL") {
      template = (
        <a href={rec.url} target="_blank">
          {" "}
          <b>{rec.text}</b>
        </a>
      );
      return template;
    } else {
      template = (
        <div style={{ display: "flex" }}>
          <span className="arrow-image"></span>
          <a href={rec.url} target="_blank">
            {" "}
            <b>{rec.text}</b>
          </a>
        </div>
      );
      return template;
    }
  };
  return (
    <div>
      {isMediaUpload.image && <ImageMessagePreview data={{ link: "" }} />}
      {isMediaUpload.video && <VideoMessagePreview data={{ link: "" }} />}
      {isMediaUpload.document && <DocumentMessagePreview data={{ link: "" }} />}
      <pre className="formatedMessage">{formattedMessageData}</pre>
      <div className="preview-footer"> {footerdata?.text}</div>
      {previewButtonJSON.length > 0 && (
        <div className="preview-button-contianer">
          {previewButtonJSON.map((rec, index) => {
            return (
              <>
                <Divider style={{ margin: "10px 0px" }} />
                {getButtonTemplate(rec, index)}
              </>
            );
          })}
        </div>
      )}
      {isCarouselType && (
        <Carousel autoplay arrows={false} dots={false}>
          {carouselData.cards.map((rec) => {
            return <CarouselMessagePreview data={rec} />;
          })}
        </Carousel>
      )}
    </div>
  );
};

export default TemplateMessagePreview;

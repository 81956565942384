import { DeleteOutlined } from "@ant-design/icons";
import { Button, Card, Col, Dropdown, Form, Input, Row, Select } from "antd";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";

const TemplateButton = ({
  allowdButtonValue,
  templateButtonData,
  id,
  setTemplateButtonData,
  hideAddButton = false,
  buttonLabel = "Add Buttons",
}) => {
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("in");

  const buttonJSON = allowdButtonValue.map((rec) => {
    return {
      key: rec.toUpperCase(),
      value: rec.toUpperCase(),
      label: rec + " BUTTON",
      id: rec.toUpperCase(),
    };
  });
  const onFooterButtonChangeHandler = (selectedValue) => {
    let obj = {
      type: selectedValue.key,
      id: Math.floor(Math.random() * 2000),
    };
    const updatedTemplateJSON = JSON.parse(JSON.stringify(templateButtonData));
    updatedTemplateJSON.push(obj);
    setTemplateButtonData(updatedTemplateJSON);
  };

  const menuProps = {
    items: buttonJSON,
    onClick: onFooterButtonChangeHandler,
  };

  const handleButtonChange = (selectedFieldID, field, value) => {
    const newSelectedList = templateButtonData.map((rec) => {
      if (rec.id === selectedFieldID) {
        rec[field] = value;
      }
      return rec;
    });
    setTemplateButtonData(newSelectedList);
  };
  const onRemoveButtonHandler = (id) => {
    const filterButtonJSON = templateButtonData.filter((rec) => {
      return rec.id !== id;
    });
    setTemplateButtonData(filterButtonJSON);
  };

  return (
    <>
      <Form.Item wrapperCol={{ offset: 21 }}>
        {!hideAddButton && (
          <Dropdown menu={menuProps}>
            <Button type="primary">{buttonLabel}</Button>
          </Dropdown>
        )}
      </Form.Item>
      {templateButtonData.length > 0 && (
        <Card title="Buttons">
          {templateButtonData.map((rec, index) => {
            if (
              rec.type === "QUICK REPLY" ||
              rec.type === "FLOW" ||
              rec.type === "CATALOG"
            ) {
              return (
                <Card
                  title={`${rec.type} Button`}
                  key={`BUTTON ${index}`}
                  style={{
                    marginBottom: 15,
                  }}
                >
                  <Form.Item
                    label={`${rec.type.toLowerCase()} Button`}
                    style={{ textTransform: "capitalize" }}
                  >
                    <Row gutter={8} align="middle">
                      <Col flex="auto">
                        <Input
                          value={rec.text || ""}
                          placeholder="Enter Button Text"
                          onChange={(e) =>
                            handleButtonChange(rec.id, "text", e.target.value)
                          }
                        />
                      </Col>
                      <Col flex="none">
                        <DeleteOutlined
                          style={{ fontSize: 20 }}
                          onClick={() => onRemoveButtonHandler(rec.id)}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                </Card>
              );
            } else if (rec.type === "URL") {
              return (
                <>
                  <Card
                    title="Url Button"
                    key={`urlButton${index}${id}`}
                    style={{
                      flexDirection: "column",
                      marginBottom: 15,
                    }}
                  >
                    <Form.Item
                      name={`urlButtonText${index}${id}`}
                      label="Button Text"
                      style={{ flex: 1 }}
                    >
                      <Input
                        value={rec.urlButtonText || ""}
                        placeholder="Enter Button Text"
                        onChange={(e) =>
                          handleButtonChange(
                            rec.id,
                            "urlButtonText",
                            e.target.value
                          )
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      name={`urlType${index}${id}`}
                      label="Button Url type"
                      style={{ flex: 1 }}
                    >
                      <Select
                        value={rec.urlType || ""}
                        showSearch
                        onChange={(e) =>
                          handleButtonChange(rec.id, "urlType", e)
                        }
                      >
                        <Select.Option value="static">Static</Select.Option>
                        <Select.Option value="dynamic">Dynamic</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name={`websiteUrl${index}${id}`}
                      label="WebSite Url"
                      style={{ flex: 1 }}
                    >
                      <Input
                        value={rec.WebSiteUrl || ""}
                        placeholder="Enter URl"
                        onChange={(e) =>
                          handleButtonChange(
                            rec.id,
                            "WebSiteUrl",
                            e.target.value
                          )
                        }
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: 10,
                        }}
                      >
                        <DeleteOutlined
                          style={{ fontSize: 20 }}
                          onClick={() => onRemoveButtonHandler(rec.id)}
                        />
                      </div>
                    </Form.Item>
                  </Card>
                </>
              );
            } else if (rec.type === "PHONE_NUMBER") {
              return (
                <Card
                  title="Phone Number Button"
                  key={`phoneNumber${index}${id}`}
                  style={{
                    flexDirection: "column",
                    marginBottom: 15,
                  }}
                >
                  <Form.Item
                    name={`phoneNumberButtonText${index}${id}`}
                    label="Button Text"
                    style={{ flex: 1 }}
                  >
                    <Input
                      value={rec.phoneNumberButtonText || ""}
                      placeholder="Enter Button Text"
                      onChange={(e) =>
                        handleButtonChange(
                          rec.id,
                          "phoneNumberButtonText",
                          e.target.value
                        )
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    name={`countryCode${index}${id}`}
                    label="Country Code"
                    style={{ flex: 1 }}
                  >
                    <div
                      className="country-code-container"
                      style={{ width: 130 }}
                    >
                      <PhoneInput
                        country={country}
                        enableSearch={true}
                        value={phone}
                        onChange={(phone) =>
                          handleButtonChange(rec.id, "countryCode", phone)
                        }
                        inputProps={{ readOnly: true }}
                      />
                    </div>
                  </Form.Item>
                  <Form.Item
                    name={`phoneNumber${index}${id}`}
                    label="Phone Number"
                    style={{ flex: 1 }}
                  >
                    <Input
                      value={rec.phoneNumber || ""}
                      placeholder="Enter Phone Number"
                      onChange={(e) =>
                        handleButtonChange(
                          rec.id,
                          "phoneNumber",
                          e.target.value
                        )
                      }
                    />
                  </Form.Item>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: 10,
                    }}
                  >
                    <DeleteOutlined
                      style={{ fontSize: 20 }}
                      onClick={() => onRemoveButtonHandler(rec.id)}
                    />
                  </div>
                </Card>
              );
            }
            return null;
          })}
        </Card>
      )}
    </>
  );
};

export default TemplateButton;

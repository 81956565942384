import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, Upload, notification } from 'antd';
import { useImperativeHandle, useState } from 'react';

const { TextArea } = Input;
const templateElementJSON = [
	{
		type: 'replyButton.buttons[0].title',
	},
];
const SendReplyButtonMessage = (props) => {
	const { childFormRef, formData, messageForm } = props;
	const [templateButtonJSON, setTemplateButtonJSON] = useState(templateElementJSON);

	const resetAllFields = () => {
		setTemplateButtonJSON(templateElementJSON);
		messageForm.resetFields();
	};
	const getFormData = () => {
		return new Promise((resolve, reject) => {
			messageForm
				.validateFields()
				.then((values) => {
					for (const [key, value] of Object.entries(values)) {
						if (value) {
							formData.append(key, value);
						}
					}
					resolve(formData);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};
	useImperativeHandle(childFormRef, () => ({
		formData: getFormData,
		resetData: resetAllFields,
	}));
	const handleDeleteSection = (deleteIndex) => {
		const updatedJSON = JSON.parse(JSON.stringify(templateButtonJSON));
		if (updatedJSON.length > 1) {
			updatedJSON.splice(deleteIndex, 1);
			setTemplateButtonJSON(updatedJSON);
		} else {
			notification.error({
				message: 'Error',
				description: 'At least One Button Text Required!!!',
			});
		}
	};
	const addNewItem = () => {
		const obj = {
			type: `replyButton.buttons[${templateButtonJSON.length}].title`,
		};
		const updatedJSON = JSON.parse(JSON.stringify(templateButtonJSON));
		updatedJSON.push(obj);
		setTemplateButtonJSON(updatedJSON);
	};
	return (
		<Form.Item>
			<Card>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						flexWrap: 'wrap',
					}}
				>
					<Form.Item name="replyButton.header" label="Title(Optional)" labelCol={{ span: 7 }} style={{ width: '48%' }}>
						<TextArea
							placeholder={`Enter section Title`}
							style={{
								height: 120,
								resize: 'none',
							}}
						/>
					</Form.Item>
					<Form.Item
						name="replyButton.body"
						label="Body"
						labelCol={{ span: 7 }}
						style={{ width: '48%' }}
						rules={[
							{
								required: true,
								message: 'Please enter Body',
							},
						]}
					>
						<TextArea
							placeholder={`Enter Body`}
							style={{
								height: 120,
								resize: 'none',
							}}
						/>
					</Form.Item>
					<Form.Item name="replyButton.footer" label="Footer(Optional)" labelCol={{ span: 7 }} style={{ width: '48%' }}>
						<TextArea
							placeholder={`Enter Footer`}
							style={{
								height: 120,
								resize: 'none',
							}}
						/>
					</Form.Item>
					<Form.Item name="uploadFile" labelCol={{ span: 7 }} style={{ width: '48%' }} label="Header File(Optional)">
						<Upload {...props}>
							<Button icon={<UploadOutlined />}>Click to Upload</Button>
						</Upload>
					</Form.Item>
				</div>

				{templateButtonJSON.map((templateRec, index) => {
					return (
						<Card style={{ marginBottom: 10 }}>
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									width: '100%',
									flexFlow: 'row wrap',
								}}
							>
								<Form.Item
									name={templateRec.type}
									label="Text"
									rules={[
										{
											required: true,
											message: 'Enter values',
										},
									]}
									style={{ width: '50%' }}
								>
									<Input placeholder="Enter value" />
								</Form.Item>
								<Form.Item>
									<Button type="text" icon={<DeleteOutlined />} onClick={() => handleDeleteSection(index)} />
								</Form.Item>
							</div>
						</Card>
					);
				})}
				<Form.Item>
					<Button title="Add Item" onClick={() => addNewItem()}>
						Add Button Text
					</Button>
				</Form.Item>
			</Card>
		</Form.Item>
	);
};

export default SendReplyButtonMessage;

import { Button, Flex, Form, Input, Modal, Select, Switch, Table, Typography, notification } from 'antd';
import { useEffect, useState } from 'react';

import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { getDateFromTimeStamp } from '../../constants/DateConstants';
import { deleteApi, getApi, postApi } from '../../constants/ApiConstant';

const Webhook = () => {
	const [tableData, setTableData] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isEditWebhook, setIsEditWebhook] = useState(false);
	const [editWebhookJson, setEditWebhookJson] = useState({});
	const [webhookModalForm] = Form.useForm();
	const [phoneNos, setPhoneNos] = useState([]);
	const selectedPhoneRec = useSelector((state) => state.selectedPhoneRec);
	const [webhookStatus,setWebhookStatus] = useState(true)
	const columns = [
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Phone Number Id',
			dataIndex: 'phoneNumberId',
			key: 'phoneNumberId',
		},
		{
			title: 'Active',
			dataIndex: 'active',
			key: 'active',
			render: (active, record) => <Switch checked={active} disabled />,
		},
		{
			title: 'URL',
			dataIndex: 'url',
			key: 'url',
		},
		{
			title: 'Creation Time',
			dataIndex: 'creationTime',
			key: 'creationTime',
			sorter: (a, b) => a.creationTime - b.creationTime,
			defaultSortOrder: 'descend',
			render: (_, record) => <Typography.Text>{getDateFromTimeStamp(record.creationTime)}</Typography.Text>,
		},
		{
			title: 'Action',
			dataIndex: 'Action',
			key: 'Action',
			render: (_, record) => (
				<Flex gap="small">
					<Button onClick={() => editHandler(record.id)}>
						<EditOutlined />
					</Button>
					<Button onClick={() => deleteHandler(record.id)}>
						<DeleteOutlined />
					</Button>
				</Flex>
			),
		},
	];

	const editHandler = (id) => {
		const editIndex = tableData.findIndex((rec) => rec.id === id);
		if (editIndex !== -1) {
			const editJSON = { ...tableData[editIndex] };
			setEditWebhookJson(editJSON);
			showModal('EDIT', editJSON);
		} else {
			notification.error({
				message: 'Error',
				description: 'Error Record Not Found!',
			});
		}
	};
	const deleteHandler = async(id) => {
		const response = await deleteApi(`/api/v1/webhook/deleteWebhook/${id}`);
			if(response){
				setTableData((prevData) => prevData.filter((item) => item.id !== id));
				notification.success({
					message: 'Success',
					description: 'Item deleted successfully.',
				});
			}
	};

	const resetModalFormData = () => {
		webhookModalForm.resetFields();
		setEditWebhookJson({});
		setIsEditWebhook(false);
		setIsModalOpen(false);
	};

	const showModal = async (action, json) => {
		if (action === 'CREATE') {
			handleWebHookStatus(true);
			webhookModalForm.setFieldsValue({ active: true });
		} else if (action === 'EDIT') {
			webhookModalForm.setFieldsValue({ name: json.name });
			webhookModalForm.setFieldsValue({ active: json.active });
			webhookModalForm.setFieldsValue({ url: json.url });
			webhookModalForm.setFieldsValue({ phoneNumberId: json.phoneNumberId });
			handleWebHookStatus(json.active);
			setIsEditWebhook(true);
		}
		setIsModalOpen(true);
	};

	const handleCancel = () => {
		resetModalFormData();
	};

	useEffect(() => {
		getApi('/api/v1/onboard/getPhones')
			.then((response) => {
				if (response && response.status === 200) {
					setPhoneNos(response.data.map((d) => ({ key: d.phoneNumberId, label: d.displayPhoneNumber })));
				} 
			})
			
	}, []);
	useEffect(() => {
		getApi('/api/v1/webhook/listWebhooks')
			.then((response) => {
				if (response && response.status === 200) {
					setTableData(response?.data);
				} 
			})
	}, [selectedPhoneRec]);

	const onFinish = (values) => {
		if (!isEditWebhook) {
			postApi('/api/v1/webhook/addUpdateWebhook', values)
				.then((response) => {
					if (response && response.status === 200) {
						getApi('/api/v1/webhook/listWebhooks')
							.then((response) => {
								if (response && response.status === 200) {
									setTableData(response.data);
								}
							})
							
						notification.success({
							message: 'Success',
							description: 'Item Created successfully!',
						});
					} 
				})
				
		} else {
			const postData = {
				name: values.name,
				active: values.active,
				id: editWebhookJson.id,
				phoneNumberId: values.phoneNumberId,
				url: values.url,
			};
			postApi('/api/v1/webhook/addUpdateWebhook', postData)
				.then((response) => {
					if (response && response.status === 200) {
						const editIndex = tableData.findIndex((rec) => rec.id === editWebhookJson.id);
						if (editIndex !== -1) {
							const updatedData = [...tableData];
							updatedData[editIndex] = {
								...updatedData[editIndex],
								active: values.active,
								phoneNumberId: values.phoneNumberId,
								url: values.url,
								name: values.name,
							};
							setTableData(updatedData);
						}
					} 
				})
				
		}
		handleCancel();
	};
	const handleWebHookStatus = (status) => {
		setWebhookStatus(status);
	};
	return (
		<Flex vertical gap={'large'}>
			<Flex justify="space-between">
				<Typography.Title level={4} style={{ margin: 0 }}>
					List Webhooks
				</Typography.Title>
				<Button type="primary" onClick={() => showModal('CREATE')}>
					Create Webhook
				</Button>
			</Flex>
			<Table columns={columns} dataSource={tableData} />
			<Modal title="Add Update Webhook" open={isModalOpen} onOk={() => webhookModalForm.submit()} onCancel={handleCancel}>
				<Form form={webhookModalForm} onFinish={onFinish} 
					labelCol={{
						span: 5,
					}}
					wrapperCol={{
						span: 16,
					}}>
					<Form.Item name="name" label="Name">
						<Input placeholder="Webhook Name" />
					</Form.Item>
					<Form.Item name="phoneNumberId" label="Phone number">
						<Select>
							{phoneNos.map((phone) => (
								<Select.Option key={phone.key} value={phone.key}>
									{phone.label}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item name="url" label="Webhook URL">
						<Input placeholder="Webhook URL" />
					</Form.Item>
					<Form.Item name="active" label="Status">
						 <Switch
							checked={webhookStatus}
							onChange={(checked) => {
								handleWebHookStatus(checked);
								webhookModalForm.setFieldsValue({ active: checked });
							}}
						/>
					</Form.Item>
				</Form>
			</Modal>
		</Flex>
	);
};

export default Webhook;

import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { Card, Col, Row } from "antd";
import "./Support.css";

const Support = () => {
    const handlePhoneClick = () => {
        window.location.href = 'tel:+1234567890';
    };

    const handleMailClick = () => {
        window.location.href = 'mailto:info@example.com';
    };

    return (
        <Card title = "We would love to hear from you !!!" >
        <Row gutter={16}>
            <Col span={4}>
                <Card hoverable className="contact-card"  onClick={handlePhoneClick}>
                    <div>
                        <PhoneOutlined className="svg-icon-support"/>
                    </div>
                    <div className="support-card-text">Call us</div>
                </Card>
            </Col>
            <Col span={4}>
                <Card hoverable className="contact-card"  onClick={handleMailClick}>
                    <div>
                        <MailOutlined className="svg-icon-support"/>
                    </div>
                    <div className="support-card-text">Email</div>
                </Card>
            </Col>
        </Row>
        </Card>
    );
}

export default Support;

import { Form, Input, Modal, Select, Switch, notification } from 'antd';
import { Option } from 'antd/es/mentions';
import { useEffect, useState } from 'react';
import { getApi, postApi } from '../../../constants/ApiConstant';

const AddEditBasicChatBot = ({ jsonData, hideBSVRMessage, isOpen }) => {
	const [chatBotForm] = Form.useForm();
	const [messageList, setMessageList] = useState();
	const [messageStatus, setMessageStatus] = useState(true);
	useEffect(() => {
		getCannedMessage();
	}, []);
	useEffect(() => {
		getCannedMessage();
		if (Object.keys(jsonData).length > 0) {
			chatBotForm.setFieldsValue(jsonData);
			setMessageStatus(jsonData.active);
		}
	}, [jsonData]);
	const getCannedMessage = async() => {
		const response = await getApi('/api/v1/message/preComposed/list');
			if(response){
				setMessageList(response.data);
			}
	};
	const onFinish = async(values) => {
		const payload = { ...values };
		if (Object.keys(jsonData).length > 0) {
			payload.id = jsonData.id;
		}
		const response = await postApi('/api/v1/basicChatbot/save', payload);
			if (response) {
				notification.success({
					message: 'Success',
					description: response.data.message,
				});
				hideBSVRMessage();
			} 
	};
	const handleMessageStatus = (status) => {
		setMessageStatus(status);
	};

	const onFinishFailed = (values) => {};
	return (
		<Modal title="Basic Chat Bot" open={isOpen} onOk={() => chatBotForm.submit()} onCancel={() => hideBSVRMessage()} style={{ minWidth: 700 }}>
			<Form
				form={chatBotForm}
				name="textmessage"
				labelCol={{
					span: 5,
				}}
				wrapperCol={{
					span: 16,
				}}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				initialValues={{
					remember: true,
				}}
				autoComplete="off"
			>
				<Form.Item label="Name" name="name" rules={[{ required: true, message: 'Name is required' }]}>
					<Input />
				</Form.Item>
				<Form.Item label="Type" name="type" rules={[{ required: true, message: 'Rule type is required' }]}>
					<Select placeholder="Select your type">
						<Option value="CONTAINS">Contains</Option>
						<Option value="BEGINS_WITH">Begin With</Option>
						<Option value="ENDS_WITH">End With</Option>
						<Option value="EXACT_MATCH">Exact Match</Option>
						<Option value="PATTERN_MATCH">Pattern Match</Option>
					</Select>
				</Form.Item>
				<Form.Item
					label="Match text"
					name="conditionText"
					rules={[
						{
							required: true,
							message: 'Matching text is required',
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item label="Response message" name="preComposedMessageId" rules={[{ required: true, message: 'Response message is required' }]}>
					<Select placeholder="Select your Message Response">
						{messageList?.map((messag) => (
							<Option key={messag.id} value={messag.id}>
								{messag.name}
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item name="active" label="Status">
					<Switch
						checked={messageStatus}
						onChange={(checked) => {
							handleMessageStatus(checked);
							chatBotForm.setFieldsValue({ active: checked });
						}}
					/>
				</Form.Item>
				<Form.Item label="Priority" name="priority">
					<Input />
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default AddEditBasicChatBot;

import {
  Button,
  Card,
  Descriptions,
  Flex,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Typography,
  notification,
} from "antd";
import { useRef, useState } from "react";
import {
  TEMPLATE_CATEGOTY_JSON,
  TEMPLATE_LANGUAGE_JSON,
  TEMPLATE_TYPE_JSON,
} from "../../constants/MessageTemplateConstant";
// import OrderDetails from "./OrderDetails";
import { postApi } from "../../constants/ApiConstant";
import "./AddEditTemplate.css";
import TemplateBody from "./TemplateBody";
import TemplateCarousel from "./TemplateCarousel";
import TemplateCatalog from "./TemplateCatalog";
import TemplateCustom from "./TemplateCustom";
import TemplateFlow from "./TemplateFlow";
import { createCarouselPayload, createPayload } from "./TemplateUtil";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { getErrorDescription } from "../../constants/ErrorConstant";

const AddEditTempplate = () => {
  const [AddEditTemplateForm] = Form.useForm();
  const [templateData, setTemplateData] = useState({});
  const [templateBodyJSON, setTemplateBodyJSON] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const counterRef = useRef(0);
  const [templateJSON, setTemplateJSON] = useState({
    name: "",
    language: "",
    category: "",
    type: "",
    footer: "",
    components: [],
  });
  const [categoryJSON, setCategoryJSON] = useState([]);
  const [errorData, setErrorData] = useState({});

  const resetAllFields = () => {
    setTemplateData({});
    setCategoryJSON([]);
    setTemplateBodyJSON({});
    setErrorData({});
  };
  const onFinish = async (values) => {
    let payload = {};
    if (templateJSON.type === "CAROUSEL") {
      payload = createCarouselPayload(
        templateBodyJSON,
        templateJSON,
        templateData
      );
    } else {
      payload = createPayload(templateData, templateJSON);
    }
    setIsLoading(true);
    const response = await postApi("/api/v1/messageTemplate/create", payload);
    setIsLoading(false);
    if (response.status === 200) {
      resetAllFields();
      setTemplateJSON({
        name: "",
        language: "",
        category: "",
        type: "",
        footer: "",
        components: [],
      });
      AddEditTemplateForm.resetFields();
      notification.success({
        message: "Success",
        description: response.data.message,
      });
    } else if (Object.keys(response.error).length > 0) {
      setErrorData(response);
    }
  };

  const getFilterCategoryJSON = (selectedValue) => {
    const filterCategoryJSON = TEMPLATE_CATEGOTY_JSON.filter((rec) => {
      return rec.type.includes(selectedValue);
    });
    if (filterCategoryJSON.length === 1) {
      AddEditTemplateForm.setFieldsValue({
        category: filterCategoryJSON[0].name,
      });
      setTemplateJSON((prevState) => ({
        ...prevState,
        category: filterCategoryJSON[0].name,
      }));
    } else {
      AddEditTemplateForm.setFieldsValue({ category: "" });
      setTemplateJSON((prevState) => ({ ...prevState, category: "" }));
    }
    setCategoryJSON(filterCategoryJSON);
  };
  const handleStateChange = (fieldName, selectedValue) => {
    const updatedTemplateJSON = JSON.parse(JSON.stringify(templateJSON));
    if (fieldName === "name") {
      selectedValue = selectedValue.replace(/\s+/g, "_");
      // Remove special characters
      selectedValue = selectedValue.replace(/[^a-zA-Z0-9_]/g, "");
    }
    updatedTemplateJSON[fieldName] = selectedValue;
    setTemplateJSON(updatedTemplateJSON);
    if (fieldName === "type") {
      resetAllFields();
      setTemplateData({});
      let obj = {
        [counterRef.current++]: {
          templateHeader: {},
          templateBody: {},
          templateButton: [],
        },
      };
      setTemplateData(obj);
      getFilterCategoryJSON(selectedValue);
    }
  };
  const onAddCarousalHandler = () => {
    if (Object.keys(templateData).length > 9) {
      return;
    }
    let obj = {
      [counterRef.current++]: {
        templateHeader: {},
        templateBody: {},
        templateButton: [],
      },
    };

    setTemplateData((prev) => ({ ...prev, ...obj }));
  };
  const handleBodyChange = (updatedBodyData) => {
    const newBodyData = { ...updatedBodyData, type: "BODY" };
    setTemplateBodyJSON(newBodyData);
  };

  return (
    <Flex vertical gap={"large"}>
      <Flex justify="space-between">
        <Typography.Title level={4} style={{ margin: 0 }}>
          Create Template
        </Typography.Title>
      </Flex>
      <Form
        form={AddEditTemplateForm}
        onFinish={onFinish}
        labelCol={{ span: 4 }}
        style={{ width: "60%", margin: "0 auto" }}
      >
        <Form.Item name="type" label="Template Type">
          <Select onChange={(e) => handleStateChange("type", e)}>
            {TEMPLATE_TYPE_JSON.map((rec) => (
              <Select.Option key={rec.id} value={rec.name}>
                {rec.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="category" label="Template Category">
          <Select
            onChange={(e) => handleStateChange("category", e)}
            disabled={categoryJSON.length <= 1}
          >
            {categoryJSON.map((rec) => (
              <Select.Option key={rec.id} value={rec.name}>
                {rec.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Template Name:">
          <Input
            placeholder="Enter Template Name"
            value={templateJSON.name}
            onChange={(e) => handleStateChange("name", e.target.value)}
          />
        </Form.Item>
        <Form.Item name="language" label="Template Language">
          <Select showSearch onChange={(e) => handleStateChange("language", e)}>
            {TEMPLATE_LANGUAGE_JSON.map((rec) => (
              <Select.Option key={rec.code} value={rec.code}>
                {rec.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        {templateJSON.type === "CUSTOM" && (
          <div>
            <TemplateCustom
              templateData={templateData}
              setTemplateData={setTemplateData}
              templateJSON={templateJSON}
              handleStateChange={handleStateChange}
            />
          </div>
        )}
        {templateJSON.type === "FLOWS" && (
          <div>
            <TemplateFlow
              templateData={templateData}
              setTemplateData={setTemplateData}
              templateJSON={templateJSON}
              handleStateChange={handleStateChange}
            />
          </div>
        )}
        {templateJSON.type === "CATALOG" && (
          <div>
            <TemplateCatalog
              templateData={templateData}
              setTemplateData={setTemplateData}
            />
          </div>
        )}
        {templateJSON.type === "CAROUSEL" && (
          <>
            <TemplateBody
              templateBodyData={templateBodyJSON}
              setTemplateBodyData={(updatedBody) =>
                handleBodyChange(updatedBody)
              }
              id="BODY"
              label="Message Bubble"
            />
            <Card title="Cards" style={{ marginBottom: 20 }}>
              <TemplateCarousel
                templateData={templateData}
                setTemplateData={setTemplateData}
              />
              <Button
                type="primary"
                disabled={Object.keys(templateData).length > 9}
                onClick={onAddCarousalHandler}
              >
                Add Card
              </Button>
            </Card>
          </>
        )}
        <Form.Item
          wrapperCol={{ span: 12, offset: 22 }}
          style={{ marginTop: 20 }}
        >
          <Button
            type="primary"
            onClick={AddEditTemplateForm.submit}
            loading={isLoading}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
      {Object.keys(errorData).length > 0 && (
        <Modal
          open={Object.keys(errorData).length > 0}
          title={
            <Space>
              <ExclamationCircleOutlined style={{ color: "red" }} />
              <span style={{ color: "red" }}>Errors</span>
            </Space>
          }
          width={800}
          onOk={() => setErrorData({})}
          onCancel={() => setErrorData({})}
        >
          <Card>
            <Descriptions
              size="small"
              column={1}
              bordered
              items={getErrorDescription(errorData)}
            ></Descriptions>
          </Card>
        </Modal>
      )}
    </Flex>
  );
};

export default AddEditTempplate;

import {
  Button,
  Card,
  Col,
  Descriptions,
  Form,
  Input,
  Modal,
  Row,
  Space,
  notification,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { SEND_MESSAGE_API_URL } from "../../constants/Constant";
import CustomTabs from "../CustomTabs/CustomTabs";
import { postMultiPartApi } from "../../constants/ApiConstant";

import { ExclamationCircleOutlined } from "@ant-design/icons";
import { getErrorDescription } from "../../constants/ErrorConstant";
const SendMessages = () => {
  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const tabJSON = [
    "Text",
    "Image",
    "Video",
    "Document",
    "Audio",
    "Location",
    "Contacts",
    "Template",
    "Interactive",
  ];
  const [messageForm] = Form.useForm();
  const selectedTabName = useSelector((state) => state.selectedTab);
  const [errorData, setErrorData] = useState({});
  let formData = new FormData();
  const childFormRef = useRef(null);

  useEffect(() => {
    messageForm.resetFields();
  }, [selectedTabName, messageForm]);
  const onChangeHandler = (e) => {
    setValue(e.target.value);
  };

  const validateGlobalFields = () => {
    if (!value) {
      const description = "Please Enter Phone Number!!";
      notification.error({
        message: "Error",
        description: description,
      });
      return false;
    }
    return true;
  };

  const onFinish = async (values) => {
    const isValid = validateGlobalFields();
    if (!isValid) {
      return;
    }
    formData = await childFormRef.current.formData();
    if (typeof formData === "boolean") {
      return;
    }
    formData.append("to", value);
    setIsLoading(true);
    try {
      const response = await postMultiPartApi(SEND_MESSAGE_API_URL, formData);
      if (response && response.status === 200) {
        messageForm.resetFields();
        setErrorData({});
        notification.success({
          message: "Success",
          description: response.data.message,
        });
        setIsLoading(false);
        childFormRef.current.resetData();
      } else {
        setIsLoading(false);
        setErrorData(response);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error);
    }
  };
  const onFinishFailed = (values) => {
    console.log(values);
  };

  return (
    <div>
      <Row>
        <Col xs={22} sm={22} md={22} lg={22} xl={22}>
          <Card
            title="Send Messages"
            style={{ width: "100%", marginBottom: 20 }}
          >
            <div
              style={{
                display: "flex",
                marginBottom: 10,
                alignItems: "center",
              }}
            >
              <label style={{ paddingRight: 10 }}>Phone Number:</label>
              <Input
                onChange={onChangeHandler}
                value={value}
                style={{ flex: 1 }}
              />
            </div>
            <Form
              form={messageForm}
              name="textmessage"
              style={{
                maxWidth: "100%",
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item>
                <CustomTabs
                  tabData={tabJSON}
                  childFormRef={childFormRef}
                  formData={formData}
                  messageForm={messageForm}
                />
              </Form.Item>
              <Form.Item
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: 0,
                }}
              >
                <Button type="primary" htmlType="submit" loading={isLoading}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
      {Object.keys(errorData).length > 0 && (
        <Modal
          open={Object.keys(errorData).length > 0}
          title={
            <Space>
              <ExclamationCircleOutlined style={{ color: "red" }} />
              <span style={{ color: "red" }}>Errors</span>
            </Space>
          }
          width={800}
          onOk={() => setErrorData({})}
          onCancel={() => setErrorData({})}
        >
          <Card>
            <Descriptions
              size="small"
              column={1}
              bordered
              items={getErrorDescription(errorData)}
            ></Descriptions>
          </Card>
        </Modal>
      )}
    </div>
  );
};

export default SendMessages;

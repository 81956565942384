import { HeatMapOutlined, UserOutlined } from '@ant-design/icons';
import { HotTable } from '@handsontable/react';
import { Button, Card, Col, Collapse, Form, Input, Row, Select, notification } from 'antd';
import 'handsontable/dist/handsontable.full.css';
import { registerAllModules } from 'handsontable/registry';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getApi, postApi, postMultiPartApi } from '../../constants/ApiConstant';
import { HOT_COLUMN_WIDTH } from '../../constants/SizeLimit';
import { setTemplateData, setTemplateVariable } from '../../Store/action';
import UploadFile from '../SendMessage/CommonComponents/UploadComponrnt/UploadFile';
registerAllModules();
const SendBulkMessage = () => {
	const [form] = Form.useForm();
	const templateItems = useSelector((state) => state.templateData);
	const [selectedDropDownValue, setSelectedDropDownValue] = useState('');
	const [isFileUpload, setIsFileUpload] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isLocationHeader, setISLocationHeader] = useState(false);
	const [hotData, setHotData] = useState([[null]]);
	const [hotColumn, setHotColumn] = useState(['Receiver']);
	const [fileList, setFileList] = useState([]);
	const [uploadTitle, setUploadTitle] = useState('');
	const [locationData, setLocationData] = useState({
		longitude: '',
		latitude: '',
		name: '',
		address: '',
	});
	const [isCarouselType, setIsCarouselType] = useState(false);
	const [fileUpload, setFileUpload] = useState([]);
	const dispatch = useDispatch();
	useEffect(() => {
		getTemplatesForSendMessage();
	}, [dispatch]);
	const getTemplatesForSendMessage = async () => {
		const response = await getApi('api/v1/messageTemplate/getTemplatesForSendMessage');
		if (response) {
			dispatch(setTemplateData(response.data));
		}
	};
	const options = templateItems.map((item, index) => ({
		value: item.name + '$' + item.language,
		label: item.name + '\u00A0\u00A0\u00A0-\u00A0\u00A0\u00A0' + item.language,
		key: index,
	}));

	const resetAllFields = () => {
		setISLocationHeader(false);
		setIsFileUpload(false);
		setFileUpload([]);
		setIsCarouselType(false);
	};
	const validationFormData = () => {
		if (!selectedDropDownValue) {
			notification.error({
				message: 'Error',
				description: 'Please Select Template!!!',
			});
			return false;
		}
		if (isFileUpload && fileList.length === 0) {
			notification.error({
				message: 'Error',
				description: 'Please Upload File!!!',
			});
			return false;
		}
		let allValuesPresent = true;
		let allPhoneNumberPresent = true;
		for (let i = 0; i < hotData.length; i++) {
			if (!hotData[i][0]) {
				allPhoneNumberPresent = false;
				break;
			}
			for (let j = 0; j < hotData[i].length; j++) {
				if (!hotData[i][j]) {
					allValuesPresent = false;
					break;
				}
			}
		}
		if (!allPhoneNumberPresent) {
			notification.error({
				message: 'Error',
				description: 'Please Enter all Row Receiver values!!!',
			});
			return false;
		}
		if (!allValuesPresent) {
			notification.error({
				message: 'Error',
				description: 'Please Enter all variable values!!!',
			});
			return false;
		}
		if (isLocationHeader) {
			if (!locationData.longitude || !locationData.name || !locationData.address || !locationData.latitude) {
				notification.error({
					message: 'Error',
					description: 'Please Enter all location values!!!',
				});
				return false;
			}
		}
		return true;
	};
	const onFinish = async (values) => {
		const formData = new FormData();
		if (isLocationHeader) {
			let obj = {
				longitude: values.longitude,
				latitude: values.latitude,
				name: values.Name,
				address: values.address,
			};
			setLocationData(obj);
		}
		const isValidForm = validationFormData();
		if (!isValidForm) {
			return;
		}
		setIsLoading(true);
		formData.append('templateName', selectedDropDownValue.split('$')[0]);
		formData.append('language', selectedDropDownValue.split('$')[1]);

		hotData.forEach((receiversAndVariables, i) => {
			const receiver = receiversAndVariables[0];
			formData.append(`receiversAndVariables[${i}].receiver`, receiver);
			for (let j = 1; j < receiversAndVariables.length; j++) {
				const templateVariable = receiversAndVariables[j];
				formData.append(`receiversAndVariables[${i}].templateVariables[${j - 1}]`, templateVariable);
			}
		});
		if (isFileUpload) {
			if (isCarouselType) {
				for (let i = 0; i < fileList.length; i++) {
					formData.append(`files[${i}].uploadFile`, fileList[i].originFileObj);
				}
			} else {
				formData.append('uploadFile', fileList[0].originFileObj);
			}
		}
		if (isLocationHeader) {
			formData.append('location.longitude', values['longitude']);
			formData.append('location.latitude', values['latitude']);
			formData.append('location.name', values['name']);
			formData.append('location.address', values['address']);
		}
		try {
			const response = await postMultiPartApi('/api/v1/whatsapp/sendTemplateMessageWithGrid', formData);
			if (response) {
				resetAllFields();
				setHotData([[null]]); // Reset hotData to default
				setHotColumn(['Receiver']);
				setSelectedDropDownValue('');
				setFileList([]);
				notification.success({
					message: 'Success',
					description: response.data.message,
				});
			}
		} finally {
			setIsLoading(false);
		}
	};
	const onFinishFailed = (values) => {
		console.log(values);
	};
	const handleChange = (values) => {
		resetAllFields();
		const updatedValues = values.split('$');
		const name = updatedValues[0];
		const language = updatedValues[1];
		setSelectedDropDownValue(values);

		const selectedTemplateIndex = templateItems.findIndex((rec) => {
			return rec.name === name && rec.language === language;
		});
		if (selectedTemplateIndex !== -1) {
			const selectedTemplateData = templateItems[selectedTemplateIndex];
			postApi('api/v1/messageTemplate/getTemplateDetail', selectedTemplateData).then((response) => {
				if (response) {
					let variableCount = countVariables(response.data.components);
					for (let i = 0; i < response.data.components.length; i++) {
						let key = response.data.components[i];
						if (key.type === 'CAROUSEL') {
							setIsCarouselType(true);
							for (let j = 0; j < key.cards.length; j++) {
								variableCount += countVariables(key.cards[j].components);
							}
						}
					}
					let countArray = Array.from({ length: variableCount }, (_, i) => `Variable ${i + 1}`);
					setHotData([Array(countArray.length + 1).fill('')]);
					setHotColumn(['Receiver', ...countArray]);
					dispatch(setTemplateVariable(variableCount + 1));
				}
			});
		}
	};
	function countVariables(jsonArray) {
		let variableCount = 0;

		jsonArray.forEach((obj) => {
			if (obj.type === 'HEADER' && !obj.text) {
				if (obj.format === 'VIDEO') {
					setUploadTitle('Upload Video');
					setFileUpload((prevFileUpload) => {
						const updatedFileUpload = [...prevFileUpload];
						const obj1 = {
							id: Math.floor(Math.random() * 20000),
							uploadTitle: obj.format.toLowerCase(),
						};
						updatedFileUpload.push(obj1);
						return updatedFileUpload;
					});
					setIsFileUpload(true);
				} else if (obj.format === 'DOCUMENT') {
					setUploadTitle('Upload Document');
					setIsFileUpload(true);
				} else if (obj.format === 'IMAGE') {
					setUploadTitle('Upload Image');
					setFileUpload((prevFileUpload) => {
						const updatedFileUpload = [...prevFileUpload];
						const obj1 = {
							id: Math.floor(Math.random() * 20000),
							uploadTitle: obj.format.toLowerCase(),
						};
						updatedFileUpload.push(obj1);
						return updatedFileUpload;
					});
					setIsFileUpload(true);
				} else if (obj.format === 'LOCATION') {
					setISLocationHeader(true);
				}
			}
			if (obj.text && obj.type !== 'BUTTONS') {
				const matches = obj.text.match(/{{\d+}}/g);
				if (matches) {
					variableCount += matches.length;
				}
			} else if (obj.type === 'BUTTONS') {
				obj.buttons.forEach((inObj) => {
					if (inObj.text) {
						const matches = inObj.text.match(/{{\d+}}/g);
						if (matches) {
							variableCount += matches.length;
						}
					}
					if (inObj.url) {
						const matches = inObj.url.match(/{{\d+}}/g);
						if (matches) {
							variableCount += matches.length;
						}
					}
				});
			}
		});
		return variableCount;
	}
	const uploadFileChangeHandler = (data, isFileUpload, fileUrl, index) => {
		const updatedFileData = [...fileList];
		updatedFileData[index] = data;
		setFileList(updatedFileData);
	};
	const onSearch = (value) => {
		console.log('search:', value);
	};
	const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const helpCollapse = [
		{
			key: 'help1',
			label: 'How to use this grid',
			children: (
				<>
					<p>You can use the grid to send template messages to multiple receivers.</p>
					<ol>
						<li>
							Select the message template that you want to send. Once it is selected, a grid will be created with column headers as the variables (if present)
						</li>
						<li>In the first column enter the receivers. In other columns enter the value of the variables.</li>
						<li>Include files if present in the template</li>
						<li>Once the grid is ready, click on Submit button to send the messages.</li>
					</ol>
					Other points to note:
					<ol>
						<li>
							<b>This grid supports copy and paste from Excel / Google Sheet.</b>
						</li>
						<li>
							When you paste data, it will automatically add new rows if required. Extra rows need not be manually added in that case. Though you can still add
							new rows by right clicking on the grid.
						</li>
						<li>Please ensure that there are no empty rows / columns while sending the data.</li>
					</ol>
				</>
			),
		},
	];

	return (
		<Row>
			<Col xs={22} sm={22} md={22} lg={22} xl={22}>
				<Card title="Send Bulk Messages" style={{ width: '100%', marginBottom: 20 }}>
					<Collapse items={helpCollapse}></Collapse>

					<Form
						form={form}
						name="audioForm"
						labelCol={{
							span: 3,
						}}
						wrapperCol={{
							span: 20,
						}}
						style={{
							maxWidth: '100%',
							padding: 30,
						}}
						initialValues={{
							remember: true,
						}}
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
						autoComplete="off"
					>
						<Form.Item label="Select Template" name="">
							<Col xs={24} sm={24} md={24} lg={24} xl={24}>
								<Select
									showSearch
									placeholder="Select a Template"
									optionFilterProp="children"
									onChange={handleChange}
									onSearch={onSearch}
									filterOption={filterOption}
									defaultValue=""
									value={selectedDropDownValue}
									style={{
										width: '100%',
										marginBottom: 10,
									}}
									options={options}
								/>
							</Col>
						</Form.Item>
						{isFileUpload && !isCarouselType && (
							<Form.Item label=" " name="uploadTitle" className="image-item">
								<div>
									<UploadFile
										uploadFileName={uploadTitle}
										uploadFileChangeHandler={(data, isFileUpload, fileUrl) => uploadFileChangeHandler(data, isFileUpload, fileUrl, 0)}
										documentUplod={uploadTitle.split(' ')[1].toLocaleLowerCase()}
									/>
								</div>
							</Form.Item>
						)}
						{fileUpload &&
							isCarouselType &&
							fileUpload.length > 0 &&
							fileUpload.map((rec, index) => {
								return (
									<Form.Item>
										<div>
											<UploadFile
												uploadFileName={rec.uploadTitle}
												uploadFileChangeHandler={(data, isFileUpload, fileUrl) => uploadFileChangeHandler(data, isFileUpload, fileUrl, index)}
												tabName={rec.uploadTitle}
												documentUplod={rec.uploadTitle}
											/>
										</div>
									</Form.Item>
								);
							})}
						{/*   {isFileUpload && (
              <Form.Item label=" " name="uploadTitle" className="image-item">
                <UploadFile
                  uploadFileName={uploadTitle}
                  uploadFileChangeHandler={uploadFileChangeHandler}
                  documentUplod={uploadTitle.split(" ")[1].toLocaleLowerCase()}
                />
              </Form.Item>
            )} */}
						{isLocationHeader && (
							<>
								<Form.Item label="Name" name="Name" rules={[{ required: true, message: 'Please enter Name' }]}>
									<Input placeholder="Name" prefix={<UserOutlined className="site-form-item-icon" />}></Input>
								</Form.Item>
								<Form.Item label="Address" name="address" rules={[{ required: true, message: 'Please enter Address' }]}>
									<Input placeholder="Address" prefix={<UserOutlined className="site-form-item-icon" />}></Input>
								</Form.Item>

								<Form.Item label="Latitude" name="latitude" rules={[{ required: true, message: 'Please enter Address' }]}>
									<Input placeholder="Latitude" prefix={<HeatMapOutlined className="site-form-item-icon" />} />
								</Form.Item>
								<Form.Item label="Longitude" name="longitude" rules={[{ required: true, message: 'Please enter Address' }]}>
									<Input placeholder="Longitude" prefix={<HeatMapOutlined className="site-form-item-icon" />} />
								</Form.Item>
							</>
						)}
						<Form.Item label=" ">
							<HotTable
								data={hotData}
								colHeaders={hotColumn}
								rowHeaders={true}
								width="100%"
								colWidths={HOT_COLUMN_WIDTH}
								licenseKey="non-commercial-and-evaluation"
								settings={{
									copyPaste: true,
									dropdownMenu: true,
									contextMenu: true,
									minRows: 1,
									minCols: 1,
									filters: true,
									manualRowMove: true,
								}}
							/>
						</Form.Item>
						<Form.Item
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								margin: 0,
							}}
						>
							<Button type="primary" htmlType="submit" loading={isLoading}>
								Submit
							</Button>
						</Form.Item>
					</Form>
				</Card>
			</Col>
		</Row>
	);
};

export default SendBulkMessage;

import { Input, Modal, notification } from "antd";
import { useState } from "react";
import { postApi } from "../../constants/ApiConstant";
import { setSorageObj } from "../../constants/StorageUtil";
import { onLoginHandler } from "../../Util/LoginApiUtil";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const SwitchUser = ({ isModalOpen, setIsModalOpen }) => {
  const [userName, setUserName] = useState("");
  const dispatch = useDispatch();
  const state = useLocation().pathname;
  const navigate = useNavigate();
  const onSwitchUserHandler = () => {
    postApi(
      "/api/v1/admin/getTokenForUser",
      JSON.stringify({ username: userName })
    )
      .then((response) => {
        if (response.status === 200) {
          const storageObj = {
            userName: userName,
            token: response.data.token,
            authState: true,
          };
          setSorageObj(storageObj);
          setUserName("");
          onLoginHandler(dispatch, true, state, navigate)
            .then((data) => {})
            .catch((error) => {});
        }

        setIsModalOpen(false);
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error.message,
        });
      });
  };
  return (
    <Modal
      open={isModalOpen}
      title="Switch User"
      width={800}
      onOk={onSwitchUserHandler}
      onCancel={() => setIsModalOpen(false)}
    >
      <Input
        placeholder="Enter User Name"
        value={userName}
        onChange={(e) => setUserName(e.target.value)}
      />
    </Modal>
  );
};

export default SwitchUser;

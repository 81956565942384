import { Col, Row, Segmented } from 'antd';
import { useState } from 'react';
import SendCatalogButtonMessage from './SendInteractiveMessage/SendCatalogButtonMessage';
import SendCtaButtonMessage from './SendInteractiveMessage/SendCtaButtonMessage';
import SendListButtonMessage from './SendInteractiveMessage/SendListButtonMessage';
import SendReplyButtonMessage from './SendInteractiveMessage/SendReplyButtonMessage';

const interactiveMessageTypeJSON = ['List Button', 'Reply Button', 'CTA Button', 'Catalog Button'];

const SendInteractiveMessage = (props) => {
	const [activeSegMent, setActiveSegment] = useState('List Button');
	const { childFormRef, formData, messageForm } = props;
	const handleSegmentChange = (activeKey) => {
		setActiveSegment(activeKey);
	};

	return (
		<div style={{ padding: '20px 0px' }}>
			<Row style={{ display: 'flex', justifyContent: 'space-between' }}>
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Segmented options={interactiveMessageTypeJSON} block onChange={handleSegmentChange} />
					{activeSegMent === 'List Button' && <SendListButtonMessage childFormRef={childFormRef} formData={formData} messageForm={messageForm} />}
					{activeSegMent === 'Reply Button' && <SendReplyButtonMessage childFormRef={childFormRef} formData={formData} messageForm={messageForm} />}
					{activeSegMent === 'CTA Button' && <SendCtaButtonMessage childFormRef={childFormRef} formData={formData} messageForm={messageForm} />}
					{activeSegMent === 'Catalog Button' && <SendCatalogButtonMessage childFormRef={childFormRef} formData={formData} messageForm={messageForm} />}
				</Col>
			</Row>
		</div>
	);
};

export default SendInteractiveMessage;

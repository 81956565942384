import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Flex, Switch, Table, Typography, notification } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AddEditBasicChatBot from './AddEditBasicChatBot';
import { deleteApi, getApi } from '../../../constants/ApiConstant';

const BasicChatBot = () => {
	const columns = [
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'Name',
		},
		{
			title: 'Type',
			dataIndex: 'type',
			key: 'type',
		},
		{
			title: 'Match text',
			dataIndex: 'conditionText',
			key: 'conditionText',
		},
		{
			title: 'Response message',
			dataIndex: 'preComposedMessageId',
			key: 'responseMessage',
		},
		{
			title: 'Active',
			dataIndex: 'active',
			key: 'active',
			render: (active) => <Switch checked={active} disabled />,
		},
		{
			title: 'Action',
			dataIndex: 'Action',
			key: 'Action',
			render: (_, record) => (
				<Flex gap="small">
					<Button>
						<EditOutlined onClick={() => editHandler(record)} />
					</Button>
					<Button>
						<DeleteOutlined onClick={() => deleteHandler(record.id)} />
					</Button>
				</Flex>
			),
		},
	];
	const [messageRulesList, setMessageRulesList] = useState([]);
	const [isShowAddEditCBR, setIsAddEditCBR] = useState(false);
	const [editBCR, setEditBCR] = useState({});
	const selectedPhoneRec = useSelector((state) => state.selectedPhoneRec);
	useEffect(() => {
		if (!isShowAddEditCBR) {
			getBasicChatBotList();
		}
	}, [isShowAddEditCBR, selectedPhoneRec]);
	const getBasicChatBotList = async() => {
		const response = await getApi('/api/v1/basicChatbot/list');
			if(response){
				setMessageRulesList(response.data);
			}
				
	}
	const createChatBotMessageRule = () => {
		setIsAddEditCBR(true);
	};
	const editHandler = (record) => {
		setIsAddEditCBR(true);
		setEditBCR(record);
	};
	const deleteHandler = async(id) => {
		const response = await deleteApi(`/api/v1/basicChatbot/${id}`);
			if(response){
				setMessageRulesList((prevData) => prevData.filter((item) => item.id !== id));
				notification.success({
					message: 'Success',
					description: 'Item deleted successfully.',
				});
			}
	};

	const hideBSVRMessage = () => {
		setIsAddEditCBR(false);
		setEditBCR({});
	};
	return (
		<>
			<Flex vertical gap={'large'}>
				<Flex justify="space-between">
					<Typography.Title level={4} style={{ margin: 0 }}>
						Basic Chat Bot Rules
					</Typography.Title>
					<Button type="primary" onClick={createChatBotMessageRule}>
						Create Chat Bot Rule
					</Button>
				</Flex>
				<Table columns={columns} dataSource={messageRulesList} />
			</Flex>
			{isShowAddEditCBR && <AddEditBasicChatBot isOpen={isShowAddEditCBR} jsonData={editBCR} hideBSVRMessage={hideBSVRMessage} />}
		</>
	);
};

export default BasicChatBot;

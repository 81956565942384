import { DeleteOutlined, EditOutlined,CopyOutlined } from '@ant-design/icons';
import { Button, Flex, Form, Input, Modal, Switch, Table, Typography, notification,message } from 'antd';
import { useEffect, useState } from 'react';
import { getDateFromTimeStamp } from '../../constants/DateConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import { deleteApi, getApi, postApi } from '../../constants/ApiConstant';

const ListApiKey = () => {
	const [tableData, setTableData] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isEditApiKey, setIsEditApiKey] = useState(false);
	const [editApiKeyJson, setEditApiKeyJson] = useState({});
	const [modalStatus, setModalStatus] = useState(true);
	const [apiKeyModalForm] = Form.useForm();
	const columns = [
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Status',
			dataIndex: 'active',
			key: 'active',
			render: (active, record) => <Switch checked={active} disabled />,
		},
		{
			title: 'Value',
			dataIndex: 'value',
			key: 'value',
			render: (text) => <Typography.Text style={{ overflow: 'wrap' }}>{text}
			<CopyOutlined style={{ marginLeft: 8, cursor: "pointer" }} onClick={() => copyToClipboard(text)}/>
			</Typography.Text>,
		},
		{
			title: 'Creation Time',
			dataIndex: 'creationTime',
			key: 'creationTime',
			sorter: (a, b) => a.creationTime - b.creationTime,
			defaultSortOrder: 'descend',
			render: (_, record) => <Typography.Text>{getDateFromTimeStamp(record.creationTime)}</Typography.Text>,
		},
		{
			title: 'Action',
			dataIndex: 'Action',
			key: 'Action',
			render: (_, record) => (
				<Flex gap="small">
					<Button onClick={() => editHandler(record.id)}>
						<FontAwesomeIcon icon={faPenToSquare} />
					</Button>
					<Button onClick={() => deleteHandler(record.id)}>
						<FontAwesomeIcon icon={faTrash} />
					</Button>
				</Flex>
			),
		},
	];

	const copyToClipboard = (text) => {
		navigator.clipboard.writeText(text);
		message.success("Key Value copied to clipboard");
	};

	const editHandler = (id) => {
		const editIndex = tableData.findIndex((rec) => {
			return rec.id === id;
		});
		if (editIndex !== -1) {
			const editJSON = tableData[editIndex];
			setEditApiKeyJson(editJSON);
			showModal('EDIT', editJSON);
		} else {
			notification.error({
				message: 'Error',
				description: 'Error Record Not Found!',
			});
		}
	};
	const deleteHandler = async(id) => {
		const response = await deleteApi(`/api/v1/apiKey/${id}`)
		if(response)  {
			setTableData((prevData) => prevData.filter((item) => item.id !== id));
			notification.success({
				message: 'Success',
				description: 'Item deleted successfully.',
			});
		}
	};

	const resetModalFormData = () => {
		apiKeyModalForm.resetFields();
		setEditApiKeyJson({});
	};
	const showModal = (action, json) => {
		if (action === 'CREATE') {
			handleModalStatus(true);
			apiKeyModalForm.setFieldsValue({ active: true });
			setIsEditApiKey(false);
			setIsModalOpen(true);
		} else if (action === 'EDIT') {
			apiKeyModalForm.setFieldsValue({ name: json.name });
			apiKeyModalForm.setFieldsValue({ active: json.active });
			apiKeyModalForm.setFieldsValue({ value: json.value });
			setIsEditApiKey(true);
			setIsModalOpen(true);
			handleModalStatus(json.active);
		}
	};
	const handleCancel = () => {
		setIsModalOpen(false);
		resetModalFormData();
	};

	const handleModalStatus = (status) => {
		setModalStatus(status);
	};

	useEffect(() => {
		getApiKEyList();
	}, []);

	const getApiKEyList = async() => {
		const response = await getApi('/api/v1/apiKey/list');
			if (response) {
				setTableData(response.data);
			} 
	}

	const onFinish = async(values) => {
		if (!values.name) {
			notification.error({
				message: 'Error',
				description: 'API Key Name is required.',
			});
			return;
		}
		if (!isEditApiKey) {
			const response = await postApi('/api/v1/apiKey/create', values)
				if (response) {
					setTableData([...tableData, response.data]);
					notification.success({
						message: 'Success',
						description: 'Item Created successfully.',
					});
				} 
		} else {
			const postData = {
				name: values.name,
				active: values.active,
				id: editApiKeyJson.id,
			};
			const response = await postApi('/api/v1/apiKey/update', postData)
				if (response) {
					const editIndex = tableData.findIndex((rec) => {
						return rec.id === response.data.id;
					});
					if (editIndex !== -1) {
						const updatedData = [...tableData];
						updatedData[editIndex] = response.data;
						setTableData(updatedData);
						notification.success({
							message: 'Success',
							description: 'Item updated successfully.',
						});
					}
				}
		}
		handleCancel();
	};
	const onFinishFailed = (values) => {};

	return (
		<Flex vertical gap={'large'}>
			<Flex justify="space-between">
				<Typography.Title level={4} style={{ margin: 0 }}>
					List Api Keys
				</Typography.Title>
				<Button type="primary" onClick={() => showModal('CREATE')}>
					Create API Key
				</Button>
			</Flex>
			<Table columns={columns} dataSource={tableData} />
			<Modal title={!isEditApiKey ? 'Create API Key' : 'Edit API Key'} open={isModalOpen} onOk={() => apiKeyModalForm.submit()} onCancel={handleCancel}>
				<Form form={apiKeyModalForm} onFinish={onFinish} onFinishFailed={onFinishFailed}>
					<Form.Item name="name" label="Name">
						<Input placeholder="Api Key Name" />
					</Form.Item>
					<Form.Item name="active" label="Status">
						<Switch
							checked={modalStatus}
							onChange={(checked) => {
								handleModalStatus(checked);
								apiKeyModalForm.setFieldsValue({ active: checked });
							}}
						/>
					</Form.Item>
					{isEditApiKey && (
						<Form.Item name="value" label="ApiKey">
							<Typography.Text>{apiKeyModalForm.getFieldValue('value')}</Typography.Text>
						</Form.Item>
					)}
				</Form>
			</Modal>
		</Flex>
	);
};

export default ListApiKey;

export const SET_IS_SIGN_UP = "SET_IS_SIGN_UP";
export const SET_APP_SETTINGS = "SET_APP_SETTINGS";
export const SET_NEW_NUMBER = "SET_NEW_NUMBER";
export const SET_TEMPLATE_DATA = "SET_TEMPLATE_DATA";
export const SET_SELECTED_TAB = "SET_SELECTED_TAB";
export const SET_SELECTED_TAB_DATA = "SET_SELECTED_TAB_DATA";
export const SET_TEMPLATE_VARIABLE = "SET_TEMPLATE_VARIABLE";
export const SET_CLEAR_ALL_DATA = "SET_CLEAR_ALL_DATA";
export const SET_SELECTED_PHONE_REC = "SET_SELECTED_PHONE_REC";
export const SET_CONNECTED_ACCOUNT_DATA = "SET_CONNECTED_ACCOUNT_DATA";
export const SET_CONTACT_LABEL = "SET_CONTACT_LABEL";
export const SET_CONTACT_ATTRIBUTES = "SET_CONTACT_ATTRIBUTES";
export const TOKEN_EXPIRE = "TOKEN_EXPIRE";
export const USER_CONFIG = "USER_CONFIG";
export const SET_CONNECTED_ACCOUNT_TABLE_DATA =
  "SET_CONNECTED_ACCOUNT_TABLE_DATA";
export const SET_MESSAGE_DATA = "SET_MESSAGE_DATA";
export const setIsSignUP = (items) => ({
  type: SET_IS_SIGN_UP,
  payload: items,
});

export const setNewNumber = (items) => ({
  type: SET_NEW_NUMBER,
  payload: items,
});

export const setTemplateData = (items) => ({
  type: SET_TEMPLATE_DATA,
  payload: items,
});
export const setSelectedTab = (items) => ({
  type: SET_SELECTED_TAB,
  payload: items,
});
export const setSelectedTabData = (items) => ({
  type: SET_SELECTED_TAB_DATA,
  payload: items,
});
export const setTemplateVariable = (items) => ({
  type: SET_TEMPLATE_VARIABLE,
  payload: items,
});
export const clearAllData = (items) => ({
  type: SET_CLEAR_ALL_DATA,
  payload: items,
});

export const setSelectedPhoneRec = (item) => ({
  type: SET_SELECTED_PHONE_REC,
  payload: item,
});
export const setConnectedAccountData = (item) => ({
  type: SET_CONNECTED_ACCOUNT_DATA,
  payload: item,
});
export const setContactLabel = (item) => ({
  type: SET_CONTACT_LABEL,
  payload: item,
});
export const setContactAttributes = (item) => ({
  type: SET_CONTACT_ATTRIBUTES,
  payload: item,
});
export const setTokenExpire = (item) => ({
  type: TOKEN_EXPIRE,
  payload: item,
});
export const setUserConfig = (item) => ({
  type: USER_CONFIG,
  payload: item,
});
export const setConnectedAccountTableData = (item) => ({
  type: SET_CONNECTED_ACCOUNT_TABLE_DATA,
  payload: item,
});
export const setMessageData = (item) => ({
  type: SET_MESSAGE_DATA,
  payload: item,
});

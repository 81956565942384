import {
  setConnectedAccountData,
  setConnectedAccountTableData,
  setUserConfig,
} from "../Store/action";
import { getApi } from "../constants/ApiConstant";
import { setUserConfigSettings } from "../constants/StorageUtil";

const getUserConfig = async () => {
  const response = await getApi("/api/v1/login/user/config");
  return response;
};
const getPhoneNoList = async () => {
  const response = await getApi("/api/v1/onboard/getPhones");
  if (response && response.status === 200) {
    const updatedData = [];
    for (let i = 0; i < response.data.length; i++) {
      updatedData.push({
        label: response.data[i].displayPhoneNumber,
        value: response.data[i].phoneNumberId,
      });
    }
    return updatedData;
  }
};
const getPhonesWithTeamInbox = async () => {
  const response = await getApi("/api/v1/onboard/getPhonesWithTeamInbox");
  if (response.status === 200) {
    return response.data;
  }
};

export const onLoginHandler = async (
  dispatch,
  isConnectedAccountListUpdate,
  state,
  navigate
) => {
  const userConfig = await getUserConfig();
  const phoneList = await getPhoneNoList();
  if (isConnectedAccountListUpdate) {
    const connectedAccountData = await getPhonesWithTeamInbox();
    dispatch(setConnectedAccountTableData(connectedAccountData));
  }
  if (userConfig.status === 200) {
    dispatch(setUserConfig(userConfig.data));
    setUserConfigSettings(userConfig.data);
    if (state === "/" && userConfig?.data?.userType === "USER") {
      navigate("ConnectedAccount");
    } else if (
      state === "/" &&
      (userConfig?.data?.userType === "ADMIN" ||
        userConfig?.data?.userType === "RESELLER")
    ) {
      navigate("DashBoard");
    }
  }
  dispatch(setConnectedAccountData(phoneList));
};

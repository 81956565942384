import { faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Flex, Form, ColorPicker, Input, Modal, Table, Typography, notification } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setContactLabel } from '../../Store/action';
import { getDateFromTimeStamp } from '../../constants/DateConstants';
import { deleteApi, postApi } from '../../constants/ApiConstant';

const ContactLabels = () => {
	const tableData = useSelector((state) => state.contactLabel || []);
	const [isEditContactLabel, setIsEditContactLabel] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [contactLabelModalForm] = Form.useForm();
	const [editContactLabelJSON, setEditContactLabelJSON] = useState({});
	const dispatch = useDispatch();
	const columns = [
		{
			title: 'Id',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Color',
			dataIndex: 'colorCode',
			key: 'colorCode',
			render: (_, record) => <ColorPicker value={record.colorCode} open={false} />,
		},
		{
			title: 'Description',
			dataIndex: 'description',
			key: 'description',
		},
		{
			title: 'Creation Time',
			dataIndex: 'creationTime',
			key: 'creationTime',
			sorter: true,
			defaultSortOrder: 'descend',
			render: (_, record) => <Typography.Text>{getDateFromTimeStamp(record.creationTime)}</Typography.Text>,
		},
		{
			title: 'LastUpdate Time',
			dataIndex: 'lastUpdateTime',
			key: 'lastUpdateTime',
			sorter: true,
			render: (_, record) => <Typography.Text>{getDateFromTimeStamp(record.lastUpdateTime)}</Typography.Text>,
		},
		{
			title: 'Action',
			dataIndex: 'Action',
			key: 'Action',
			render: (_, record) => (
				<Flex gap="small">
					<Button onClick={() => editHandler(record.id)}>
						<FontAwesomeIcon icon={faPenToSquare} />
					</Button>
					<Button onClick={() => deleteHandler(record.id)}>
						<FontAwesomeIcon icon={faTrash} />
					</Button>
				</Flex>
			),
		},
	];
	const deleteHandler = async(id) => {
		const response = await deleteApi(`/api/v1/contact/label/${id}`);
			if(response){
				getLabelData();
				notification.success({
					message: 'Success',
					description: 'Label deleted successfully.',
				});
			}
	};
	const editHandler = (id) => {
		const editIndex = tableData.findIndex((rec) => {
			return rec.id === id;
		});
		if (editIndex !== -1) {
			const editJSON = tableData[editIndex];
			setEditContactLabelJSON(editJSON);
			showModal('EDIT', editJSON);
		} else {
			notification.error({
				message: 'Error',
				description: 'Error Record Not Found!',
			});
		}
	};

	const getLabelData = async() => {
		let obj = {
			order: [
				{
					fieldName: 'creationTime',
					dir: 'desc',
				},
			],
		};
		const response = await postApi('/api/v1/contact/label/list', obj);
			if (response) {
				dispatch(setContactLabel(response.data.results));
			} 
	};
	useEffect(() => {
		if (tableData?.length === 0) {
			getLabelData();
		}
	}, [tableData]);
	const handleCancel = () => {
		setIsEditContactLabel(false);
		setIsModalOpen(false);
		contactLabelModalForm.resetFields();
	};
	const onFinish = async(values) => {
		if (!values.name) {
			notification.error({
				message: 'Error',
				description: 'Label Name is required.',
			});
			return;
		}
		if (!isEditContactLabel) {
			const response = await postApi('/api/v1/contact/label/save', values);
					if (response) {
						getLabelData();
						notification.success({
							message: 'Success',
							description: 'Label Created successfully.',
						});
					} 
		} else {
			const postData = {
				...values,
				id: editContactLabelJSON.id,
			};
			const response = await postApi('/api/v1/contact/label/save', postData);
					if (response) {
						getLabelData();
					} 
		}
		handleCancel();
	};
	const onFinishFailed = (values) => {
		console.log(values);
	};
	const showModal = (action, json) => {
		if (action === 'CREATE') {
			setIsEditContactLabel(false);
		} else if (action === 'EDIT') {
			contactLabelModalForm.setFieldsValue({ name: json.name });
			contactLabelModalForm.setFieldsValue({ description: json.description });
			contactLabelModalForm.setFieldsValue({ colorCode: json.colorCode });
			setIsEditContactLabel(true);
		}
		setIsModalOpen(true);
	};
	return (
		<Flex vertical gap={'large'}>
			<Flex justify="space-between">
				<Typography.Title level={4} style={{ margin: 0 }}>
					List Contact Labels
				</Typography.Title>
				<Button
					type="primary"
					onClick={() => {
						showModal('CREATE');
					}}
				>
					Create Contact Label
				</Button>
			</Flex>
			<Table columns={columns} dataSource={tableData} />
			<Modal
				title={!isEditContactLabel ? 'Create Contact Label' : 'Edit Contact Label'}
				open={isModalOpen}
				onOk={() => contactLabelModalForm.submit()}
				onCancel={handleCancel}
			>
				<Form
					form={contactLabelModalForm}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
					labelCol={{
						span: 5,
					}}
					wrapperCol={{
						span: 20,
					}}
				>
					<Form.Item name="name" label="Name">
						<Input placeholder="Name" />
					</Form.Item>
					<Form.Item name="description" label="Description">
						<Input.TextArea placeholder="Description" rows={3} />
					</Form.Item>
					<Form.Item name="colorCode" label="Color Code">
						<Input placeholder="colorCode" type="color" />
					</Form.Item>
				</Form>
			</Modal>
		</Flex>
	);
};

export default ContactLabels;

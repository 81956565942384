export const getDateFromTimeStamp = (str, language) => {
  let date = new Date(typeof str === "string" ? Number(str) : str);
  return (
    date.getFullYear() +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + date.getDate()).slice(-2) +
    " " +
    ("0" + date.getHours()).slice(-2) +
    ":" +
    ("0" + date.getMinutes()).slice(-2) +
    ":" +
    ("0" + date.getSeconds()).slice(-2)
  );
};

export const toTimestamp = (strDate) => {
  return new Date(strDate).getTime();
};

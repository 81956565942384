import { Flex, Typography } from "antd";
import { getUserConfigSettings } from "../../constants/StorageUtil";

const DashBoard = () => {
  const userConfig = getUserConfigSettings();
  return (
    <Flex vertical gap={"large"}>
      <Flex justify="space-between">
        <Typography.Title level={4} style={{ margin: 0 }}>
          {userConfig?.userType === "ADMIN"
            ? "Admin DashBoard"
            : userConfig?.userType === "RESELLER"
            ? "Reseller DashBoard "
            : ""}
        </Typography.Title>
      </Flex>
    </Flex>
  );
};
export default DashBoard;

// App.js
import { Layout, Typography, notification } from "antd";
import { Footer } from "antd/es/layout/layout";
import { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import Whitelabel from "./Components/Admin/Whitelabel/Whitelabel.js";
import ListApiKey from "./Components/ApiKey/ListApiKey";
import CannedMessage from "./Components/CannedMessage/CannedMessage";
import BasicChatBot from "./Components/ChatBot/BasicChatBot/BasicChatBot";
import BlockContacts from "./Components/Contacts/BlockContacts.js";
import ContactAttributes from "./Components/Contacts/ContactAttributes.js";
import ContactLabels from "./Components/Contacts/ContactLabels.js";
import Contacts from "./Components/Contacts/Contacts.js";
import DashBoard from "./Components/DashBoard/DashBoard.js";
import Flows from "./Components/Flows/Flows.js";
import { AppHeader } from "./Components/Layout/AppHeader";
import SideBar from "./Components/Layout/SideBar";
import Login from "./Components/Login/Login";
import Profile from "./Components/Profile/Profile.js";
import Report from "./Components/Reporting/Report";
import DomainSettings from "./Components/Reseller/DomainSettings/DomainSetttings.js";
import ResetPassword from "./Components/ResetPassword/ResetPassword.js";
import SendBulkMessage from "./Components/SendBulkMessage/SendBulkMessage";
import SendMessages from "./Components/SendMessage/SendMessage";
import Support from "./Components/Settings/Support.js";
import AddEditTemplate from "./Components/Template/AddEditTemplate.js";
import ListTemplate from "./Components/Template/ListTemplate.js";
import PreDefinedTemplate from "./Components/Template/PreDefinedTemplate.js";
import ConnectedAccount from "./Components/ViewNewNumber/ConnectedAccount";
import Webhook from "./Components/Webhook/Webhook";
import EmbeddedSignup from "./Components/WhiteLabel/EmbeddedSignup.js";
import Wrapper from "./Components/Wrapper/Wrapper.js";
import { setDomainSettings } from "./constants/StorageUtil.js";
import { AuthProvider, useAuth } from "./Context/AuthContext";
import { SidebarProvider } from "./Context/SidebarContext";
import { axiosLoginApi } from "./Util/axios";
import Customer from "./Components/Customer/Customer.js";
import Audit from "./Components/Audit/Audit.js";
const { Content } = Layout;

function App() {
  return (
    <AuthProvider>
      <AppRouter />
    </AuthProvider>
  );
}

function AppRouter() {
  const { isLoggedIn } = useAuth();
  const [domainData, setDomainData] = useState({});
  useEffect(() => {
    getDomainSettingData();
  }, []);
  const getDomainSettingData = () => {
    axiosLoginApi
      .get("/api/v1/whitelist/domain")
      .then((response) => {
        setDomainSettings(response.data);
        setDomainData(response.data);
        const favicon = document.getElementById("dynamic-favicon");
        if (favicon) {
          favicon.href = response.data.favIcoUrl;
        }
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error.message,
        });
      });
  };
  return (
    <Router>
      <Routes>
        {!isLoggedIn && (
          <>
            <Route
              path="/resetPassword"
              element={<ResetPassword domainSettingData={domainData} />}
            />
            <Route
              path="/"
              element={<Login domainSettingData={domainData} />}
            />
          </>
        )}
        <Route path="/whiteLabel/embeddedSignup" element={<EmbeddedSignup />} />
        <Route
          path="/*"
          element={
            isLoggedIn ? (
              <Wrapper isLoggedIn={isLoggedIn}>
                <Layout style={{ minHeight: "100vh" }}>
                  <SidebarProvider>
                    <AppHeader />
                    <Layout>
                      <SideBar />
                      <Content>
                        <Layout style={{ margin: "18px" }}>
                          <Routes>
                            <Route path="/" element={<DashBoard />} />
                            <Route path="/DashBoard" element={<DashBoard />} />
                            <Route path="/" element={<ConnectedAccount />} />
                            <Route
                              path="/ConnectedAccount"
                              element={<ConnectedAccount />}
                            />
                            <Route
                              path="/SendMessages"
                              element={<SendMessages />}
                            />
                            <Route
                              path="/SendBulkMessage"
                              element={<SendBulkMessage />}
                            />
                            <Route path="/Reporting" element={<Report />} />
                            <Route
                              path="/ListApikey"
                              element={<ListApiKey />}
                            />
                            <Route path="/Webhook" element={<Webhook />} />
                            <Route
                              path="/CannedMessage"
                              element={<CannedMessage />}
                            />
                            <Route
                              path="/basicChatBot"
                              element={<BasicChatBot />}
                            />
                            <Route path="/support" element={<Support />} />
                            <Route
                              path="/contactLabel"
                              element={<ContactLabels />}
                            />
                            <Route
                              path="/contactAttribute"
                              element={<ContactAttributes />}
                            />
                            <Route path="/contacts" element={<Contacts />} />
                            <Route
                              path="/blockContact"
                              element={<BlockContacts />}
                            />
                            <Route
                              path="/ListTemplate"
                              element={<ListTemplate />}
                            />
                            <Route
                              path="/preDefineTemplate"
                              element={<PreDefinedTemplate />}
                            />
                            <Route
                              path="/ListTemplate/CreateTemplate"
                              element={<AddEditTemplate />}
                            />
                            <Route path="/profile" element={<Profile />} />
                            <Route
                              path="/whiteLabel"
                              element={<Whitelabel />}
                            />
                            <Route
                              path="/DomainSettings"
                              element={<DomainSettings />}
                            />
                            <Route path="/Flows" element={<Flows />} />
                            <Route path="/Customer" element={<Customer />} />
                            <Route path="/Audit" element={<Audit />} />
                          </Routes>
                        </Layout>
                      </Content>
                    </Layout>
                    <Footer style={{ width: "100%", textAlign: "center" }}>
                      <Typography.Text>
                        &copy; {domainData?.applicationName}
                      </Typography.Text>
                    </Footer>
                  </SidebarProvider>
                </Layout>
              </Wrapper>
            ) : (
              <Login domainSettingData={domainData} />
            )
          }
        />
      </Routes>
    </Router>
  );
}

export default App;

import { useEffect, useState } from "react";
import TemplateMessagePreview from "./TemplateMessagePreview";

const CarouselMessagePreview = ({ data }) => {
  return (
    <div>
      <TemplateMessagePreview
        data={{
          components: data.components,
          card_Index: data.card_index,
        }}
        isNotLoadingData={true}
      />
    </div>
  );
};

export default CarouselMessagePreview;

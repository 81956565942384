import { Image } from "antd";

const ImageMessagePreview = ({ data }) => {
  return (
    <div>
      {!data.link && <div className="media-container image-placeholder"></div>}
      {data.link && (
        <div className="media-container">
          <img src={data.link} alt="Preview" width="100%" />
        </div>
      )}
      {data.caption && <pre>{data.caption}</pre>}
    </div>
  );
};

export default ImageMessagePreview;

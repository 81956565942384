export default function AddScript(url) {
	return new Promise((resolve, reject) => {
	  const script = document.createElement("script");
	  script.src = url;
	  script.async = true;
	  script.defer = true;
	  script.crossorigin = "anonymous";
	  script.onload = () => {
		resolve();
	  };
	  script.onerror = (error) => {
		reject(error);
	  };
	  document.body.appendChild(script);
	});
  }
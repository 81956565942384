import ButtonInteractiveMessagePreview from "./ButtonInteractiveMessagePreview";
import ListInteractiveMessagePreview from "./ListInteractiveMessagePreview";

const InteractiveMessagePreview = ({ data }) => {
  return (
    <div>
      {(data.type === "list" ||
        data.type === "cta_url" ||
        data.type === "catalog_message") && (
        <ListInteractiveMessagePreview data={data} />
      )}
      {data.type === "button" && (
        <ButtonInteractiveMessagePreview data={data} />
      )}
    </div>
  );
};

export default InteractiveMessagePreview;

export const validateChatwootPassword = (rule, value, callback) => {
	const regexUpperCase = /[A-Z]/;
	const regexLowerCase = /[a-z]/;
	const regexNumber = /\d/;
	const regexSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

	const hasUpperCase = regexUpperCase.test(value);
	const hasLowerCase = regexLowerCase.test(value);
	const hasNumber = regexNumber.test(value);
	const hasSpecialChar = regexSpecialChar.test(value);
	const isLengthValid = value ? value.length >= 8 : false;

	if (!hasUpperCase || !hasLowerCase || !hasNumber || !hasSpecialChar || !isLengthValid) {
		const errorMessage =
			'Password must be minimum 8 characters long and must have at least one uppercase letter, one lowercase letter, one number and one special character';
		callback(errorMessage);
	} else {
		callback();
	}
};

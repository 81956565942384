import {
  IS_CONNECTED_ACCOUNT_DATA_REFRESH,
  SET_APP_SETTINGS,
  SET_CLEAR_ALL_DATA,
  SET_CONNECTED_ACCOUNT_DATA,
  SET_CONNECTED_ACCOUNT_TABLE_DATA,
  SET_CONTACT_ATTRIBUTES,
  SET_CONTACT_LABEL,
  SET_IS_SIGN_UP,
  SET_MESSAGE_DATA,
  SET_NEW_NUMBER,
  SET_SELECTED_PHONE_REC,
  SET_SELECTED_TAB,
  SET_SELECTED_TAB_DATA,
  SET_TEMPLATE_DATA,
  SET_TEMPLATE_VARIABLE,
  TOKEN_EXPIRE,
  USER_CONFIG,
} from "./action";
const initialState = {
  isSignUp: false,
  appSettings: {},
  newNumberJSON: [],
  templateData: [],
  selectedTab: "Text",
  selectedTabData: {
    templateVariables: [],
  },
  templateVariable: 0,
  selectedPhoneRec: {},
  connectedAccountData: [],
  contactLabel: [],
  contactAttributes: [],
  isTokenExpire: false,
  userConfig: {},
  connectedAccountTableData: [],
  messageData: [],
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_SIGN_UP:
      return {
        ...state,
        isSignUp: action.payload,
      };
    case SET_APP_SETTINGS:
      return {
        ...state,
        appSettings: action.payload,
      };
    case SET_NEW_NUMBER:
      return {
        ...state,
        newNumberJSON: action.payload,
      };
    case SET_TEMPLATE_DATA:
      return {
        ...state,
        templateData: action.payload,
      };
    case SET_SELECTED_TAB:
      return {
        ...state,
        selectedTab: action.payload,
      };
    case SET_SELECTED_TAB_DATA:
      return {
        ...state,
        selectedTabData: action.payload,
      };
    case SET_TEMPLATE_VARIABLE:
      return {
        ...state,
        templateVariable: action.payload,
      };
    case SET_CLEAR_ALL_DATA:
      return {
        state: initialState,
      };
    case SET_SELECTED_PHONE_REC:
      return {
        ...state,
        selectedPhoneRec: action.payload,
      };
    case SET_CONNECTED_ACCOUNT_DATA:
      return {
        ...state,
        connectedAccountData: action.payload,
      };
    case SET_CONTACT_LABEL:
      return {
        ...state,
        contactLabel: action.payload,
      };
    case SET_CONTACT_ATTRIBUTES:
      return {
        ...state,
        contactAttributes: action.payload,
      };
    case TOKEN_EXPIRE:
      return {
        ...state,
        isTokenExpire: action.payload,
      };
    case USER_CONFIG:
      return {
        ...state,
        userConfig: action.payload,
      };
    case SET_CONNECTED_ACCOUNT_TABLE_DATA:
      return {
        ...state,
        connectedAccountTableData: action.payload,
      };
    case SET_MESSAGE_DATA:
      return {
        ...state,
        messageData: action.payload,
      };
    default:
      return state;
  }
};

export default rootReducer;

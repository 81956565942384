import { Carousel, Divider, Typography, Radio } from "antd";
import { useState } from "react";
import { MenuOutlined } from "@ant-design/icons";
import "./ListInteractiveMessagePreview.css";
const ListInteractiveMessagePreview = ({ data }) => {
  const [selectedValue, setSelectedValue] = useState(null);

  const handleRadioChange = (rowTitle) => {
    setSelectedValue(rowTitle);
  };

  return (
    <Carousel autoplay arrows={false} dots={false}>
      <div className="interactive-message-container">
        {data.header && data.header.text && (
          <Typography.Title
            level={4}
            style={{ margin: 0, textTransform: "capitalize" }}
          >
            {data.header.text}
          </Typography.Title>
        )}
        {!data.header && data.type === "catalog_message" && (
          <div className="image-catalog-container">
            <span></span>
            <p>View Business Catalog</p>
          </div>
        )}
        {data.body && data.body.text && (
          <Typography.Title level={5} style={{ margin: "5px 0px 0px 0px" }}>
            {data.body.text}
          </Typography.Title>
        )}
        {data.footer && data.footer.text !== "undefined" && (
          <span style={{ marginTop: 5 }}>{data.footer.text}</span>
        )}
        {data.type === "list" && data.action && data.action.button && (
          <>
            <Divider />
            <div className="list-button-container">
              <MenuOutlined />
              <a>{data.action.button}</a>
            </div>
          </>
        )}
        {data.type === "cta_url" && data.action && data.action.parameters && (
          <>
            <Divider />
            <div className="list-button-container">
              <MenuOutlined />
              <a href={data.action.parameters.url} target="_blank">
                {data.action.parameters.displayText}
              </a>
            </div>
          </>
        )}
        {data.type === "catalog_message" && (
          <>
            <Divider />
            <div className="list-button-container">
              <a>VIEW CATALOG</a>
            </div>
          </>
        )}
      </div>
      {data.type === "list" && (
        <div className="interactive-message-container">
          <Typography.Title
            level={4}
            style={{ margin: 0, textAlign: "center" }}
          >
            {data.action.button}
          </Typography.Title>
          <Radio.Group
            value={selectedValue}
            onChange={(e) => handleRadioChange(e.target.value)}
          >
            <ul className="list-outer-container">
              {data.action.sections.map((rec) => {
                return (
                  <li key={rec.title}>
                    <div>
                      <p>{rec.title}</p>
                      <ul className="list-inner-container">
                        {rec.rows.map((rowRec) => {
                          return (
                            <li key={rowRec.title}>
                              <div>
                                <span>
                                  {" "}
                                  <p>{rowRec.title}</p>
                                  <span>{rowRec.description}</span>
                                </span>
                              </div>
                              <Radio
                                value={rowRec.title}
                                style={{ marginRight: 8 }}
                              />
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </li>
                );
              })}
            </ul>
          </Radio.Group>
        </div>
      )}
    </Carousel>
  );
};

export default ListInteractiveMessagePreview;

import { Button, Form, Input } from 'antd';
import { useEffect, useState } from 'react';
import { TEXT_AREA_SIZE } from '../../constants/SizeLimit';
import { checkVariableNumber, validateParentheses } from './TemplateUtil';

const { TextArea } = Input;

const TemplateBody = ({ templateBodyData, setTemplateBodyData, id, label = 'Template Body' }) => {
	const [parenthesesCorrect, setParenthesesCorrect] = useState(true);
	const [variableCount, setVariableCount] = useState([]);
	useEffect(() => {
		let myString = templateBodyData?.bodyContent;
		let variableCount = (myString?.match(/{{\d+}}/g) || []).length;
		const inputs = Array.from({ length: variableCount }, (_, index) => index + 1);
		setVariableCount(inputs);
	}, [templateBodyData?.bodyContent]);
	const onBodyChangeHandler = (e) => {
		const updatedTemplateJSON = { ...templateBodyData };
		updatedTemplateJSON.bodyContent = e.target.value;
		setTemplateBodyData(updatedTemplateJSON);
		const result = validateParentheses(e.target.value);
		setParenthesesCorrect(result);
	};
	const handleKeyUp = (e) => {
		if (parenthesesCorrect) {
			const updatedTemplateJSON = { ...templateBodyData };
			let arr = updatedTemplateJSON?.bodyContent?.split('}}') || [];
			const updatedStr = checkVariableNumber(arr);
			updatedTemplateJSON.bodyContent = updatedStr;
			setTemplateBodyData(updatedTemplateJSON);
		}
	};
	const addBodyVariableHandler = () => {
		const bodyContent = templateBodyData.bodyContent || '';
		const textArea = document.getElementById(id); // Get the textarea element
		const startPos = textArea.selectionStart; // Get the cursor start position
		const endPos = textArea.selectionEnd; // Get the cursor end position

		// Find the position of the variable to be inserted after
		const variablePosition = bodyContent.substring(0, startPos).match(/{{\d+}}/g);
		const lastVariableNumber = variablePosition ? variablePosition.length : 0;

		// Construct the updated body content with the new variable at cursor position
		const updatedText = bodyContent.substring(0, startPos) + `{{${lastVariableNumber + 1}}}` + bodyContent.substring(endPos, bodyContent.length);

		// Update state with the modified body content
		const updatedTemplateJSON = JSON.parse(JSON.stringify(templateBodyData));
		updatedTemplateJSON.bodyContent = updatedText;
		setTemplateBodyData(updatedTemplateJSON);

		// Move the cursor position after the inserted variable
		textArea.selectionStart = startPos + 5; // Adjusted for '{{}}' length
		textArea.selectionEnd = startPos + 5;
	};
	const handleBodyVariableChane = (value, index) => {
		const updatedTemplateJSON = JSON.parse(JSON.stringify(templateBodyData));
		if (!updatedTemplateJSON.variableCountJSON) {
			updatedTemplateJSON.variableCountJSON = [];
		}
		updatedTemplateJSON.variableCountJSON[index] = value;
		setTemplateBodyData(updatedTemplateJSON);
	};
	return (
		<>
			<Form.Item name={`body_text${id}`} label={label}>
				<TextArea
					id={id}
					showCount
					className={parenthesesCorrect ? '' : 'error-textarea'}
					maxLength={TEXT_AREA_SIZE}
					placeholder={`Enter text message upto ${TEXT_AREA_SIZE}`}
					style={{
						height: 120,
						resize: 'none',
					}}
					value={templateBodyData?.bodyContent}
					onChange={(e) => onBodyChangeHandler(e)}
					onKeyUp={(e) => handleKeyUp(e)}
				/>
				<Button style={{ marginTop: 20 }} onClick={addBodyVariableHandler}>
					Add Variable
				</Button>
			</Form.Item>
			{variableCount.length > 0 &&
				variableCount.map((rec, index) => {
					const name = `count${index + 1}`;
					return (
						<Form.Item name={`name${id}${index}`} label={name}>
							<Input placeholder={name} onChange={(e) => handleBodyVariableChane(e.target.value, index)} />
						</Form.Item>
					);
				})}
		</>
	);
};

export default TemplateBody;

import React, { useState } from 'react';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Upload } from 'antd';

const beforeUpload = (file) => {
  const isAudio = file.type.startsWith('audio/');
  if (!isAudio) {
    message.error('You can only upload audio files!');
  }

  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Audio must be smaller than 2MB!');
  }

  return isAudio && isLt2M;
};

const SendContactMessage = (props) => {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    props.onChangeHandler(values, 'ContactMessage')
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };



  return (
    <>
      <Form
        form={form}
        name="audioForm"
        labelCol={{
          span: 5,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 600,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="First name"
          name="firstName"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Middle name"
          name="middleName"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Last name"
          name="lastName"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Formatted name"
          name="formattedName "
        >
          <Input />
        </Form.Item>
          
      </Form>
    </>
  );
};

export default SendContactMessage;

import { LockOutlined, MailOutlined, UserOutlined } from "@ant-design/icons";
import {
  Button,
  Flex,
  Form,
  Image,
  Input,
  Typography,
  notification,
} from "antd";
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import FormItem from "antd/es/form/FormItem";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import en from "world_countries_lists/data/countries/en/world.json";
import {
  clearLocalStorage,
  getDomainSettings,
  setSorageObj,
} from "../../constants/StorageUtil";
import { useAuth } from "../../Context/AuthContext";
import { setIsSignUP } from "../../Store/action";
import { axiosLoginApi } from "../../Util/axios";
import { onLoginHandler } from "../../Util/LoginApiUtil";
import "./Login.css";

const Login = ({ domainSettingData }) => {
  const isSignUp = useSelector((state) => state.isSignUp);
  const [loading, setLoading] = useState(false);
  const [domainData, setDomainData] = useState({});
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { login } = useAuth();
  const [isResetPasswod, setIsResetPassword] = useState(false);
  const state = useLocation().pathname;
  useEffect(() => {
    clearLocalStorage(["selectedPhoneId", "domainSettings"]);
  }, [dispatch]);
  useEffect(() => {
    setDomainData(getDomainSettings());
  }, [domainSettingData]);
  
  const signUpHandler = () => {
    form.resetFields();
    dispatch(setIsSignUP(true));
  };
  const signInHandler = () => {
    form.resetFields();
    dispatch(setIsSignUP(false));
  };
  const onFinish = (values) => {
    if (!isSignUp) {
      if (isResetPasswod) {
        onResetPasswordHandler(values);
      } else {
        loginHandler(values);
      }
    } else {
      createNewUserHandler(values);
    }
  };
  const onFinishFailed = (errorInfo) => { };
  const validatePhoneNumber = (rule, value) => {
    const phoneRegex = /^\d{9,14}$/;
    if (!phoneRegex.test(value.phone)) {
      return Promise.reject("Invalid phone number");
    }
    return Promise.resolve();
  };
  const onResetPasswordHandler = (values) => {
    const postData = { username: values.username };
    setLoading(true);
    axiosLoginApi
    .post("/api/v1/login/forgotPassword", postData)
    .then((response) => {
      if (response.data.success) {
        notification.success({
          message: "Success",
          description: response.data.message,
        });
        form.resetFields();
        setIsResetPassword(false);
      } else {
        notification.error({
          message: "Error",
          description: response.data.message,
        });
      }
    })
    .catch((error) => {
      notification.error({
        message: "Error",
        description: error.message,
      });
    })
    .finally(() => {
      setLoading(false); // Set loading to false regardless of success or failure
    });
  };
  const loginHandler = (values) => {
    const postData = { username: values.username, password: values.password };
    setLoading(true); // Set loading to true before making the server call
    
    axiosLoginApi
    .post("/api/v1/login/signin", JSON.stringify(postData))
    .then((response) => {
      if (response.status === 200) {
        const storageObj = {
          userName: values.username,
          token: response.data.token,
        };
        setSorageObj(storageObj);
        onLoginHandler(dispatch, false, state, navigate)
        .then((data) => { })
        .catch((error) => { });
        login();
        form.resetFields();
        if (state === "/") {
          navigate("ConnectedAccount");
        }
      } else {
        notification.error({
          message: "Error",
          description: "Username / Password not valid.",
        });
      }
    })
    .catch((error) => {
      if (error.response.status === 401) {
        notification.error({
          message: "Error",
          description: "Incorrect Username Password",
        });
      } else {
        notification.error({
          message: "Error",
          description: error.message,
        });
      }
    })
    .finally(() => {
      setLoading(false); // Set loading to false regardless of success or failure
    });
  };
  
  const createNewUserHandler = (values) => {
    const postData = {
      username: values.username,
      password: values.password,
      phoneNumber: values.phone.phone,
      email: values.email,
      phoneCountryCode: values.phone.code,
    };
    setLoading(true); // Set loading to true before making the server call
    
    axiosLoginApi
    .post("/api/v1/onboard/createUser", JSON.stringify(postData))
    .then((response) => {
      console.log("Response:", response.data);
      dispatch(setIsSignUP(false));
      form.resetFields();
      notification.success({
        message: "Success",
        description: "User created successfully.",
      });
    })
    .catch((error) => {
      notification.error({
        message: "Error",
        description:
        error.code === "ERR_NETWORK"
        ? error.message
        : error.response.data.detail,
      });
      console.error("Error:", error);
    })
    .finally(() => {
      setLoading(false); // Set loading to false regardless of success or failure
    });
  };
  
  return (
    <Flex
    vertical={true}
    justify="space-evenly"
    align="center"
    style={{ width: "100vw", height: "100vh" }}
    className="login-form"
    >
    <Image
    alt={domainData?.applicationName}
    src={domainData?.loginLogoUrl}
    preview={false}
    style={{ width: "30vw" }}
    />
    {!isSignUp && isResetPasswod && (
      <Typography.Title level={5} style={{ margin: 0 }}>
      Forgot Password
      </Typography.Title>
    )}
    {!isSignUp && (
      <>
      <Form
      form={form}
      labelAlign="left"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 24 }}
      style={{ width: "30vw" }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      >
      <FormItem
      label="Username"
      name="username"
      rules={[
        {
          required: true,
          message: "Please enter Username",
        },
      ]}
      >
      <Input
      placeholder="Username"
      prefix={<UserOutlined className="site-form-item-icon" />}
      ></Input>
      </FormItem>
      {!isResetPasswod && (
        <FormItem
        label="Password"
        name="password"
        rules={[{ required: true, message: "Please enter Password" }]}
        >
        <Input.Password
        inputType="Password"
        placeholder="Password"
        prefix={<LockOutlined className="site-form-item-icon" />}
        ></Input.Password>
        </FormItem>
      )}
      
      <Form.Item>
      <Button
      type="primary"
      size="large"
      htmlType="submit"
      block={true}
      loading={loading}
      >
      Submit
      </Button>
      </Form.Item>
      {isResetPasswod && (
        <Form.Item>
        <Flex justify="space-evenly" gap="middle" align="center">
        <Button type="text" onClick={() => setIsResetPassword(false)}>
        Cancel
        </Button>
        </Flex>
        </Form.Item>
      )}
      {!isResetPasswod && (
        <FormItem>
        <Flex justify="space-evenly" gap="middle" align="center">
        <Button
        type="text"
        onClick={() => {
          setIsResetPassword(true);
        }}
        >
        Forgot Password
        </Button>
        <Button type="text" onClick={signUpHandler}>
        Create Account
        </Button>
        </Flex>
        </FormItem>
      )}
      </Form>
      </>
    )}
    
    {isSignUp && (
      <>
      <Typography.Title
      level={3}
      style={{
        display: "flex",
        justifyContent: "center",
        paddingBottom: "20px",
      }}
      >
      Sign Up
      </Typography.Title>
      <Form
      form={form}
      labelAlign="left"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 24 }}
      style={{ width: "30vw" }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      >
      <FormItem
      label="Username"
      name="username"
      rules={[
        {
          required: true,
          message: "Please enter Username",
        },
        {
          pattern: new RegExp(/^[a-z0-9._@-]{3,50}$/),
          message:
          "Username must be minimum 3 characters long and can only contain lowercase letters (a-z), numbers (0-9), and the special characters . _ @ -",
        },
      ]}
      >
      <Input
      placeholder="Username"
      prefix={<UserOutlined className="site-form-item-icon" />}
      ></Input>
      </FormItem>
      <FormItem
      label="Password"
      name="password"
      rules={[{ required: true, message: "Please enter Password" }]}
      >
      <Input.Password
      inputType="Password"
      placeholder="Password"
      prefix={<LockOutlined className="site-form-item-icon" />}
      ></Input.Password>
      </FormItem>
      <FormItem
      label="Email"
      name="email"
      rules={[
        { type: "email", message: "Email is not valid" },
        { required: true, message: "Please enter Email" },
      ]}
      >
      <Input
      placeholder="Email"
      prefix={<MailOutlined className="site-form-item-icon" />}
      ></Input>
      </FormItem>
      <ConfigProvider locale={en}>
      <FormItem
      name="phone"
      label="Phone"
      initialValue={{ short: "in" }}
      rules={[
        { required: true, message: "Please enter Phone number" },
        { validator: validatePhoneNumber },
      ]}
      >
      <CountryPhoneInput
      placeholder="Phone"
      inline
      onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
      />
      </FormItem>
      </ConfigProvider>
      
      <Form.Item>
      <Button
      type="primary"
      size="large"
      htmlType="submit"
      block={true}
      loading={loading}
      >
      Register
      </Button>
      </Form.Item>
      
      <FormItem>
      <Flex justify="space-evenly" gap="middle" align="center">
      <Button type="text" onClick={signInHandler}>
      Login
      </Button>
      </Flex>
      </FormItem>
      </Form>
      </>
    )}
    </Flex>
  );
};

export default Login;

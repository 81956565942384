import { Button, Typography } from "antd";
import "./ListInteractiveMessagePreview.css";
const ButtonInteractiveMessagePreview = ({ data }) => {
  return (
    <div>
      <div className="interactive-message-container">
        {data.header && data.header.text && (
          <Typography.Title
            level={4}
            style={{ margin: 0, textTransform: "capitalize" }}
          >
            {data.header.text}
          </Typography.Title>
        )}
        {data.body && data.body.text && (
          <Typography.Title level={5} style={{ margin: "5px 0px 0px 0px" }}>
            {data.body.text}
          </Typography.Title>
        )}
        {data.footer && data.footer.text !== "undefined" && (
          <span style={{ marginTop: 5 }}>{data.footer.text}</span>
        )}
      </div>
      <ul className="intractive-button-container">
        {data.action.buttons.map((rec) => {
          return (
            <li key={rec.reply.id}>
              <Button>{rec.reply.title}</Button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ButtonInteractiveMessagePreview;

import { Button, Card, Col, Typography } from "antd";
import { useEffect } from "react";
import { useState } from "react";
export 
const MessagePreview = (props) => {
  const { type, payload, title,componentData,variableInputStates } = props;
    useEffect(() => {
    createPreviewMessage(componentData);
  },[variableInputStates,componentData])
  const regex = /\{\{(\d+)\}\}/g;
   const [formatedMessageData,setFormatedMessageData] = useState("");
   const [previewButtonJSON,setPreviewButtonJSON] = useState([]);
  const createPreviewMessage = (Data) => {
    setFormatedMessageData("");
    setPreviewButtonJSON([])

    setFormatedMessageData((prevFormattedMessage) => {
      let formattedMessage = prevFormattedMessage;
        if(Data && Data.length > 0) {
            for (let i = 0; i < Data.length; i++) {
                if (Data[i].type === 'HEADER' && Data[i].format === 'text') {
                  // Handle header text if needed
                } else if (Data[i].type === 'BODY' ||Data[i].type === 'FOOTER') {
                  formattedMessage  += '\n' + Data[i].text.replace(regex, (match, capture) => {
                    const variableIndex = parseInt(capture, 10);
                    return variableInputStates[variableIndex - 1] || match;
                  });
                } else if (Data[i].type === 'BUTTONS') {
                  setPreviewButtonJSON(Data[i].buttons);
                }
              }
          
              return formattedMessage;
        }
      
    });
  };
  const getCoverTemplate = (messageType,fileUrl) => {
    let template = "";
    if ((messageType === "Image" || messageType === "image") && fileUrl) {
      return (template = <img alt="example" src={payload.fileUrl} />);
    } else if ((messageType === "Video" || messageType === "video") && fileUrl) {
      return (
        <video width="300" height="300" controls>
          <source src={fileUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    } else if ((messageType === "Document" || messageType === "document" || messageType === "Audio" || messageType || 'audio') && fileUrl) {
      return (
        <iframe
          src={fileUrl}
          width="100%"
          height="auto"
          title="fileUrl"
        ></iframe>
      );
    }
    return template;
  };
  const getButtonTemplate = (rec,index) => {
    let template = ''
    if(rec.type === 'URL') {
       template = <Button style={{marginRight:5, marginBottom:5}}><a href={rec.url} target="_blank"> {rec.text}</a></Button>
       return template;
    } else {
      template = <Button style={{marginRight:5, marginBottom:5}} title={rec.text}>{rec.text}</Button>
      return template;
    }
  }
  return (
    <Col xs={24} sm={7} md={7} lg={7} xl={7}>
      {type === "Text" && (
        <Card title={title} style={{ width: "100%", marginBottom: 20 }}>
          {type === "Text" && payload?.textValue && (
            <Typography.Text>
              {" "}
              <pre>{payload.textValue}</pre>
            </Typography.Text>
          )}
        </Card>
      )}
      {(type === "Image" ||
        type === "Video" ||
        type === "Document" ||
        type === "Audio") && (
        <Card
          title={title}
          style={{ width: "100%", marginBottom: 20 }}
          hoverable
          cover={getCoverTemplate(type,payload?.fileUrl)}
        >
          {payload?.textValue && type !== "Audio" && (
            <Typography.Text>
              {" "}
              <pre>{payload.textValue}</pre>
            </Typography.Text>
          )}
        </Card>
      )}
      {type === "Template" && 
        <Card
        title={title}
        style={{ width: "100%", marginBottom: 20 }}
        hoverable
        cover={getCoverTemplate(payload.tabName,payload?.fileUrl)}
      >
        <div>
            <pre style={{ whiteSpace: 'pre-wrap', fontFamily: 'monospace', fontSize: '14px' }}>
            {formatedMessageData}
            </pre>
        </div>
        {previewButtonJSON.length > 0 && <div>
          {previewButtonJSON.map((rec, index) => {
              return getButtonTemplate(rec,index)
          })}
          </div>}
      </Card>}
    </Col>
  );
};

export default MessagePreview;

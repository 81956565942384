import axios from 'axios';
import { BASE_URL } from './env';
import { getSelectedPhoneId, getStorageObj } from '../constants/StorageUtil';

const commonProperties = {
	baseURL: BASE_URL,
	timeout: 120000,
};

const jsonHeader = {
	headers: {
		'Content-Type': 'application/json',
	},
};

const setInterceptors = (instance) => {
	instance.interceptors.request.use(
		(config) => {
			const {phoneNumberId } = config?.headers; 
			const token = getStorageObj().token;
			let phoneId = getSelectedPhoneId() ? getSelectedPhoneId().value : '';
			if(phoneNumberId) {
				phoneId = phoneNumberId;
			}
			if (token) {
				config.headers.Authorization = `Bearer ${token}`;
				config.headers['x-phone-id'] = phoneId;
			}
			return config;
		},
		(error) => Promise.reject(error)
	);
};

export const axiosApi = axios.create({
	...commonProperties,
	...jsonHeader,
});

export const axiosMultiPartApi = axios.create({
	...commonProperties,
	headers: {
		'Content-Type': 'multipart/form-data',
	},
});

// Other axiosLoginApi configuration remains the same
export const axiosLoginApi = axios.create({
	...commonProperties,
	...jsonHeader,
});

setInterceptors(axiosApi);
setInterceptors(axiosMultiPartApi);

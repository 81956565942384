import { useEffect } from "react";
import TemplateBody from "./TemplateBody";
import { handleBodyChange, handleButtonChange } from "./TemplateUtil";
import TemplateButton from "./TemplateButton";

const TemplateCatalog = ({ templateData, setTemplateData }) => {
  const templateId = Object.keys(templateData)[0];
  useEffect(() => {
    const updatedButtonJSON = JSON.parse(JSON.stringify(templateData));
    let obj = {
      type: "CATALOG",
      id: Math.floor(Math.random() * 2000),
      text: "View Catalog",
    };
    updatedButtonJSON[templateId].templateButton.push(obj);
    setTemplateData(updatedButtonJSON);
  }, [templateId]);
  return (
    <>
      <TemplateBody
        templateBodyData={templateData[templateId].templateBody}
        setTemplateBodyData={(updatedBody) =>
          handleBodyChange(
            templateId,
            updatedBody,
            templateData,
            setTemplateData
          )
        }
        id={templateId}
      />
      <TemplateButton
        allowdButtonValue={["QUICK REPLY"]}
        templateButtonData={templateData[templateId].templateButton}
        setTemplateButtonData={(updatedButton) =>
          handleButtonChange(
            templateId,
            updatedButton,
            templateData,
            setTemplateData
          )
        }
        hideAddButton={true}
        id={templateId}
      />
    </>
  );
};

export default TemplateCatalog;

import { notification } from "antd";
import { axiosApi, axiosMultiPartApi } from "../Util/axios";
import store from "../Store/store";
import { setTokenExpire } from "../Store/action";

export const postApi = async (url, payload) => {
  return axiosApi
    .post(url, payload)
    .then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        notification.error({
          message: "Error",
          description: "Error please try again",
        });
      }
    })
    .catch((error) => {
      if (error.response.status === 401) {
        handleTokenExpire(error);
      } else {
        return error.response.data;
      }
    });
};

export const postMultiPartApi = async (url, payload) => {
  return axiosMultiPartApi
    .post(url, payload)
    .then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        notification.error({
          message: "Error",
          description: "Error please try again",
        });
      }
    })
    .catch((error) => {
      if (error.response.status === 401) {
        handleTokenExpire(error);
      } else {
        return error.response.data;
      }
    });
};
export const getApi = (url) => {
  return axiosApi
    .get(url)
    .then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        notification.error({
          message: "Error",
          description: "Error please try again",
        });
      }
    })
    .catch((error) => {
      handleTokenExpire(error);
    });
};
export const deleteApi = async (url) => {
  return axiosApi
    .delete(url)
    .then((response) => {
      if (response.status === 200) {
        return response;
      } else {
        notification.error({
          message: "Error",
          description: "Error please try again",
        });
      }
    })
    .catch((error) => {
      handleTokenExpire(error);
    });
};

const handleTokenExpire = (error) => {
  if (
    error.response.status === 401 &&
    error.response.data.includes("JWT expired")
  ) {
    store.dispatch(setTokenExpire(true));
    notification.error({
      message: "Error",
      description: "Session expired. Please login again.",
    });
  } else {
    notification.error({
      message: "Error",
      description: error.response.data,
    });
  }
};

export const TEMPLATE_CATEGOTY_JSON = [
	{
		name: 'MARKETING',
		label: 'Marketing',
		id: 'MARKETING',
		type: ['CATALOG', 'FLOWS', 'ORDER DETAILS', 'LOCATION', 'CAROUSEL', 'CUSTOM'],
	},
	{
		label: 'Utility',
		name: 'UTILITY',
		id: 'UTILITY',
		type: ['FLOWS', 'ORDER DETAILS', 'ORDER STATUS', 'CUSTOM'],
	},
	{
		name: 'AUTHENTICATION',
		id: 'AUTHENTICATION',
		label: 'Authentication',
		type: ['Otp'],
	},
];
export const TEMPLATE_TYPE_JSON = [
	{
		name: 'NONE',
		label: 'None',
		id: 'NONE',
	},
	{
		name: 'CATALOG',
		label: 'Catalog',
		id: 'CATALOG',
	},
	{
		name: 'CUSTOM',
		label: 'Custom',
		id: 'CUSTOM',
	},
	{
		name: 'FLOWS',
		label: 'Flows',
		id: 'FLOWS',
	},
	/* {
      name: "ORDER DETAILS",
      label:"Order Details",
      id: "ORDER_DETAILS",
    },
    {
      name: "ORDER STATUS",
      label:"Order Status",
      id: "ORDER_Status",
    },
    {
      name: "LOCATION",
      label:"Location",
      id: "LOCATION",
    }, */
	{
		name: 'CAROUSEL',
		label: 'Carousel',
		id: 'CAROUSEL',
	},
	/*  {
      name:"AUTHENTICATION",
      label:"Authentication",
      id:"AUTHENTICATION"
    } */
];
export const TEMPLATE_LANGUAGE_JSON = [
	{
		code: 'af',
		name: 'Afrikaans',
	},
	{
		code: 'sq',
		name: 'Albanian',
	},
	{
		code: 'am',
		name: 'Amharic',
	},
	{
		code: 'ar',
		name: 'Arabic',
	},
	{
		code: 'hy',
		name: 'Armenian',
	},
	{
		code: 'az',
		name: 'Azerbaijani',
	},
	{
		code: 'eu',
		name: 'Basque',
	},
	{
		code: 'be',
		name: 'Belarusian',
	},
	{
		code: 'bn',
		name: 'Bengali',
	},
	{
		code: 'bs',
		name: 'Bosnian',
	},
	{
		code: 'bg',
		name: 'Bulgarian',
	},
	{
		code: 'ca',
		name: 'Catalan',
	},
	{
		code: 'ceb',
		name: 'Cebuano',
	},
	{
		code: 'ny',
		name: 'Chichewa',
	},
	{
		code: 'zh',
		name: 'Chinese (Simplified)',
	},
	{
		code: 'zh-TW',
		name: 'Chinese (Traditional)',
	},
	{
		code: 'co',
		name: 'Corsican',
	},
	{
		code: 'hr',
		name: 'Croatian',
	},
	{
		code: 'cs',
		name: 'Czech',
	},
	{
		code: 'da',
		name: 'Danish',
	},
	{
		code: 'nl',
		name: 'Dutch',
	},
	{
		code: 'en',
		name: 'English',
	},
	{
		code: 'eo',
		name: 'Esperanto',
	},
	{
		code: 'et',
		name: 'Estonian',
	},
	{
		code: 'tl',
		name: 'Filipino',
	},
	{
		code: 'fi',
		name: 'Finnish',
	},
	{
		code: 'fr',
		name: 'French',
	},
	{
		code: 'fy',
		name: 'Frisian',
	},
	{
		code: 'gl',
		name: 'Galician',
	},
	{
		code: 'ka',
		name: 'Georgian',
	},
	{
		code: 'de',
		name: 'German',
	},
	{
		code: 'el',
		name: 'Greek',
	},
	{
		code: 'gu',
		name: 'Gujarati',
	},
	{
		code: 'ht',
		name: 'Haitian Creole',
	},
	{
		code: 'ha',
		name: 'Hausa',
	},
	{
		code: 'haw',
		name: 'Hawaiian',
	},
	{
		code: 'iw',
		name: 'Hebrew',
	},
	{
		code: 'hi',
		name: 'Hindi',
	},
	{
		code: 'hmn',
		name: 'Hmong',
	},
	{
		code: 'hu',
		name: 'Hungarian',
	},
	{
		code: 'is',
		name: 'Icelandic',
	},
	{
		code: 'ig',
		name: 'Igbo',
	},
	{
		code: 'id',
		name: 'Indonesian',
	},
	{
		code: 'ga',
		name: 'Irish',
	},
	{
		code: 'it',
		name: 'Italian',
	},
	{
		code: 'ja',
		name: 'Japanese',
	},
	{
		code: 'jw',
		name: 'Javanese',
	},
	{
		code: 'kn',
		name: 'Kannada',
	},
	{
		code: 'kk',
		name: 'Kazakh',
	},
	{
		code: 'km',
		name: 'Khmer',
	},
	{
		code: 'rw',
		name: 'Kinyarwanda',
	},
	{
		code: 'ko',
		name: 'Korean',
	},
	{
		code: 'ku',
		name: 'Kurdish (Kurmanji)',
	},
	{
		code: 'ky',
		name: 'Kyrgyz',
	},
	{
		code: 'lo',
		name: 'Lao',
	},
	{
		code: 'la',
		name: 'Latin',
	},
	{
		code: 'lv',
		name: 'Latvian',
	},
	{
		code: 'lt',
		name: 'Lithuanian',
	},
	{
		code: 'lb',
		name: 'Luxembourgish',
	},
	{
		code: 'mk',
		name: 'Macedonian',
	},
	{
		code: 'mg',
		name: 'Malagasy',
	},
	{
		code: 'ms',
		name: 'Malay',
	},
	{
		code: 'ml',
		name: 'Malayalam',
	},
	{
		code: 'mt',
		name: 'Maltese',
	},
	{
		code: 'mi',
		name: 'Maori',
	},
	{
		code: 'mr',
		name: 'Marathi',
	},
	{
		code: 'mn',
		name: 'Mongolian',
	},
	{
		code: 'my',
		name: 'Myanmar (Burmese)',
	},
	{
		code: 'ne',
		name: 'Nepali',
	},
	{
		code: 'no',
		name: 'Norwegian',
	},
	{
		code: 'or',
		name: 'Odia (Oriya)',
	},
	{
		code: 'ps',
		name: 'Pashto',
	},
	{
		code: 'fa',
		name: 'Persian',
	},
	{
		code: 'pl',
		name: 'Polish',
	},
	{
		code: 'pt',
		name: 'Portuguese',
	},
	{
		code: 'pa',
		name: 'Punjabi',
	},
	{
		code: 'ro',
		name: 'Romanian',
	},
	{
		code: 'ru',
		name: 'Russian',
	},
	{
		code: 'sm',
		name: 'Samoan',
	},
	{
		code: 'gd',
		name: 'Scots Gaelic',
	},
	{
		code: 'sr',
		name: 'Serbian',
	},
	{
		code: 'st',
		name: 'Sesotho',
	},
	{
		code: 'sn',
		name: 'Shona',
	},
	{
		code: 'sd',
		name: 'Sindhi',
	},
	{
		code: 'si',
		name: 'Sinhala',
	},
	{
		code: 'sk',
		name: 'Slovak',
	},
	{
		code: 'sl',
		name: 'Slovenian',
	},
	{
		code: 'so',
		name: 'Somali',
	},
	{
		code: 'es',
		name: 'Spanish',
	},
	{
		code: 'su',
		name: 'Sundanese',
	},
	{
		code: 'sw',
		name: 'Swahili',
	},
	{
		code: 'sv',
		name: 'Swedish',
	},
	{
		code: 'tg',
		name: 'Tajik',
	},
	{
		code: 'ta',
		name: 'Tamil',
	},
	{
		code: 'tt',
		name: 'Tatar',
	},
	{
		code: 'te',
		name: 'Telugu',
	},
	{
		code: 'th',
		name: 'Thai',
	},
	{
		code: 'tr',
		name: 'Turkish',
	},
	{
		code: 'tk',
		name: 'Turkmen',
	},
	{
		code: 'uk',
		name: 'Ukrainian',
	},
	{
		code: 'ur',
		name: 'Urdu',
	},
	{
		code: 'ug',
		name: 'Uyghur',
	},
	{
		code: 'uz',
		name: 'Uzbek',
	},
	{
		code: 'vi',
		name: 'Vietnamese',
	},
	{
		code: 'cy',
		name: 'Welsh',
	},
	{
		code: 'xh',
		name: 'Xhosa',
	},
	{
		code: 'yi',
		name: 'Yiddish',
	},
	{
		code: 'yo',
		name: 'Yoruba',
	},
	{
		code: 'zu',
		name: 'Zulu',
	},
];
export const TEMPLATE_HEADER_JSON = [
	{
		value: '',
		label: 'None',
		id: 1,
	},
	{
		value: 'TEXT',
		label: 'Text',
		id: 2,
	},
	{
		value: 'IMAGE',
		label: 'Image',
		id: 3,
	},
	{
		value: 'VIDEO',
		label: 'Video',
		id: 4,
	},
	{
		value: 'CAROUSEL',
		label: 'Carousel',
		id: 5,
	},
	{
		value: 'LOCATION',
		label: 'Location',
		id: 6,
	},
];
export const CARD_HEADER_JSON = [
	{
		value: '',
		label: 'None',
		id: 1,
	},
	{
		value: 'IMAGE',
		label: 'Image',
		id: 3,
	},
	{
		value: 'VIDEO',
		label: 'Video',
		id: 4,
	},
];
export const TEMPLATE_FOOTER_BUTTON_JSON = [
	{
		value: 'QUICK_REPLY',
		label: 'Quick Reply Button',
		id: 1,
		key: 'QUICK_REPLY',
	},
	{
		value: 'URL',
		label: 'Url Button',
		id: 2,
		key: 'URL',
	},
	{ value: 'PHONE_NUMBER', label: 'Phone Button', id: 3, key: 'PHONE_NUMBER' },
];

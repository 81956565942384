import { SearchOutlined } from "@ant-design/icons";
import { Button, DatePicker, Flex, Input, Space } from "antd";
import dayjs from "dayjs";
const { RangePicker } = DatePicker;

export const auditByFilterJSON = [
  { text: "USER", value: "USER" },
  { text: "SYSTEM", value: "SYSTEM" },
  { text: "RESELLER", value: "RESELLER" },
  { text: "ADMIN", value: "ADMIN" },
];

export const auditTypeFilterJSON = [
  { text: "ACCOUNT_CREATED", value: "ACCOUNT_CREATED" },
  { text: "PHONE_ADDED", value: "PHONE_ADDED" },
  { text: "PLAN_APPLIED", value: "PLAN_APPLIED" },
  { text: "FEATURE_APPLIED", value: "FEATURE_APPLIED" },
  { text: "WCHATTER_ACCOUNT_CREATED", value: "WCHATTER_ACCOUNT_CREATED" },
  { text: "CONVERTED_TO_RESELLER", value: "CONVERTED_TO_RESELLER" },
  { text: "CHANGED_ROLE_VALIDITY", value: "CHANGED_ROLE_VALIDITY" },
  { text: "WHITE_LABEL_DOMAIN_CREATED", value: "WHITE_LABEL_DOMAIN_CREATED" },
  { text: "RESELLER_ASSIGNED", value: "RESELLER_ASSIGNED" },
  { text: "RESELLER_REMOVED", value: "RESELLER_REMOVED" },
];

export const getTextColumnSearchProps = (
  dataIndex,
  name,
  searchInput,
  handleSearch
) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    close,
  }) => (
    <Flex
      gap="small"
      vertical
      style={{ padding: 8 }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <Input
        ref={searchInput}
        placeholder={`Search ${name}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
        >
          Search
        </Button>
        <Button type="link" onClick={() => close()} size="small">
          Close
        </Button>
      </Space>
    </Flex>
  ),
});

export const getDateColumnSearchProps = (
  dataIndex,
  name,
  setDates,
  setValue,
  handleSearch
) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    close,
  }) => (
    <Flex
      gap="small"
      vertical
      style={{ padding: 8 }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <RangePicker
        presets={[...rangePresets]}
        showTime
        onCalendarChange={(val) => setDates(val)}
        format="YYYY-MM-DD HH:mm:ss"
        onChange={(dates, dateStrings) => {
          setSelectedKeys([dateStrings[0], dateStrings[1]]);
          setValue(dates);
        }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button type="link" size="small" onClick={() => close()}>
          Close
        </Button>
      </Space>
    </Flex>
  ),
});

export const rangePresets = [
  { label: "Last 1 Day", value: [dayjs().add(-1, "day"), dayjs()] },
  { label: "Last 7 Days", value: [dayjs().add(-7, "day"), dayjs()] },
  { label: "Last 30 Days", value: [dayjs().add(-30, "day"), dayjs()] },
];

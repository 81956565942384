import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Select,
  Space,
} from "antd";
import { Header } from "antd/es/layout/layout";
import { useAuth } from "../../Context/AuthContext";
import { useSidebar } from "../../Context/SidebarContext";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { setSelectedPhoneRec } from "../../Store/action";
import { colors, widths } from "../../constants/LayoutConstants";
import {
  getDomainSettings,
  getSelectedPhoneId,
  getStorageObj,
  getUserConfigSettings,
  setSelectedPhoneId,
} from "../../constants/StorageUtil";
import PhoneInput from "react-phone-input-2";
import { postApi } from "../../constants/ApiConstant";
import { onLoginHandler } from "../../Util/LoginApiUtil";
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import en from "world_countries_lists/data/countries/en/world.json";
import isoCountries from "i18n-iso-countries";

isoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export const AppHeader = () => {
  const { logout } = useAuth();
  const { collapsed, toggleSidebar } = useSidebar();
  const connectedAccountData = useSelector(
    (state) => state.connectedAccountData
  );
  const [selectedConnectedAccount, setSelectedConnectedAccount] = useState({});
  const [domainData, setDomainData] = useState({});
  const [userData, setUserData] = useState({});
  const userConfigJson = useSelector((state) => state.userConfig);
  const [isEmailPhoneModalOen, setIsEmailPhoneModalOpen] = useState(false);
  const [emailPhoneKeyModalForm] = Form.useForm();
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("in");
  const state = useLocation().pathname;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    setDomainData(getDomainSettings());
  }, []);
  useEffect(() => {
    const localStorageObj = getSelectedPhoneId();
    if (connectedAccountData?.length > 0) {
      const localStorageIndex = connectedAccountData.findIndex(
        (rec) => rec.value === localStorageObj?.value
      );
      if (localStorageIndex !== -1) {
        setSelectedConnectedAccount({ ...localStorageObj });
      } else {
        if (connectedAccountData.length === 1) {
          setSelectedPhoneId(connectedAccountData[0]);
          setSelectedConnectedAccount({ ...connectedAccountData[0] });
        } else {
          const obj = { label: "Select Phone No.", value: "Select Phone No." };
          setSelectedPhoneId(obj);
          setSelectedConnectedAccount({ ...obj });
        }
      }
    }
  }, [connectedAccountData]);
  const validatePhoneNumber = (rule, value) => {
    const phoneRegex = /^\d{9,14}$/;
    if (!phoneRegex.test(value.phone)) {
      return Promise.reject("Invalid phone number");
    }
    return Promise.resolve();
  };

  const items = [
    {
      key: "Profile",
      label: <Link to="/profile">Profile</Link>,
    },
    {
      key: "logout",
      label: (
        <a
          onClick={(e) => {
            e.preventDefault();
            logout();
          }}
        >
          Logout
        </a>
      ),
    },
  ];
  useEffect(() => {
    const userJSON =
      userConfigJson && Object.keys(userConfigJson).length > 0
        ? userConfigJson
        : getUserConfigSettings();
    if (userJSON) {
      setUserData(userJSON);
    }
    if (userJSON && (!userJSON.email || !userJSON?.phoneNumber)) {
      setIsEmailPhoneModalOpen(true);
    }
    if (userJSON) {
      setUserData(userJSON);

      const countryCode = isoCountries.getAlpha2Code(
        userJSON?.phoneCountryCode,
        "in"
      );

      emailPhoneKeyModalForm.setFieldsValue({
        email: userJSON?.email || "",
        phone: {
          short: countryCode || "in",
          phone: userJSON?.phoneNumber || "",
        },
      });
    }
  }, [userConfigJson]);
  const handleChange = (phoneNumberId) => {
    const selectedRec = connectedAccountData.find((rec) => {
      return rec.value === phoneNumberId;
    });
    if (selectedRec) {
      setSelectedPhoneId(selectedRec);
      dispatch(setSelectedPhoneRec(selectedRec));
    }
  };
  const onFinish = async (values) => {
    const payload = {
      phoneCountryCode: values?.phone?.code,
      phoneNumber: values?.phone?.phone,
      email: values.email,
    };
    const response = await postApi("/api/v1/profile/update", payload);
    if (response.status === 200) {
      setIsEmailPhoneModalOpen(false);
      onLoginHandler(dispatch, false, state, navigate)
        .then((data) => {})
        .catch((error) => {});
    }
  };
  const onFinishFailed = (values) => {};
  return (
    <>
      <Header style={{ backgroundColor: colors.headerBackground }}>
        <Row>
          <Col style={{ width: widths.sidebarWidth - 60 }}>
            <Image
              alt={domainData?.applicationName}
              src={domainData?.sidebarLogoUrl}
              preview={false}
              width={"70%"}
            />
          </Col>
          <Col flex={"auto"} style={{ paddingLeft: "24px" }}>
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={toggleSidebar}
            />
          </Col>
          <Col>
            {connectedAccountData && connectedAccountData.length > 1 && (
              <Space wrap>
                <Select
                  defaultValue={selectedConnectedAccount}
                  key={selectedConnectedAccount.value}
                  style={{
                    width: 220,
                    marginRight: 10,
                  }}
                  onChange={handleChange}
                  options={connectedAccountData}
                />
              </Space>
            )}
            <Dropdown menu={{ items }} trigger={["click"]}>
              <a onClick={(e) => e.preventDefault()}>
                <Space size={"small"}>
                  <Avatar>
                    {getStorageObj().userName.substring(0, 1).toUpperCase()}
                  </Avatar>
                  {getStorageObj().userName}
                  <FontAwesomeIcon icon={faCaretDown} />
                </Space>
              </a>
            </Dropdown>
          </Col>
        </Row>
      </Header>
      <Modal
        title="Update phone and email details of account"
        open={isEmailPhoneModalOen}
        width={700}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => emailPhoneKeyModalForm.submit()}
          >
            OK
          </Button>,
        ]}
      >
        <Form
          form={emailPhoneKeyModalForm}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 16 }}
          style={{ minWidth: "700px", width: "100%" }}
        >
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: "Please enter Email",
              },
            ]}
          >
            <Input
              type="text"
              value={userData?.email}
              disabled={userData?.email}
            />
          </Form.Item>
          <ConfigProvider locale={en}>
            <Form.Item
              name="phone"
              label="Phone"
              initialValue={{ short: "in" }}
              rules={[
                { required: true, message: "Please enter Phone number" },
                { validator: validatePhoneNumber },
              ]}
            >
              <CountryPhoneInput
                placeholder="Phone"
                inline
                disabled={userData?.phoneNumber}
                onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
              />
            </Form.Item>
          </ConfigProvider>
        </Form>
      </Modal>
    </>
  );
};

import ImageMessagePreview from "./ImageMessagePreview";
import TextMessagePreview from "./TextMessagePreview";
import "./OutSendMessagePreview.css";
import VideoMessagePreview from "./VideoMessagePreview";
import DocumentMessagePreview from "./DocumentMessagePreview";
import TemplateMessagePreview from "./TemplateMessagePreview";
import InteractiveMessagePreview from "./InteractiveMessagePreview";

const OutSendMessagePreview = ({ data }) => {
  return (
    <div className="message-preview-container">
      <div className="message-preview-inner-container">
        {data.type === "text" && <TextMessagePreview data={data.text.body} />}
        {data.type === "image" && <ImageMessagePreview data={data.image} />}
        {data.type === "video" && <VideoMessagePreview data={data.video} />}
        {data.type === "document" && (
          <DocumentMessagePreview data={data.document} />
        )}
        {data.type === "template" && (
          <TemplateMessagePreview data={data.template} />
        )}
        {data.type === "interactive" && (
          <InteractiveMessagePreview data={data.interactive} />
        )}
      </div>
    </div>
  );
};

export default OutSendMessagePreview;

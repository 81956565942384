import { Col, Input, Layout, Menu, Modal, Row, Tag, notification } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSidebar } from "../../Context/SidebarContext";
import {
  adminSideBarJSON,
  resellerSideBarJSON,
  sideBarList,
} from "../../constants/Constant";
import { widths } from "../../constants/LayoutConstants";
import { useDispatch, useSelector } from "react-redux";
import { getUserConfigSettings } from "../../constants/StorageUtil";
import SwitchUser from "../SwitchUser/SwitchUser";

const { SubMenu } = Menu;

const SideBar = (props) => {
  const { collapsed } = useSidebar();
  const location = useLocation();
  const [selectedKeys, setSelectedKeys] = useState(["3"]); // Set initial selected key
  const [openKeys, setOpenKeys] = useState([]);
  const dispatch = useDispatch();
  const userConfigData = useSelector((state) => state.userConfig);
  const [sideBarJSON, setSideBarJSON] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    if (userConfigData && Object.keys(userConfigData).length > 0) {
      setSideBarJSONLIST(userConfigData);
    } else {
      const userConfig = getUserConfigSettings();
      setSideBarJSONLIST(userConfig);
    }
  }, [userConfigData]);
  const setSideBarJSONLIST = async (userConfig) => {
    if (userConfig?.userType === "ADMIN") {
      setSideBarJSON(adminSideBarJSON);
    } else if (userConfig?.userType === "USER") {
      setSideBarJSON(sideBarList);
    } else if (userConfig?.userType === "RESELLER") {
      setSideBarJSON(resellerSideBarJSON);
    }
  };

  useEffect(() => {
    const currentPath = location.pathname;
    const selectedItem = sideBarJSON.find((item) => {
      if (item.submenu) {
        return item.submenu.some(
          (subItem) => `/${subItem.linkTo}` === currentPath
        );
      } else {
        return `/${item.linkTo}` === currentPath;
      }
    });

    if (selectedItem) {
      if (selectedItem.submenu) {
        setSelectedKeys([
          selectedItem.key,
          selectedItem.submenu.find(
            (subItem) => `/${subItem.linkTo}` === currentPath
          ).key,
        ]);
        setOpenKeys([selectedItem.key]);
      } else {
        setSelectedKeys([selectedItem.key]);
        setOpenKeys([]);
      }
    }
  }, [location.pathname]);

  const handleMenuClick = (key, label) => {
    if (key === "SwitchUser") {
      setIsModalOpen(true);
    }
    setSelectedKeys([key]);
    setOpenKeys([key]);
  };
  const handleSubMenuOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  const renderSubMenu = (subMenu) => (
    <SubMenu key={subMenu.key} icon={subMenu.icon} title={subMenu.label}>
      {subMenu.submenu.map((item) => (
        <Menu.Item key={item.key}>
          <Link
            to={`/${item.linkTo}`}
            onClick={() => handleMenuClick(item.key, item.label)}
          >
            <Row>
              <Col>{item.label}</Col>
              <Col flex={"auto"}></Col>
              {item.isNewUpdate && (
                <Col>
                  <Tag bordered={false} color={"success"}>
                    New
                  </Tag>
                </Col>
              )}
            </Row>
          </Link>
        </Menu.Item>
      ))}
    </SubMenu>
  );

  return (
    <>
      <Layout.Sider
        trigger={null}
        width={widths.sidebarWidth}
        collapsible
        collapsed={collapsed}
      >
        <Menu
          mode="inline"
          selectedKeys={selectedKeys}
          openKeys={openKeys} // Set openKeys dynamically
          onOpenChange={handleSubMenuOpenChange} // Handle submenu open change
          style={{ height: "100%" }}
        >
          {sideBarJSON.map((item) =>
            item.submenu ? (
              renderSubMenu(item)
            ) : (
              <Menu.Item key={item.key} icon={item.icon}>
                <Link
                  to={`/${item.linkTo}`}
                  onClick={() => handleMenuClick(item.key, item.label)}
                >
                  {item.label}
                </Link>
              </Menu.Item>
            )
          )}
        </Menu>
      </Layout.Sider>
      {isModalOpen && (
        <SwitchUser isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      )}
    </>
  );
};

export default SideBar;

import {
  Button,
  Flex,
  Form,
  Input,
  Modal,
  Typography,
  notification,
} from "antd";
import { useState } from "react";
import { postApi } from "../../../constants/ApiConstant";

const Whitelabel = () => {
  const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);
  const [keyForAddEditModalForm] = Form.useForm();
  const onAddEditModalOpen = (type) => {
    setIsAddEditModalOpen(true);
  };
  const onFinish = async (values) => {
    const response = await postApi("/api/v1/admin/whitelabel/domain", values);
    if (response.status === 200) {
      notification.success({
        message: "Success",
        description: response.data.message,
      });
      keyForAddEditModalForm.resetFields();
      handleCancel();
    } else {
      if (response.status === 409) {
        notification.error({
          message: "Error",
          description: response.detail,
        });
      } else {
        notification.error({
          message: "Error",
          description: "Please try again",
        });
      }
    }
  };
  const onFinishFailed = (values) => {};
  const handleCancel = () => {
    setIsAddEditModalOpen(false);
  };
  return (
    <Flex vertical gap={"large"}>
      <Flex justify="space-between">
        <Typography.Title level={4} style={{ margin: 0 }}>
          List White Label
        </Typography.Title>
        <Button type="primary" onClick={onAddEditModalOpen}>
          Create Whitelabel
        </Button>
      </Flex>
      <Modal
        title={"Create White Label"}
        open={isAddEditModalOpen}
        onOk={() => keyForAddEditModalForm.submit()}
        onCancel={handleCancel}
      >
        <Form
          form={keyForAddEditModalForm}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item name="hostname" label="Host Name">
            <Input placeholder="Enter Host Name" />
          </Form.Item>
          <Form.Item name="resellerId" label="Reseller Id">
            <Input placeholder="Enter Reseller Id" />
          </Form.Item>
        </Form>
      </Modal>
    </Flex>
  );
};

export default Whitelabel;

import { Segmented } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setSelectedTab, setSelectedTabData, setTemplateVariable } from '../../Store/action';
import SendAudioMessage from '../SendMessage/SendAudioMessage';
import SendContactMessage from '../SendMessage/SendContactMessage';
import SendDocumentMessage from '../SendMessage/SendDocumentMessage';
import SendImageMessage from '../SendMessage/SendImageMessage';
import SendInteractiveMessage from '../SendMessage/SendInteractiveMessage';
import SendLocationMessage from '../SendMessage/SendLocationMessage';
import SendTemplateMessage from '../SendMessage/SendTemplateMessage';
import SendTextMessage from '../SendMessage/SendTextMessage';
import SendVideoMessage from '../SendMessage/SendVideoMessage';

const CustomTabs = (props) => {
	const { tabData, childFormRef, formData, messageForm } = props;
	const dispatch = useDispatch();
	const [activeSegMent, setActiveSegment] = useState('Text');
	const handleSegmentChange = (activeKey) => {
		dispatch(setSelectedTab(activeKey));
		dispatch(setSelectedTabData([]));
		dispatch(setTemplateVariable(0));
		setActiveSegment(activeKey);
	};

	return (
		<>
			<Segmented options={tabData} block onChange={handleSegmentChange} />
			{activeSegMent === 'Text' && <SendTextMessage childFormRef={childFormRef} formData={formData} messageForm={messageForm} />}
			{activeSegMent === 'Image' && <SendImageMessage childFormRef={childFormRef} formData={formData} messageForm={messageForm} />}
			{activeSegMent === 'Video' && <SendVideoMessage childFormRef={childFormRef} formData={formData} messageForm={messageForm} />}
			{activeSegMent === 'Document' && <SendDocumentMessage childFormRef={childFormRef} formData={formData} messageForm={messageForm} />}
			{activeSegMent === 'Audio' && <SendAudioMessage childFormRef={childFormRef} formData={formData} messageForm={messageForm} />}
			{activeSegMent === 'Location' && <SendLocationMessage childFormRef={childFormRef} formData={formData} messageForm={messageForm} />}
			{activeSegMent === 'Contacts' && <SendContactMessage />}
			{activeSegMent === 'Template' && <SendTemplateMessage childFormRef={childFormRef} formData={formData} messageForm={messageForm} />}
			{activeSegMent === 'Interactive' && <SendInteractiveMessage childFormRef={childFormRef} formData={formData} messageForm={messageForm} />}
		</>
	);
};

export default CustomTabs;

import { Flex, Typography } from "antd";
import { useEffect, useState } from "react";
import { getApi } from "../../constants/ApiConstant";

const PreDefinedTemplate = () => {
    const [preDefinedTemplateList,setPreDefinedTemplate] = useState([]);
    useEffect(() => {
        getPreDefinedTemplateList();
    },[])
    const getPreDefinedTemplateList = async() => {
        const response = await getApi('/api/v1/messageTemplate/preDefined/list');
        if(response.status === 200) {
            setPreDefinedTemplate(response.data);
        }
    }
    return(
        <Flex vertical gap={'large'}>
        <Flex justify="space-between">
            <Typography.Title level={4} style={{ margin: 0 }}>
                Pre Define Templates
            </Typography.Title>
        </Flex>
            <Flex justify="space-between">
                <ul>
                {preDefinedTemplateList.map((rec,index) => {
                    return (
                        <li>
                            <span>{rec.name}</span>
                            <span>{rec.description}</span>
                            <span>{rec.group}</span>
                        </li>
                    )
                })}
                </ul>
            </Flex>
        </Flex>
    )
}

export default PreDefinedTemplate;
import { faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Flex, Form, Input, Modal, Table, Typography, notification } from 'antd';
import { useEffect, useState } from 'react';
import { getDateFromTimeStamp } from '../../constants/DateConstants';
import { deleteApi, postApi } from '../../constants/ApiConstant';

const BlockContacts = () => {
	const [tableData, setTableData] = useState([]);
	const [isEditBlockContact, setIsEditBlockContact] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [blockContactModalForm] = Form.useForm();
	const [editBlockContactJSON, setEditBlockContactJSON] = useState({});
	const columns = [
		{
			title: 'Phone Number',
			dataIndex: 'phoneNumber',
			key: 'phoneNumber',
		},
		{
			title: 'Creation Time',
			dataIndex: 'creationTime',
			key: 'creationTime',
			sorter: true,
			defaultSortOrder: 'descend',
			render: (_, record) => <Typography.Text>{getDateFromTimeStamp(record.creationTime)}</Typography.Text>,
		},
		{
			title: 'LastUpdate Time',
			dataIndex: 'lastUpdateTime',
			key: 'lastUpdateTime',
			sorter: true,
			render: (_, record) => <Typography.Text>{getDateFromTimeStamp(record.creationTime)}</Typography.Text>,
		},
		{
			title: 'Action',
			dataIndex: 'Action',
			key: 'Action',
			render: (_, record) => (
				<Flex gap="small">
					<Button
						onClick={() => {
							editHandler(record.id);
						}}
					>
						<FontAwesomeIcon icon={faPenToSquare} />
					</Button>
					<Button onClick={() => deleteHandler(record.id)}>
						<FontAwesomeIcon icon={faTrash} />
					</Button>
				</Flex>
			),
		},
	];
	const deleteHandler = async(id) => {
		const response = await deleteApi(`/api/v1/contact/blocked/${id}`);
			if(response){
				getBlockContactData();
				notification.success({
					message: 'Success',
					description: response.data.message,
				});
			}
	};
	const editHandler = (id) => {
		const editIndex = tableData.findIndex((rec) => {
			return rec.id === id;
		});
		if (editIndex !== -1) {
			const editJSON = tableData[editIndex];
			setEditBlockContactJSON(editJSON);
			showModal('EDIT', editJSON);
		} else {
			notification.error({
				message: 'Error',
				description: 'Error Record Not Found!',
			});
		}
	};

	const getBlockContactData = async() => {
		let obj = {
			order: [
				{
					fieldName: 'creationTime',
					dir: 'desc',
				},
			],
		};
		const response = await postApi('/api/v1/contact/blocked/list', obj);
			if (response) {
				setTableData(response.data.results);
			} 
	};
	useEffect(() => {
		getBlockContactData();
	}, []);
	const handleCancel = () => {
		setIsEditBlockContact(false);
		setIsModalOpen(false);
		blockContactModalForm.resetFields();
	};
	const onFinish = async(values) => {
		if (!values.phoneNumber) {
			notification.error({
				message: 'Error',
				description: 'Phone Number is required.',
			});
			return;
		}
		if (!isEditBlockContact) {
			const response = await postApi('/api/v1/contact/blocked/save', values);
				if (response) {
					notification.success({
						message: 'Success',
						description: response.message,
					});
					getBlockContactData();
				} 
		} else {
			const postData = {
				...values,
				id: editBlockContactJSON.id,
			};
			const response = await postApi('/api/v1/contact/blocked/save', postData);
				if (response) {
					getBlockContactData();
				notification.success({
						message: 'Success',
						description: response.message,
					});
				}
		}
		handleCancel();
	};
	const onFinishFailed = (values) => {
		console.log(values);
	};
	const showModal = (action, json) => {
		if (action === 'CREATE') {
			setIsEditBlockContact(false);
		} else if (action === 'EDIT') {
			blockContactModalForm.setFieldsValue({ phoneNumber: json.phoneNumber });
			setIsEditBlockContact(true);
		}
		setIsModalOpen(true);
	};
	return (
		<Flex vertical gap={'large'}>
			<Flex justify="space-between">
				<Typography.Title level={4} style={{ margin: 0 }}>
					List Block Contact
				</Typography.Title>
				<Button
					type="primary"
					onClick={() => {
						showModal('CREATE');
					}}
				>
					Block Contact
				</Button>
			</Flex>
			<Table columns={columns} dataSource={tableData} />
			<Modal
				title={!isEditBlockContact ? 'Block Contact' : 'Edit Blocked Contact'}
				open={isModalOpen}
				onOk={() => blockContactModalForm.submit()}
				onCancel={handleCancel}
			>
				<Form
					form={blockContactModalForm}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
					labelCol={{
						span: 7,
					}}
					wrapperCol={{
						span: 20,
					}}
				>
					<Form.Item name="phoneNumber" label="Phone Number">
						<Input placeholder="Phone Number" />
					</Form.Item>
				</Form>
			</Modal>
		</Flex>
	);
};

export default BlockContacts;

import { Card, Col, Form, Input, Row, Select, notification } from "antd";
import { useEffect, useImperativeHandle, useState } from "react";
import { getApi, postApi } from "../../constants/ApiConstant";
import MessagePreview from "../MessagePreview/MessagePreview";
import UploadFile from "./CommonComponents/UploadComponrnt/UploadFile";

const SendTemplateMessage = (props) => {
  const { childFormRef, formData, messageForm } = props;
  const [templateData, setTemplateData] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [variableList, setVariableList] = useState([]);
  const [isFileUpload, setIsFileUpload] = useState(false);
  const [isLocationHeader, setIsLocationHeader] = useState(false);
  const [tabName, setTabName] = useState("");
  const [uploadFile, setUploadedFile] = useState([]);
  const [fileUrl, setFileUrl] = useState("");
  const [isFileUrl, setIsFileUrl] = useState(false);
  const [variableInputStates, setVariableInputStates] = useState({});
  const [fileUpload, setFileUpload] = useState([]);
  const [isCarouselType, setIsCarouselType] = useState(false);

  const resetAllFields = () => {
    setSelectedTemplate("");
    setSelectedLanguage("");
    setVariableList([]);
    setIsFileUpload(false);
    setIsLocationHeader(false);
    setTabName("");
    setUploadedFile([]);
    setFileUrl("");
    setFileUpload([]);
    setIsCarouselType(false);
    setVariableInputStates(variableList.map(() => ""));
  };
  useEffect(() => {
    getTemplatesForSendMessage();
  }, []);
  const getTemplatesForSendMessage = async () => {
    const response = await getApi(
      "api/v1/messageTemplate/getTemplatesForSendMessage"
    );
    if (response) {
      setTemplateData(response.data);
    }
  };
  const options = templateData.map((item, index) => ({
    value: item.name + "$" + item.language,
    label: item.name + "\u00A0\u00A0\u00A0-\u00A0\u00A0\u00A0" + item.language,
    key: index,
  }));
  const handleInputChange = (index, value) => {
    setVariableInputStates((prevStates) => {
      const updatedInputStates = { ...prevStates, [index]: value };
      return updatedInputStates;
    });
  };
  const validFormField = () => {
    if (isFileUpload && Object.keys(uploadFile).length === 0) {
      notification.error({
        message: "Error",
        description: "Please Upload document!!",
      });
      return false;
    }
    if (!selectedTemplate) {
      notification.error({
        message: "Error",
        description: "Please Enter Select Template!!",
      });
      return false;
    }
    return true;
  };

  const getFormData = () => {
    return new Promise((resolve, reject) => {
      messageForm
        .validateFields()
        .then((values) => {
          const isValid = validFormField();
          if (!isValid) {
            return true;
          }
          formData.append("templateName", selectedTemplate);
          formData.append("language", selectedLanguage);
          for (const key in values) {
            if (Object.prototype.hasOwnProperty.call(values, key)) {
              if (key.includes("templateVariables")) {
                if (!values[key]) {
                  notification.error({
                    message: "Error",
                    description: "Please Enter Variable Value!!!",
                  });
                  return;
                }
                formData.append(key, values[key]);
              }
            }
          }
          if (isCarouselType) {
            for (let i = 0; i < uploadFile.length; i++) {
              if (uploadFile[i].originFileObj) {
                formData.append(
                  `files[${i}].uploadFile`,
                  uploadFile[i].originFileObj
                );
              } else {
                formData.append(`files[${i}].fileUrl`, uploadFile[i]);
              }
            }
          } else {
            if (!isFileUrl) {
              formData.append("fileUrl", uploadFile);
            } else {
              formData.append("uploadFile", uploadFile[0].originFileObj);
            }
          }

          if (isLocationHeader) {
            formData.append("location.longitude", values.longitude);
            formData.append("location.latitude", values.latitude);
            formData.append("location.name", values.name);
            formData.append("location.address", values.address);
          }
          resolve(formData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  useImperativeHandle(childFormRef, () => ({
    formData: getFormData,
    resetData: resetAllFields,
  }));
  const [previewMessageData, setPreviewMessageData] = useState([]);

  const handleChange = (values) => {
    resetAllFields();
    setSelectedLanguage(values.split("$")[1]);
    setSelectedTemplate(values.split("$")[0]);
    const postData = {
      language: values.split("$")[1],
      name: values.split("$")[0],
    };
    postApi("api/v1/messageTemplate/getTemplateDetail", postData).then(
      (response) => {
        if (response) {
          setPreviewMessageData(response.data.components);
          let variableCount = countVariables(response.data.components);
          for (let i = 0; i < response.data.components.length; i++) {
            let key = response.data.components[i];
            if (key.type === "CAROUSEL") {
              setIsCarouselType(true);
              for (let j = 0; j < key.cards.length; j++) {
                variableCount += countVariables(key.cards[j].components);
              }
            }
          }
          let countArray = [];
          for (var i = 0; i < variableCount; i++) {
            countArray.push(i);
          }
          setVariableList(countArray);
        }
      }
    );
  };
  const countVariables = (jsonArray) => {
    let variableCount = 0;

    jsonArray.forEach((obj) => {
      if (obj.type === "HEADER" && !obj.text) {
        if (
          obj.format === "VIDEO" ||
          obj.format === "DOCUMENT" ||
          obj.format === "IMAGE"
        ) {
          setTabName(obj.format.toLowerCase());
          setFileUpload((prevFileUpload) => {
            const updatedFileUpload = [...prevFileUpload];
            const obj1 = {
              id: Math.floor(Math.random() * 20000),
              tabName: obj.format.toLowerCase(),
            };
            updatedFileUpload.push(obj1);
            return updatedFileUpload;
          });

          setIsFileUpload(true);
        } else if (obj.format === "LOCATION") {
          setIsLocationHeader(true);
        }
      }
      if (obj.text && obj.type !== "BUTTONS") {
        const matches = obj.text.match(/{{\d+}}/g);
        if (matches) {
          variableCount += matches.length;
        }
      } else if (obj.type === "BUTTONS") {
        obj.buttons.forEach((inObj) => {
          if (inObj.text) {
            const matches = inObj.text.match(/{{\d+}}/g);
            if (matches) {
              variableCount += matches.length;
            }
          }
          if (inObj.url) {
            const matches = inObj.url.match(/{{\d+}}/g);
            if (matches) {
              variableCount += matches.length;
            }
          }
        });
      }
    });

    return variableCount;
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };
  const uploadFileChangeHandler = (data, isFileUpload, fileUrl, index) => {
    const updatedFileData = [...uploadFile];
    updatedFileData[index] = data;
    setUploadedFile(updatedFileData);
    setFileUrl(fileUrl);
    setIsFileUrl(isFileUpload);
  };
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  return (
    <div style={{ padding: "20px 0px" }}>
      <Row style={{ display: "flex", justifyContent: "space-between" }}>
        <Col xs={24} sm={15} md={15} lg={15} xl={15}>
          <Card style={{ width: "100%", marginBottom: 20 }}>
            <Form.Item label="Select Template" name="selectedTemplate">
              <Select
                showSearch
                placeholder="Select a person"
                optionFilterProp="children"
                onChange={handleChange}
                onSearch={onSearch}
                filterOption={filterOption}
                defaultValue=""
                style={{
                  width: "70%",
                  marginBottom: 10,
                }}
                options={options}
              />
            </Form.Item>
            {isFileUpload && !isCarouselType && (
              <Form.Item>
                <div>
                  <UploadFile
                    uploadFileName={"Upload" + " " + tabName}
                    uploadFileChangeHandler={(data, isFileUpload, fileUrl) =>
                      uploadFileChangeHandler(data, isFileUpload, fileUrl, 0)
                    }
                    tabName={tabName}
                    documentUplod={tabName}
                  />
                </div>
              </Form.Item>
            )}
            {fileUpload &&
              isCarouselType &&
              fileUpload.length > 0 &&
              fileUpload.map((rec, index) => {
                return (
                  <Form.Item>
                    <div>
                      <UploadFile
                        uploadFileName={"Upload" + " " + rec.tabName}
                        uploadFileChangeHandler={(
                          data,
                          isFileUpload,
                          fileUrl
                        ) =>
                          uploadFileChangeHandler(
                            data,
                            isFileUpload,
                            fileUrl,
                            index
                          )
                        }
                        tabName={rec.tabName}
                        documentUplod={rec.tabName}
                      />
                    </div>
                  </Form.Item>
                );
              })}
            {isLocationHeader && (
              <>
                <Form.Item name="name" label="Name">
                  <Input placeholder="Please Enter Name" />
                </Form.Item>
                <Form.Item name="address" label="address">
                  <Input placeholder="please enter Address" />
                </Form.Item>
                <Form.Item name="latitude" label="Latitude">
                  <Input placeholder="please enter Latitude" />
                </Form.Item>
                <Form.Item name="longitude" label="Longitude">
                  <Input placeholder="please enter Longitude" />
                </Form.Item>
              </>
            )}

            {variableList.length > 0 &&
              variableList.map((rec, index) => (
                <Form.Item
                  key={index}
                  name={`templateVariables[${index}]`}
                  label={`Variable[${index}]`}
                >
                  <Input
                    placeholder="Enter Value..."
                    value={variableInputStates[index] || ""}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                  />
                </Form.Item>
              ))}
          </Card>
        </Col>
        <MessagePreview
          type="Template"
          payload={{ fileUrl: fileUrl, tabName: tabName }}
          componentData={previewMessageData}
          variableInputStates={variableInputStates}
          title="Preview"
        />
      </Row>
    </div>
  );
};

export default SendTemplateMessage;

import React, { createContext, useContext, useState } from 'react';

const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
	const [collapsed, setCollapsed] = useState(false);
	const toggleSidebar = () => {
		setCollapsed((prevCollapsed) => !prevCollapsed);
	};
	return <SidebarContext.Provider value={{ collapsed, toggleSidebar }}>{children}</SidebarContext.Provider>;
};

export const useSidebar = () => useContext(SidebarContext);

import { Card, Form, Input } from 'antd';
import { useImperativeHandle } from 'react';

const { TextArea } = Input;
const SendCtaButtonMessage = (props) => {
	const { childFormRef, formData, messageForm } = props;

	const resetAllFields = () => {
		messageForm.resetFields();
	};
	const getFormData = () => {
		return new Promise((resolve, reject) => {
			messageForm
				.validateFields()
				.then((values) => {
					for (const [key, value] of Object.entries(values)) {
						formData.append(key, value);
					}
					resolve(formData);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};
	useImperativeHandle(childFormRef, () => ({
		formData: getFormData,
		resetData: resetAllFields,
	}));
	return (
		<Form.Item>
			<Card>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						flexWrap: 'wrap',
					}}
				>
					<Form.Item name="ctaUrl.header" labelCol={{ span: 7 }} label="Header(Optional)" style={{ width: '48%' }}>
						<TextArea
							placeholder={`Enter Header`}
							style={{
								height: 120,
								resize: 'none',
							}}
						/>
					</Form.Item>
					<Form.Item
						name="ctaUrl.body"
						labelCol={{ span: 7 }}
						label="Body"
						style={{ width: '48%' }}
						rules={[
							{
								required: true,
								message: 'Please enter Body',
							},
						]}
					>
						<TextArea
							placeholder={`Enter Body`}
							style={{
								height: 120,
								resize: 'none',
							}}
						/>
					</Form.Item>
					<Form.Item name="ctaUrl.footer" labelCol={{ span: 7 }} label="Footer(Optional)" style={{ width: '48%' }}>
						<TextArea
							placeholder={`Enter Footer`}
							style={{
								height: 120,
								resize: 'none',
							}}
						/>
					</Form.Item>
					<Form.Item
						name="ctaUrl.button"
						labelCol={{ span: 7 }}
						style={{ width: '48%' }}
						label="Button Text"
						rules={[
							{
								required: true,
								message: 'Please enter Header Button Text',
							},
						]}
					>
						<Input placeholder="Enter value" />
					</Form.Item>
					<Form.Item
						name="ctaUrl.url"
						style={{ width: '48%' }}
						labelCol={{ span: 7 }}
						label="Button Url"
						rules={[
							{
								required: true,
								message: 'Please enter Header Button Url',
							},
						]}
					>
						<Input placeholder="Enter value" />
					</Form.Item>
				</div>
			</Card>
		</Form.Item>
	);
};

export default SendCtaButtonMessage;

import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Flex,
  Form,
  Input,
  List,
  Modal,
  Select,
  Space,
  Table,
  Typography,
  notification,
} from "antd";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getApi, postApi } from "../../constants/ApiConstant";
import { InitialTablePostData } from "../../constants/Constant";
import {
  getDateFromTimeStamp,
  toTimestamp,
} from "../../constants/DateConstants";
import {
  DEFAULT_PAGE_SIZE,
  rangePresets,
} from "../../constants/ReportConstant";
import { getUserConfigSettings } from "../../constants/StorageUtil";
const { RangePicker } = DatePicker;

const { TextArea } = Input;
const Customer = (props) => {
  const [customerTableParams, setCustomerTableParams] =
    useState(InitialTablePostData);
  const [customerTableData, setCustomerTableData] = useState([]);
  const [value, setValue] = useState(null);
  const [dates, setDates] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState("");
  const [apiKeyModalForm] = Form.useForm();
  const [searchPhoneNo, setSearchPhoneNo] = useState("");
  const [selectedAction, setSelectedAction] = useState("");
  const [resellerList, setResellerList] = useState([]);

  let userConfigData = useSelector((state) => state.userConfig);
  if (userConfigData && Object.keys(userConfigData).length === 0) {
    userConfigData = getUserConfigSettings();
  }

  const [expandedRows, setExpandedRows] = useState([]);
  useEffect(() => {
    getTableData(customerTableParams);
  }, []);

  const handleTableChange = (pagination, filters, sorter) => {
    const paginationObj = {
      current: pagination?.current ?? 1,
      pageSize: pagination?.pageSize ?? DEFAULT_PAGE_SIZE,
    };
    const sortObj = sorter.order
      ? [
          {
            fieldName: sorter.columnKey,
            dir: sorter.order === "descend" ? "desc" : "asc",
          },
        ]
      : InitialTablePostData.order;
    const searchObj = Object.entries(filters ?? {})
      .map(([key, value]) => ({
        fieldName:
          key !== "displayPhoneNumber" && key !== "whatsappBusinessAccountId"
            ? key
            : "wabaTokens." + key,
        value:
          value !== null
            ? key !== "creationTime"
              ? value
              : value.map(toTimestamp)
            : null,
      }))
      .filter(({ value }) => value);
    setCustomerTableParams({
      ...customerTableParams,
      pagination: paginationObj,
      search: searchObj,
      order: sortObj,
    });
    getTableData({
      pagination: paginationObj,
      search: searchObj,
      order: sortObj,
    });
  };
  const navigate = useNavigate();
  const onNavigateHandler = (Id, stateName) => {
    navigate(`/${stateName}`, { state: { Id } });
  };
  const adminWabaTableColumn = [
    {
      title: "Display Number",
      dataIndex: "displayPhoneNumber",
      key: "displayPhoneNumber",
    },
    {
      title: "Phone Number ID",
      dataIndex: "phoneNumberId",
      key: "phoneNumberId",
    },
    {
      title: "WABA ID",
      dataIndex: "whatsappBusinessAccountId",
      key: "whatsappBusinessAccountId",
    },

    {
      title: "Business ID",
      dataIndex: "businessId",
      key: "businessId",
    },
    {
      title: "Creation Time",
      dataIndex: "creationTime",
      key: "creationTime",
      render: (_, record) => getDateFromTimeStamp(record.creationTime),
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      render: (_, record) => (
        <Flex gap="small">
          <Button
            onClick={() => onNavigateHandler(record.phoneNumberId, "Reporting")}
          >
            Report
          </Button>
        </Flex>
      ),
    },
  ];
  const resellerWabaTableColumns = [
    {
      title: "Display Number",
      dataIndex: "displayPhoneNumber",
      key: "displayPhoneNumber",
    },
    {
      title: "Phone Number ID",
      dataIndex: "phoneNumberId",
      key: "phoneNumberId",
    },
    {
      title: "WABA ID",
      dataIndex: "whatsappBusinessAccountId",
      key: "whatsappBusinessAccountId",
    },

    {
      title: "Business ID",
      dataIndex: "businessId",
      key: "businessId",
    },
    {
      title: "Creation Time",
      dataIndex: "creationTime",
      key: "creationTime",
      render: (_, record) => getDateFromTimeStamp(record.creationTime),
    },
  ];
  const expandedRowRender = (record) => {
    return (
      record.wabaTokens.length > 0 && (
        <Table
          style={{ marginBottom: 10 }}
          onChange={handleTableChange}
          columns={
            userConfigData?.userType === "ADMIN"
              ? adminWabaTableColumn
              : resellerWabaTableColumns
          }
          dataSource={record.wabaTokens}
          pagination={false}
        />
      )
    );
  };

  const getDateColumnSearchProps = (dataIndex, name) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <Flex
        gap="small"
        vertical
        style={{ padding: 8 }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <RangePicker
          presets={[...rangePresets]}
          showTime
          onCalendarChange={(val) => setDates(val)}
          format="YYYY-MM-DD HH:mm:ss"
          onChange={(dates, dateStrings) => {
            setSelectedKeys([dateStrings[0], dateStrings[1]]);
            setValue(dates);
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button type="link" size="small" onClick={() => close()}>
            Close
          </Button>
        </Space>
      </Flex>
    ),
  });

  const getTextColumnSearchProps = (dataIndex, name) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <Flex
        gap="small"
        vertical
        style={{ padding: 8 }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${name}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
          >
            Search
          </Button>
          <Button type="link" onClick={() => close()} size="small">
            Close
          </Button>
        </Space>
      </Flex>
    ),
  });

  const getTableData = async (obj) => {
    let url = "/api/v1/admin/customers";
    if (userConfigData && userConfigData.userType === "RESELLER") {
      url = "/api/v1/reseller/customers";
    }
    const response = await postApi(url, obj);
    if (response && response.status === 200) {
      setCustomerTableData(response.data.results);
      setCustomerTableParams({
        ...customerTableParams,
        pagination: {
          ...obj.pagination,
          total: response.data.total,
        },
        search: obj.search,
        order: obj.order,
      });
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    const filteredData = customerTableData.filter((record) =>
      record.wabaTokens.some((token) =>
        token.phoneNumberId.includes(selectedKeys[0])
      )
    );

    setCustomerTableData(filteredData);
    setCustomerTableParams({
      ...customerTableParams,
      search: [{ fieldName: dataIndex, value: selectedKeys[0] }],
    });
  };
  const onCustomerModalHandler = (Id) => {
    setIsCustomerModalOpen(true);
    setSelectedCustomerId(Id);
  };
  const handleCancel = () => {
    setIsCustomerModalOpen(false);
    setSelectedCustomerId("");
    apiKeyModalForm.resetFields();
    setSelectedAction("");
  };
  const onFinish = (values) => {
    const { action, comment } = values;
    if (!action) {
      notification.error({
        message: "Error",
        description: "Please select action",
      });
    }
    let payload = {
      id: selectedCustomerId,
      action,
      comment,
    };
    if (action === "roleTime") {
      payload.rolesToAdd = [
        {
          role: values.role,
          validity: values.validity.$d.getTime(),
        },
      ];
    }
    if (action === "assignReseller") {
      payload.resellerId = values.resellerId;
    }
    updateCustomerHandler(payload);
  };
  const onFinishFailed = (values) => {};
  const updateCustomerHandler = async (payload) => {
    const response = await postApi("/api/v1/admin/customer/update", payload);
    if (response.status === 200) {
      notification.success({
        message: "Success",
        description: response.data.message,
      });
      const { pagination } = customerTableParams;
      getTableData({
        ...customerTableParams,
        pagination: {
          current: pagination.current,
          pageSize: pagination.pageSize,
        },
      });
    } else {
      notification.error({
        message: "Error",
        description: response.data.message,
      });
    }
    handleCancel();
  };

  const renderRoleValidityList = (record) => (
    <List
      size="small"
      dataSource={record.roleValidity}
      renderItem={(role) => (
        <List.Item>
          <Typography.Text>
            {role.role} - {getDateFromTimeStamp(role.validity)}
          </Typography.Text>
        </List.Item>
      )}
    />
  );

  const columns = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      ...getTextColumnSearchProps("username", "Username"),
      sorter: (a, b) => a.username - b.username,
    },
    {
      title: "Reseller Username",
      dataIndex: ["reseller", "username"],
      key: "reseller.username",
      ...getTextColumnSearchProps(
        ["reseller", "username"],
        "Reseller Username"
      ),
      sorter: (a, b) =>
        a[("reseller", "username")] - b[("reseller", "username")],
    },
    {
      title: "Role Validity",
      key: "roleValidity",
      render: (text, record) => ({ record } && renderRoleValidityList(record)),
    },
    {
      title: "Creation Time",
      dataIndex: "creationTime",
      key: "creationTime",
      ...getDateColumnSearchProps("creationTime", "Creation Time"),
      render: (_, record) => getDateFromTimeStamp(record.creationTime),
      sorter: (a, b) => a.creationTime - b.creationTime,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ...getTextColumnSearchProps("email", "Email"),
      sorter: (a, b) => a.email - b.email,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      ...getTextColumnSearchProps("phoneNumber", "Phone Number"),
      sorter: (a, b) => a.phoneNumber - b.phoneNumber,
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      render: (_, record) => (
        <Flex gap="small">
          <Button onClick={() => onCustomerModalHandler(record.id)}>
            Update
          </Button>
          <Button onClick={() => onNavigateHandler(record.id, "Audit")}>
            Audit
          </Button>
        </Flex>
      ),
    },
  ];
  const resellerColumns = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      ...getTextColumnSearchProps("username", "Username"),
      sorter: (a, b) => a.username - b.username,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ...getTextColumnSearchProps("email", "Email"),
      sorter: (a, b) => a.email - b.email,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      ...getTextColumnSearchProps("phoneNumber", "Phone Number"),
      sorter: (a, b) => a.phoneNumber - b.phoneNumber,
    },
    {
      title: "Role Validity",
      key: "roleValidity",
      render: (text, record) => ({ record } && renderRoleValidityList(record)),
    },
    {
      title: "Creation Time",
      dataIndex: "creationTime",
      key: "creationTime",
      ...getDateColumnSearchProps("creationTime", "Creation Time"),
      render: (_, record) => getDateFromTimeStamp(record.creationTime),
      sorter: (a, b) => a.creationTime - b.creationTime,
    },
  ];
  const resetFilterHandler = () => {
    const updataedParams = { ...customerTableParams };
    const paginationObj = {
      current: updataedParams.pagination?.current ?? 1,
      pageSize: updataedParams.pagination?.pageSize ?? DEFAULT_PAGE_SIZE,
    };
    updataedParams.pagination = paginationObj;
    updataedParams.search = [];
    setCustomerTableParams(updataedParams);
    getTableData(updataedParams);
  };
  const phoneSearchHandler = () => {
    if (searchPhoneNo) {
      const updataedParams = { ...customerTableParams };
      const paginationObj = {
        current: 1,
        pageSize: updataedParams.pagination?.pageSize ?? DEFAULT_PAGE_SIZE,
      };
      updataedParams.pagination = paginationObj;

      const obj = {
        fieldName: "wabaTokens.displayPhoneNumber",
        value: [searchPhoneNo],
      };
      setSearchPhoneNo("");
      const findIndex = updataedParams.search.findIndex((rec) => {
        return rec.fieldName === obj.fieldName;
      });
      if (findIndex !== -1) {
        updataedParams.search[findIndex].value = [searchPhoneNo];
      } else {
        updataedParams.search.push(obj);
      }
      getTableData(updataedParams);
    }
  };

  const handleActionChange = (value) => {
    if (value === "assignReseller") {
      getResellerList();
    }
    setSelectedAction(value);
  };
  const getResellerList = async () => {
    const response = await getApi("/api/v1/admin/resellers");
    if (response && response.status === 200) {
      setResellerList(response.data);
    } else {
      notification.error({
        message: "Error",
        description: response.data.message,
      });
    }
  };
  return (
    <Flex vertical gap={"large"}>
      <Flex justify="space-between">
        <Typography.Title level={4} style={{ margin: 0 }}>
          Customer List
        </Typography.Title>
        <div style={{ display: "flex" }}>
          <Input
            value={searchPhoneNo}
            type="text"
            placeholder="Search Phone No"
            onChange={(e) => {
              setSearchPhoneNo(e.target.value);
            }}
          />
          <Button
            style={{ marginLeft: 10 }}
            type="primary"
            onClick={phoneSearchHandler}
          >
            Search
          </Button>
          <Button
            style={{ marginLeft: 10 }}
            type="primary"
            onClick={resetFilterHandler}
          >
            Reset Filters
          </Button>
        </div>
      </Flex>
      <div>
        <Table
          rowKey={(record) => record.id}
          columns={
            userConfigData?.userType === "ADMIN" ? columns : resellerColumns
          }
          dataSource={customerTableData}
          onChange={handleTableChange}
          pagination={{
            ...customerTableParams.pagination,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]} - ${range[1]} of ${total} records`,
          }}
          expandable={{
            expandedRowRender,
            rowExpandable: (record) => record.wabaTokens.length > 0,
            expandedRowKeys: expandedRows,
            onExpand: (expanded, record) => {
              if (expanded) {
                setExpandedRows((prev) => [...prev, record.id]);
              } else {
                setExpandedRows((prev) =>
                  prev.filter((rec) => {
                    return rec !== record.id;
                  })
                );
              }
            },
          }}
        ></Table>
      </div>
      <Modal
        title="Edit Customer"
        open={isCustomerModalOpen}
        onOk={() => apiKeyModalForm.submit()}
        onCancel={handleCancel}
        width={700}
      >
        <Form
          form={apiKeyModalForm}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          labelCol={{
            span: 5,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            minWidth: "700px",
            width: "100%",
          }}
        >
          <Form.Item name="action" label="Action">
            <Select onChange={handleActionChange}>
              <Select.Option key="roleTime" value="roleTime">
                Set role and validity
              </Select.Option>
              <Select.Option key="deActivate" value="deActivate">
                De-activate
              </Select.Option>
              <Select.Option key="assignReseller" value="assignReseller">
                Assign reseller
              </Select.Option>
              <Select.Option key="removeReseller" value="removeReseller">
                Remove assigned reseller
              </Select.Option>
              <Select.Option key="makeReseller" value="makeReseller">
                Make reseller
              </Select.Option>
            </Select>
          </Form.Item>
          {selectedAction === "roleTime" && (
            <>
              <Form.Item name="role" label="Role">
                <Select>
                  <Select.Option key="USER" value="USER">
                    User
                  </Select.Option>
                </Select>
              </Form.Item>

              <Form.Item name="validity" label="Validity">
                <DatePicker onChange={() => {}} />
              </Form.Item>
            </>
          )}
          {selectedAction === "assignReseller" && (
            <Form.Item label="Reseller" name="resellerId">
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {resellerList.map((rec) => (
                  <Select.Option key={rec.id} value={rec.id}>
                    {rec.username}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item name="comment" label="Comment">
            <TextArea rows={3} />
          </Form.Item>
        </Form>
      </Modal>
    </Flex>
  );
};

export default Customer;

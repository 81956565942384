export const getErrorDescription = (errorData) => {
  let returnArray = [
    { label: "Error detail", children: errorData?.detail },
    { label: "Error title", children: errorData?.title },
    { label: "Error code", children: errorData?.error.code },
    {
      label: "Error subcode",
      children: errorData?.error?.error_subcode,
    },
    {
      label: "Error message",
      children: errorData?.error?.message,
    },
    {
      label: "Error type",
      children: errorData?.error?.type,
    },
    {
      label: "User message",
      children: errorData?.error?.error_user_msg,
    },
    {
      label: "User title",
      children: errorData?.error?.error_user_title,
    },
    {
      label: "fbtraceId",
      children: errorData?.error?.fbtrace_id,
    },
  ];
  return returnArray;
};

// TemplateHeader.js
import { Button, Form, Input, Select } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setSelectedTab } from "../../Store/action";
import UploadFile from "../SendMessage/CommonComponents/UploadComponrnt/UploadFile";
import { checkVariableNumber, validateParentheses } from "./TemplateUtil";

const TemplateHeader = ({
  allowdDropDownValue,
  templateHeaderData,
  setTemplateHeaderData,
  id,
  label = "Template Header",
}) => {
  const [parenthesesCorrect, setParenthesesCorrect] = useState(true);
  const [headerTypeJSON, setHeaderTypeJSON] = useState([]);
  const [headerVariableCount, setHeaderVariableCount] = useState(0);
  const [uploadFileText, setUploadFileText] = useState("");
  const dispatch = useDispatch();

  const onHeaderChangeHandler = (fieldType, selectedValue) => {
    let updatedValue = selectedValue;
    if (fieldType === "type") {
      if (selectedValue === "VIDEO") {
        setUploadFileText("Upload Video....");
      } else if (selectedValue === "IMAGE") {
        setUploadFileText("Upload Image....");
      } else if (selectedValue === "DOCUMENT") {
        setUploadFileText("Upload Document....");
      } else {
        setUploadFileText("");
      }
      dispatch(setSelectedTab(selectedValue));
    }
    if (fieldType === "name") {
      const variableMatches = selectedValue.match(/{{\d+}}/g) || [];
      const variableCount = variableMatches.length;
      if (variableCount > 1) {
        const firstVariableIndex = selectedValue.indexOf(variableMatches[0]);
        updatedValue =
          selectedValue.slice(
            0,
            firstVariableIndex + variableMatches[0].length
          ) +
          selectedValue
            .slice(firstVariableIndex + variableMatches[0].length)
            .replace(/{{\d+}}/g, "");
      }
      const result = validateParentheses(updatedValue);

      setParenthesesCorrect(result);
    }

    const updatedHeaderData = {
      ...templateHeaderData,
      [fieldType]: updatedValue,
    };
    setTemplateHeaderData(updatedHeaderData);
  };
  useEffect(() => {
    const updatedHeaderJSON = allowdDropDownValue.map((rec) => {
      return {
        value: rec.toUpperCase(),
        label: rec,
        id: rec.toUpperCase(),
      };
    });
    setHeaderTypeJSON(updatedHeaderJSON);
  }, [allowdDropDownValue]);

  const addVariableHandler = () => {
    setHeaderVariableCount(1);
    const countText = `{{${1}}}`;
    const updatedHeaderData = {
      ...templateHeaderData,
      name: templateHeaderData.name
        ? templateHeaderData.name + " " + countText
        : countText,
    };
    setTemplateHeaderData(updatedHeaderData);
  };

  const handleHeaderFileChange = (info, isFileUpload, fileUrl) => {
    if (isFileUpload) {
      let newFileList = [{ ...info }];
      newFileList = newFileList.slice(-1);
      newFileList.forEach((file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => {
          file.base64 = reader.result;
          const updatedHeaderData = {
            ...templateHeaderData,
            fileList: newFileList,
          };
          setTemplateHeaderData(updatedHeaderData);
        };
      });
    } else {
      const updatedHeaderData = {
        ...templateHeaderData,
        fileUrl: info,
      };
      setTemplateHeaderData(updatedHeaderData);
    }
  };
  useEffect(() => {
    if (
      (templateHeaderData?.name?.match(/{{\d+}}/g) || []).length === 0 ||
      (templateHeaderData?.name?.match(/{{\d+}}/g) || []).length === 1
    ) {
      let myString = templateHeaderData?.name;
      let variableCount = (myString?.match(/{{\d+}}/g) || []).length;
      setHeaderVariableCount(variableCount);
    }
  }, [templateHeaderData?.name, headerVariableCount]);
  const handleKeyUp = (e) => {
    if (parenthesesCorrect) {
      const updatedTemplateJSON = { ...templateHeaderData };
      let arr = updatedTemplateJSON?.name?.split("}}") || [];
      const updatedStr = checkVariableNumber(arr);
      updatedTemplateJSON.name = updatedStr;
      setTemplateHeaderData(updatedTemplateJSON);
    }
  };

  return (
    <>
      <Form.Item name={`Header${id}`} label={label}>
        <Select
          value={templateHeaderData.type}
          onChange={(e) => onHeaderChangeHandler("type", e)}
        >
          {headerTypeJSON.map((rec) => (
            <Select.Option key={rec.id} value={rec.value}>
              {rec.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {templateHeaderData && templateHeaderData.type === "TEXT" && (
        <Form.Item name={`headerText${id}`} label="Template Header Type">
          <>
            <Input
              placeholder="Enter header text"
              className={parenthesesCorrect ? "" : "error-textarea"}
              value={templateHeaderData.name}
              onChange={(e) => onHeaderChangeHandler("name", e.target.value)}
              onKeyUp={(e) => handleKeyUp(e)}
            />
            <Button
              style={{ marginTop: 20 }}
              onClick={addVariableHandler}
              disabled={headerVariableCount === 1}
            >
              Add Variable
            </Button>
            {headerVariableCount === 1 && (
              <Form.Item name={`header_text${id}`} label="{{1}}">
                <Input
                  placeholder="Enter Content for {{1}}"
                  value={templateHeaderData.headerCountValue}
                  onChange={(e) =>
                    onHeaderChangeHandler("headerCount", e.target.value)
                  }
                />
              </Form.Item>
            )}
          </>
        </Form.Item>
      )}
      {templateHeaderData &&
        (templateHeaderData.type === "IMAGE" ||
          templateHeaderData.type === "VIDEO" ||
          templateHeaderData.type === "DOCUMENT") && (
          <Form.Item name={`headermedia${id}`} label="Template Header Type">
            <UploadFile
              uploadFileName={uploadFileText}
              uploadFileChangeHandler={handleHeaderFileChange}
              resetValue={false}
              tabName={templateHeaderData.type}
            />
          </Form.Item>
        )}
    </>
  );
};

export default TemplateHeader;

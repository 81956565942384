import { EyeOutlined, LinkOutlined, SyncOutlined } from "@ant-design/icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Col,
  Descriptions,
  Dropdown,
  Flex,
  Form,
  Input,
  List,
  Modal,
  Popover,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Typography,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setConnectedAccountData } from "../../Store/action";
import AddLibrary from "../../Util/Add3rdPartyScript";
import { validateChatwootPassword } from "../../Util/PasswordValidation";
import { MAIN_HOST, MAIN_HOST_URL } from "../../Util/env";
import {
  AddNumberFormJSON,
  connectToCRMJSON,
  getColorCode,
} from "../../constants/Constant";
import { fbLoginInit } from "../../constants/metaConstants";
import CustomForm from "../SendMessage/CommonComponents/Form/CustomForm";
import { getApi, postApi } from "../../constants/ApiConstant";
import { getStorageObj } from "../../constants/StorageUtil";

const ConnectedAccount = () => {
  //Hide Login With Facebook Button
  const [isShowFaceBookLogin, setIsShowFaceBookLogin] = useState(false);
  const [data, setData] = useState([]);
  const [modalOpen, setModalOpen] = useState("");
  const [isConnectedAccountModalOpen, setIsConnectedAccountModalOpen] =
    useState(false);
  const [connectCRMDataJSON, setConnectCRMDataJSON] = useState({});
  // const [showTokenMap, setShowTokenMap] = useState({});
  const [form] = Form.useForm();
  const [connectedAccountModalForm] = Form.useForm();
  const [phoneStatusData, setPhoneStatusData] = useState([]);
  const [phoneStatus, setPhoneStatus] = useState([]);
  const token = getStorageObj().token;
  const dispatch = useDispatch();
  const [bulkList, setBulkList] = useState({});
  const [bulkPhoneNumberId, setBulkPhoneNumberId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [bulkDropDownVariableList, setBulkDropDownVariableList] = useState([]);
  const connectedAccountData = useSelector(
    (state) => state.connectedAccountTableData
  );

  useEffect(() => {
    setData(connectedAccountData);
  }, [connectedAccountData]);

  useEffect(() => {
    AddLibrary("https://connect.facebook.net/en_US/sdk.js").then(() => {
      window.fbAsyncInit = function () {
        window.FB.init(fbLoginInit);
      };
    });
    if (window.location.hostname === MAIN_HOST) {
      setIsShowFaceBookLogin(true);
    } else {
      setIsShowFaceBookLogin(false);
    }
  }, []);
  /* 	const toggleShowToken = (phoneNumberId) => {
		setShowTokenMap((prevMap) => ({
			...prevMap,
			[phoneNumberId]: !prevMap[phoneNumberId],
		}));
	};
 */ const showModal = (modalName) => {
    form.resetFields();
    if (modalName === "CRM" || modalName === "BulkTemplate") {
      setModalOpen(modalName);
    } else if (modalName === "ConnectedAccount") {
      setIsConnectedAccountModalOpen(true);
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);
  const handleMessage = (event) => {
    if (
      event.origin === MAIN_HOST_URL &&
      event.data.type === "wlSignupFlowRes"
    ) {
      notification.success({
        message: "Number added",
        description: "Number added successfully.",
      });
      getConnectedAccountList();
    }
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        setModalOpen("");
        setIsConnectedAccountModalOpen(false);
        onWchatterAddAccountFinish(values);
      })
      .catch((errorInfo) => {});
  };

  const handleCancel = () => {
    form.resetFields();
    connectedAccountModalForm.resetFields();
    setModalOpen("");
    setIsConnectedAccountModalOpen(false);
  };

  const connectCRMHandler = (data) => {
    setConnectCRMDataJSON(data);
    showModal("CRM");
  };
  const onWchatterAddAccountFinish = async (values) => {
    let postData = {
      ...values,
      phoneNumberId: connectCRMDataJSON.phoneNumberId,
    };
    const response = await postApi("/api/v1/wchatter/createAccount", postData);
    if (response) {
      notification.success({
        message: "Success",
        description: "User Account Created Success ..",
      });
      form.resetFields();
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const onConnectedAccountFinish = async (values) => {
    const response = await postApi("/api/v1/onboard/addPhone", values);
    if (response) {
      connectedAccountModalForm.resetFields();
      getConnectedAccountList();
      handleCancel();
      notification.success({
        message: "Success",
        description: "Number added successfully",
      });
      form.resetFields();
    }
  };
  const syncTemplatesFromMeta = async (phoneNumberId) => {
    const response = await getApi("/api/v1/whatsapp/syncTemplatesFromMeta", {
      headers: {
        phoneNumberId,
      },
    });
    if (response) {
      notification.success({
        message: "Success",
        description: "Template Sync Success..",
      });
    }
  };
  const syncFlowsFromMeta = async (phoneNumberId) => {
    const response = await getApi("/api/v1/whatsapp/flow/sync", {
      headers: {
        phoneNumberId,
      },
    });
    if (response) {
      notification.success({
        message: "Success",
        description: "Flows Sync Success..",
      });
    }
  };
  const getPhoneStatus = async (phoneNumberId) => {
    const response = await getApi(
      `/api/v1/onboard/phone/${phoneNumberId}/status`,
      {
        headers: {
          phoneNumberId,
        },
      }
    );
    if (response) {
      const updatedData = response.data.healthStatus.entities.filter(
        (rec) => rec.entityType !== "APP"
      );
      setPhoneStatusData(updatedData);
      setPhoneStatus(response.data.healthStatus.canSendMessage);
      setModalOpen("phoneStatus");
    }
  };
  const phoneStatusColumns = [
    {
      title: "Entity Type",
      dataIndex: "entityType",
      key: "entityType",
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Status",
      dataIndex: "canSendMessage",
      key: "canSendMessage",
      render: (canSendMessage) => {
        const color = getColorCode(canSendMessage);
        return (
          <Tag color={color} key={canSendMessage}>
            {canSendMessage.toUpperCase()}
          </Tag>
        );
      },
    },
  ];
  const tableColumns = [
    {
      title: "Display Number",
      dataIndex: "displayPhoneNumber",
      key: "displayPhoneNumber",
    },
    {
      title: "Phone Number ID",
      dataIndex: "phoneNumberId",
      key: "phoneNumberId",
    },
    {
      title: "Whatsapp Business Id",
      dataIndex: "whatsappBusinessAccountId",
      key: "whatsappBusinessAccountId",
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      render: (_, record) => (
        <Flex gap="small" vertical>
          <Dropdown
            arrow
            menu={{
              items: [
                {
                  key: "MANAGE_TEMPLATES",
                  label: (
                    <Button
                      target="_blank"
                      href={`https://business.facebook.com/wa/manage/message-templates/?business_id=${record.businessId}&waba_id=${record.whatsappBusinessAccountId}`}
                      icon={<SyncOutlined />}
                    >
                      Manage Templates
                    </Button>
                  ),
                },
                {
                  key: "SYNC_TEMPLATE",
                  label: (
                    <Button
                      icon={<SyncOutlined />}
                      onClick={() =>
                        syncTemplatesFromMeta(record.phoneNumberId)
                      }
                    >
                      Sync Template
                    </Button>
                  ),
                },
                {
                  key: "BULK_TEMPLATE",
                  label: (
                    <Button
                      icon={<SyncOutlined />}
                      onClick={() =>
                        createBulkTemplateHandler(record.phoneNumberId)
                      }
                    >
                      Create Bulk Template
                    </Button>
                  ),
                },
              ],
            }}
          >
            <Button>Template</Button>
          </Dropdown>
          <Dropdown
            arrow
            menu={{
              items: [
                {
                  key: "STATUS",
                  label: (
                    <Button
                      onClick={() => getPhoneStatus(record.phoneNumberId)}
                      icon={<SyncOutlined />}
                    >
                      Status
                    </Button>
                  ),
                },
                {
                  key: "PHONE_PROFILE",
                  label: (
                    <Button
                      target="_blank"
                      href={`https://business.facebook.com/wa/manage/phone-numbers/?business_id=${
                        record.businessId
                      }&waba_id=${
                        record.whatsappBusinessAccountId
                      }&phone_number=${record.displayPhoneNumber.replace(
                        "+",
                        ""
                      )}&childRoute=PHONE_PROFILE%2FPROFILE`}
                      icon={<SyncOutlined />}
                    >
                      Phone Profile
                    </Button>
                  ),
                },
                {
                  key: "PAYMENT_SETUP",
                  label: (
                    <Button
                      target="_blank"
                      href={`https://business.facebook.com/billing_hub/payment_methods?asset_id=${record.whatsappBusinessAccountId}&business_id=${record.businessId}`}
                      icon={<SyncOutlined />}
                    >
                      Payment Setup
                    </Button>
                  ),
                },
                {
                  key: "BUSINESS_VERIFICATION",
                  label: (
                    <Button
                      icon={<SyncOutlined />}
                      target="_blank"
                      href={`https://business.facebook.com/settings/security?business_id=${record.businessId}`}
                    >
                      Business Verification
                    </Button>
                  ),
                },
              ],
            }}
            placement="bottomLeft"
          >
            <Button>Activation</Button>
          </Dropdown>
          <Dropdown
            arrow
            menu={{
              items: [
                {
                  key: "Create_Flow",
                  label: (
                    <Button
                      target="_blank"
                      href={`https://business.facebook.com/wa/manage/flows/?business_id=${record.businessId}&waba_id=${record.whatsappBusinessAccountId}`}
                      icon={<SyncOutlined />}
                    >
                      Create Flow
                    </Button>
                  ),
                },
                {
                  key: "SYNC_FLOW",
                  label: (
                    <Button
                      icon={<SyncOutlined />}
                      onClick={() => syncFlowsFromMeta(record.phoneNumberId)}
                    >
                      Sync Flow
                    </Button>
                  ),
                },
              ],
            }}
          >
            <Button>Flow </Button>
          </Dropdown>
          {record.siteUrl && <ViewCrmDetailForNumber record={record} />}
          {!record.siteUrl && (
            <Button
              icon={<LinkOutlined />}
              onClick={() => connectCRMHandler(record)}
            >
              Connect CRM
            </Button>
          )}
        </Flex>
      ),
    },
  ];
  const getConnectedAccountList = async () => {
    const response = await getApi("/api/v1/onboard/getPhonesWithTeamInbox");
    if (response) {
      setData([...response.data]);
      const updatedData = [];
      for (let i = 0; i < response.data.length; i++) {
        updatedData.push({
          label: response.data[i].displayPhoneNumber,
          value: response.data[i].phoneNumberId,
        });
      }
      dispatch(setConnectedAccountData(updatedData));
    }
  };
  useEffect(() => {
    getConnectedAccountList();
  }, []);

  const launchWhatsAppSignup = async () => {
    window.FB.login(
      function (response) {
        console.log("In FB login response", response);
        if (response.authResponse) {
          postApi("/api/v1/onboard/embeddedSignup", response).then(
            (response) => {
              if (response && response.status === 200) {
                notification.success({
                  message: "Number added",
                  description: "Number added successfully.",
                });
                getConnectedAccountList();
              } else {
              }
            }
          );
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      {
        config_id: "400722442435718",
        response_type: "code",
        override_default_response_type: true,
        extras: {
          setup: {},
        },
      }
    );
  };
  const getErrorDescription = (record) => {
    let returnArray = [];
    if (record.errors && record.errors.length > 0) {
      for (let i = 0; i < record.errors.length; i++) {
        returnArray.push({
          label: "Error Code",
          children: record?.errors[i]?.errorCode,
        });
        returnArray.push({
          label: "Error Message",
          children: record.errors[i].errorDescription,
        });
        returnArray.push({
          label: "Possible Solution",
          children: record.errors[i].possibleSolution,
        });
      }
    }
    if (record.additionalInfo && record.additionalInfo.length > 0) {
      for (let i = 0; i < record.additionalInfo.length; i++) {
        returnArray.push({
          label: "Additional Info",
          children: record?.additionalInfo[i],
        });
      }
    }
    return returnArray;
  };
  const bindPhoneStatus = (phoneStatus) => {
    const color = getColorCode(phoneStatus);
    return (
      <Tag color={color} key={phoneStatus}>
        {phoneStatus}
      </Tag>
    );
  };
  const createBulkTemplateHandler = async (phoneNumberId) => {
    setBulkPhoneNumberId(phoneNumberId);
    const response = await getApi(
      "/api/v1/messageTemplate/preDefined/bulk/list",
      {
        headers: {
          phoneNumberId,
        },
      }
    );
    if (response && response.status === 200) {
      const responseObj = {};
      responseObj.listData = response.data;
      responseObj.bulkDropDownData = response.data.map((item) => ({
        label: item.group,
        value: item.group,
      }));

      setBulkList(responseObj);
      showModal("BulkTemplate");
    }
  };

  const createBulkTemplate = async (values) => {
    setIsLoading(true);
    const payload = {
      group: values.group,
    };
    for (let key in values) {
      if (values.hasOwnProperty(key)) {
        if (key !== "group") {
          payload.customVariables = {
            [key]: values[key],
          };
        }
      }
    }
    const response = await postApi(
      "/api/v1/messageTemplate/preDefined/createBulk",
      payload,
      {
        headers: {
          phoneNumberId: bulkPhoneNumberId,
        },
      }
    );
    if (response && response.status === 200) {
      setModalOpen("");
      setIsLoading(false);
      notification.success({
        message: "Success",
        description: response.data.message,
      });
    }
  };
  const handleBulkDropDownChange = (values) => {
    setBulkDropDownVariableList([]);
    const findIndex = bulkList.listData.findIndex((rec) => {
      return rec.group === values;
    });
    if (findIndex !== -1) {
      setBulkDropDownVariableList(bulkList.listData[findIndex].customVariables);
    }
  };
  return (
    <Flex vertical gap={"large"}>
      <Flex justify="space-between">
        <Typography.Title level={4} style={{ margin: 0 }}>
          Connected Whatsapp Numbers
        </Typography.Title>
        <div>
          {!isShowFaceBookLogin && (
            <iframe
              src={`${MAIN_HOST_URL}/whiteLabel/embeddedSignup?token=${token}`}
              width="190"
              height="40"
              style={{
                position: "relative",
                overflow: "hidden",
                border: "none",
                top: "15px",
              }}
              title="Embedded flow"
            />
          )}
          {isShowFaceBookLogin && (
            <Button
              type="primary"
              style={{
                height: "40px",
              }}
              onClick={launchWhatsAppSignup}
            >
              <Space>
                <FontAwesomeIcon icon={faFacebook} />
                Login with Facebook
              </Space>
            </Button>
          )}

          <Button
            type="secondary"
            onClick={showModal.bind(this, "ConnectedAccount")}
            style={{ display: "none" }}
          >
            Connect New Number
          </Button>
        </div>
      </Flex>
      <Table
        bordered
        pagination={false}
        columns={tableColumns}
        dataSource={data}
      />
      <Modal
        title="Create CRM Account"
        open={modalOpen === "CRM" ? true : false}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 14 }}
          onFinish={onWchatterAddAccountFinish}
          onFinishFailed={onFinishFailed}
        >
          {connectToCRMJSON.map((rec) => {
            return (
              <CustomForm data={rec} validateRule={validateChatwootPassword} />
            );
          })}
        </Form>
      </Modal>
      <Modal
        title="Connect Whatsapp Account"
        open={isConnectedAccountModalOpen}
        onOk={() => connectedAccountModalForm.submit()}
        onCancel={handleCancel}
        style={{ maxWidth: "700px" }}
        width={"auto"}
      >
        <Form
          form={connectedAccountModalForm}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 15 }}
          onFinish={onConnectedAccountFinish}
          onFinishFailed={onFinishFailed}
        >
          {AddNumberFormJSON.map((rec) => {
            return <CustomForm data={rec} />;
          })}
        </Form>
      </Modal>
      <Modal
        open={modalOpen === "phoneStatus" ? true : false}
        onOk={handleCancel}
        onCancel={handleCancel}
      >
        <h4>Overall Phone Status: {bindPhoneStatus(phoneStatus)}</h4>
        <Table
          bordered
          pagination={false}
          columns={phoneStatusColumns}
          dataSource={phoneStatusData}
          rowKey={(record) => record.id}
          expandable={{
            expandedRowRender: (record) => (
              <Row style={{ alignItems: "center" }}>
                <Col span={22} offset={1}>
                  <Card>
                    <Descriptions
                      size="small"
                      column={1}
                      bordered
                      items={getErrorDescription(record)}
                    ></Descriptions>
                  </Card>
                </Col>
              </Row>
            ),
            rowExpandable: (record) => record.canSendMessage !== "AVAILABLE",
          }}
        />
      </Modal>
      <Modal
        title="Create Bulk Templates"
        open={modalOpen === "BulkTemplate" ? true : false}
        onOk={form.submit}
        okButtonProps={{ loading: isLoading }}
        okText="Create"
        onCancel={handleCancel}
      >
        <Form
          form={form}
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 14 }}
          onFinish={createBulkTemplate}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item label="Select Group" name="group">
            <Select
              showSearch
              onChange={handleBulkDropDownChange}
              options={bulkList.bulkDropDownData}
            />
          </Form.Item>
          {bulkDropDownVariableList.length > 0 && (
            <div>
              {bulkDropDownVariableList.map((rec) => {
                return (
                  <Form.Item label={rec} name={rec} required>
                    <Input placeholder={`Enter ${rec}`} />
                  </Form.Item>
                );
              })}
            </div>
          )}
        </Form>
      </Modal>
    </Flex>
  );
};

const ViewCrmDetailForNumber = (props) => {
  const { record } = props;
  const items = [
    {
      key: "siteUrl",
      label: "Url",
      children: (
        <a href={record.siteUrl} target="_blank" rel="noreferrer">
          {record.siteUrl}
        </a>
      ),
    },
    {
      key: "users",
      label: "User(s)",
      children: (
        <List
          dataSource={record.users}
          renderItem={(item) => <List.Item>{item}</List.Item>}
        ></List>
      ),
    },
  ];
  return (
    <Popover
      trigger="click"
      title="CRM detail"
      content={<Descriptions bordered items={items} />}
    >
      <Button icon={<EyeOutlined />}>View CRM</Button>
    </Popover>
  );
};

export default ConnectedAccount;

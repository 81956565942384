import { Button, Dropdown, Flex, Form, Typography } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
const ListTemplate = () => {
  const navigate = useNavigate();
  const onLoadPreDefinedTemplate = () => {
    navigate("/preDefineTemplate");
  };
  const onCreateTemplateHandler = () => {
    navigate("/ListTemplate/CreateTemplate");
  };

  return (
    <Flex vertical gap={"large"}>
      <Flex justify="space-between">
        <Typography.Title level={4} style={{ margin: 0 }}>
          List Templates
        </Typography.Title>
        <Flex gap="small" vertical>
          <Dropdown
            arrow
            menu={{
              items: [
                {
                  key: "Create Directly",
                  label: (
                    <Button
                      target="_blank"
                      icon={<SyncOutlined />}
                      onClick={onCreateTemplateHandler}
                    >
                      Create Directly
                    </Button>
                  ),
                },
                // {
                //   key: "Using Library",
                //   label: (
                //     <Button
                //       icon={<SyncOutlined />}
                //       onClick={() => onLoadPreDefinedTemplate()}
                //     >
                //       Using Library
                //     </Button>
                //   ),
                // },
              ],
            }}
          >
            <Button type="primary"> Create Template</Button>
          </Dropdown>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ListTemplate;

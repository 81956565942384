import {
  ApiOutlined,
  DeploymentUnitOutlined,
  FileDoneOutlined,
  InfoOutlined,
  LockOutlined,
  MailOutlined,
  MessageOutlined,
  PhoneOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { DEFAULT_PAGE_SIZE } from "./ReportConstant";

export const sideBarList = [
  {
    key: "ConnectedAccount",
    icon: <UploadOutlined />,
    label: "Connected accounts",
    linkTo: "ConnectedAccount",
  },
  {
    key: "Message",
    icon: <MessageOutlined />,
    label: "Message",
    linkTo: "",
    submenu: [
      {
        key: "SendMessages",
        icon: <UploadOutlined />,
        label: "Send Single Message",
        linkTo: "SendMessages",
      },
      {
        key: "SendBulkMessage",
        icon: <VideoCameraOutlined />,
        label: "Send Bulk Messages",
        linkTo: "SendBulkMessage",
        isNewUpdate: true,
      },
    ],
  },
  {
    key: "Reporting",
    icon: <FileDoneOutlined />,
    label: "Report",
    linkTo: "Reporting",
  },
  {
    key: "Contact",
    icon: <ApiOutlined />,
    label: "Contact",
    linkTo: "",
    submenu: [
      {
        key: "Contacts",
        icon: <UploadOutlined />,
        label: "Contacts",
        linkTo: "contacts",
      },
      {
        key: "Contact Label",
        icon: <UploadOutlined />,
        label: "Contact Label",
        linkTo: "contactLabel",
      },
      {
        key: "Contact Attributes",
        icon: <UploadOutlined />,
        label: "Contact Attributes",
        linkTo: "contactAttribute",
      },
      {
        key: "Block Contact",
        icon: <UploadOutlined />,
        label: "Block Contact",
        linkTo: "blockContact",
      },
    ],
  },
  {
    key: "CannedMessage",
    icon: <DeploymentUnitOutlined />,
    label: "Canned message",
    linkTo: "CannedMessage",
  },
  {
    key: "ChatBot",
    icon: <MessageOutlined />,
    label: "Chatbot",
    linkTo: "",
    submenu: [
      {
        key: "BasicChatBot",
        icon: <UploadOutlined />,
        label: "Basic Chatbot",
        linkTo: "basicChatBot",
      },
    ],
  },
  {
    key: "Template",
    icon: <ApiOutlined />,
    label: "Template",
    linkTo: "ListTemplate",
  },
  {
    key: "Flows",
    icon: <ApiOutlined />,
    label: "Flows ",
    linkTo: "Flows",
  },
  {
    key: "ListApikey",
    icon: <ApiOutlined />,
    label: "Api keys",
    linkTo: "ListApikey",
  },
  {
    key: "Webhook",
    icon: <DeploymentUnitOutlined />,
    label: "Webhook",
    linkTo: "Webhook",
  },
  // {
  // 	key: 'Support',
  // 	icon: <SettingOutlined />,
  // 	label: 'Support',
  // 	linkTo: 'support',
  // },
];

export const connectToCRMJSON = [
  {
    label: "Company Name",
    name: "companyName",
    isRequired: true,
    requiredMessage: "Please input your Company Name!",
    prefix: <InfoOutlined className="site-form-item-icon" />,
    placeholder: "Company Name",
    inputType: "Normal",
  },
  {
    label: "Name",
    name: "name",
    isRequired: true,
    requiredMessage: "Please input your Name!",
    prefix: <UserOutlined className="site-form-item-icon" />,
    placeholder: "Name",
    inputType: "Normal",
  },
  {
    label: "User Email",
    name: "userEmail",
    isRequired: true,
    requiredMessage: "Please input your User Email!",
    prefix: <MailOutlined className="site-form-item-icon" />,
    placeholder: "User Email",
    inputType: "Normal",
  },
  {
    label: "User Password",
    name: "userPassword",
    isRequired: true,
    requiredMessage: "Please input your User Password!",
    prefix: <LockOutlined className="site-form-item-icon" />,
    placeholder: "User Password",
    inputType: "Password",
  },
];
export const adminSideBarJSON = [
  {
    key: "SwitchUser",
    icon: <UploadOutlined />,
    label: "Switch User",
    linkTo: "",
  },
  {
    key: "WhiteLabel",
    icon: <UploadOutlined />,
    label: "Whitelabel",
    linkTo: "whiteLabel",
  },
  {
    key: "Customer",
    icon: <UploadOutlined />,
    label: "Customer",
    linkTo: "Customer",
  },
  {
    key: "Audit",
    icon: <UploadOutlined />,
    label: "Audit",
    linkTo: "Audit",
  },
];
export const resellerSideBarJSON = [
  {
    key: "DominSetting",
    icon: <UploadOutlined />,
    label: "Whitelabel Settings",
    linkTo: "DomainSettings",
  },
  {
    key: "Customer",
    icon: <UploadOutlined />,
    label: "Customer",
    linkTo: "Customer",
  },
];

export const AddNumberFormJSON = [
  {
    label: "Phone Number ID",
    name: "phoneNumberId",
    isRequired: true,
    requiredMessage: "Please input your Phone ID!",
    prefix: <UserOutlined className="site-form-item-icon" />,
    placeholder: "Phone Number ID",
    inputType: "Normal",
  },
  {
    label: "Whatsapp Business ID",
    name: "whatsappBusinessAccountId",
    isRequired: true,
    requiredMessage: "Please input your Business ID!",
    prefix: <UserOutlined className="site-form-item-icon" />,
    placeholder: "Whatsapp Business ID",
    inputType: "Normal",
  },
  {
    label: "Token",
    name: "token",
    isRequired: true,
    requiredMessage: "Please input your Token!",
    prefix: <PhoneOutlined className="site-form-item-icon" />,
    placeholder: "Token",
    inputType: "Normal",
  },
];

export const CANNED_MESSAGE_API_URL = "/api/v1/message/preComposed/save";
export const SEND_MESSAGE_API_URL = "/api/v1/whatsapp/sendMessage";
const statusColorMap = {
  accepted: "default",
  sent: "blue",
  delivered: "lime",
  read: "green",
  failed: "error",
  deleted: "gray",
  LIMITED: "warning",
  AVAILABLE: "success",
  BLOCKED: "error",
};

export const getColorCode = (lastStatus) => {
  return statusColorMap[lastStatus] || "";
};

export const InitialTablePostData = {
  pagination: { current: 1, pageSize: DEFAULT_PAGE_SIZE },
  order: [],
  search: [],
};

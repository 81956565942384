import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Space } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import AddLibrary from '../../Util/Add3rdPartyScript';
import { BASE_URL } from '../../Util/env';
import { fbLoginInit } from '../../constants/metaConstants';

const EmbeddedSignup = () => {
	const [token, setToken] = useState('');
	useEffect(() => {
		AddLibrary('https://connect.facebook.net/en_US/sdk.js').then(() => {
			window.fbAsyncInit = function () {
				window.FB.init(fbLoginInit);
			};
		});
		setToken(new URLSearchParams(window.location.search).get('token'));
	}, []);
	const axiosSignupApi = axios.create({
		baseURL: BASE_URL,
		timeout: 120000,
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	});
	const launchWhiteLabelEmbeddedSignup = () => {
		window.FB.login(
			function (response) {
				console.log('In FB login response', response);
				if (response.authResponse) {
					axiosSignupApi.post('/api/v1/onboard/embeddedSignup', response).then((response) => {
						if (response.status === 200) {
							window.parent.postMessage({ type: 'wlSignupFlowRes', data: response.data }, '*');
						} else {
						}
					});
				}
			},
			{
				config_id: '400722442435718',
				response_type: 'code',
				override_default_response_type: true,
				extras: {
					setup: {},
				},
			}
		);
	};

	return (
		<Button type="primary" style={{ height: '40px', width: '186px' }} onClick={launchWhiteLabelEmbeddedSignup}>
			<Space>
				<FontAwesomeIcon icon={faFacebook} />
				Login with Facebook
			</Space>
		</Button>
	);
};

export default EmbeddedSignup;

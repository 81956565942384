import {
  Button,
  Flex,
  Form,
  Modal,
  Switch,
  Table,
  Typography,
  notification,
} from "antd";
import { useEffect, useState } from "react";
import { deleteApi, getApi, postApi } from "../../constants/ApiConstant";
import { getDateFromTimeStamp } from "../../constants/DateConstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";

const Flows = () => {
  const [connectedSheetAccount, setConnectedSheetAccount] = useState([]);
  const [connectedAccount, setConnectedAccount] = useState("");
  const [isWriteToSheet, setIsWriteToSheet] = useState(false);
  const [flowsKeyModalForm] = Form.useForm();
  const [isFlowModalOpen, setIsFlowModalOpen] = useState(false);
  const [editFlowsJson, setEditFlowsJson] = useState({});
  const getGoogleSheetConnectedAccount = async () => {
    const response = await getApi("/api/v1/oath/google/connectedSheetAccount");
    if (response.status === 200) {
      setConnectedAccount(response.data);
    }
  };
  const getFlowsList = async () => {
    const response = await getApi("/api/v1/flowGoogleSheet/list");
    if (response.status === 200) {
      setConnectedSheetAccount(response.data);
    } else {
      notification.error({
        message: "Error",
        description: "Error Record Not Found!",
      });
    }
  };
  const getSheetAuthorizationUrl = async () => {
    const response = await getApi(
      "api/v1/oath/google/getSheetAuthorizationUrl"
    );
    if (response.status === 200) {
      window.open(response.data, "_blank");
    }
  };
  useEffect(() => {
    getGoogleSheetConnectedAccount();
    getFlowsList();
  }, []);
  const editHandler = (json) => {
    flowsKeyModalForm.setFieldsValue({ writeToSheet: json.writeToSheet });
    setIsFlowModalOpen(true);
    handleWriteToSheetStatus(json.writeToSheet);
    setEditFlowsJson(json);
  };
  const deleteHandler = async (id) => {
    const response = await deleteApi(`/api/v1/flowGoogleSheet/${id}`);
    if (response) {
      setConnectedSheetAccount((prevData) =>
        prevData.filter((item) => item.id !== id)
      );
      notification.success({
        message: "Success",
        description: "Item deleted successfully.",
      });
    }
  };
  const columns = [
    {
      title: "Flow Id",
      dataIndex: "flowId",
      key: "flowId",
    },
    {
      title: "Save Responses To Sheet",
      dataIndex: "writeToSheet",
      key: "writeToSheet",
      render: (writeToSheet, record) => (
        <Switch checked={writeToSheet} disabled />
      ),
    },
    {
      title: "Folder Id",
      dataIndex: "folderId",
      key: "folderId",
    },
    // {
    //   title: "Whatsapp Business AccountId",
    //   dataIndex: "whatsappBusinessAccountId",
    //   key: "whatsappBusinessAccountId",
    // },
    {
      title: "Sheet Id",
      dataIndex: "sheetId",
      key: "sheetId",
    },
    {
      title: "Creation Time",
      dataIndex: "creationTime",
      key: "creationTime",
      sorter: true,
      defaultSortOrder: "descend",
      render: (_, record) => (
        <Typography.Text>
          {getDateFromTimeStamp(record.creationTime)}
        </Typography.Text>
      ),
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      render: (_, record) => (
        <Flex gap="small">
          <Button onClick={() => editHandler(record)}>
            <FontAwesomeIcon icon={faPenToSquare} />
          </Button>
          <Button onClick={() => deleteHandler(record.id)}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </Flex>
      ),
    },
  ];
  const handleWriteToSheetStatus = (status) => {
    setIsWriteToSheet(status);
  };
  const handleCancel = () => {
    setIsFlowModalOpen(false);
    flowsKeyModalForm.resetFields();
    setEditFlowsJson({});
  };
  const onFinish = async (values) => {
    const payload = {
      id: editFlowsJson?.id,
      writeToSheet: values.writeToSheet,
    };
    const response = await postApi("/api/v1/flowGoogleSheet/update", payload);
    if (response.status === 200) {
      getFlowsList();
      notification.success({
        message: "Success",
        description: "Item Updated successfully.",
      });
    } else {
      notification.error({
        message: "Error",
        description: "Error Record Not Updated!",
      });
    }
    handleCancel();
  };
  const onFinishFailed = (values) => {};
  return (
    <Flex vertical gap={"large"}>
      <Flex justify="space-between">
        <Typography.Title level={4} style={{ margin: 0 }}>
          Connected Google Sheets For Flows
        </Typography.Title>
        <div style={{ display: "flex", flexDirection: "row" }}>
          {connectedAccount && (
            <div
              style={{
                marginRight: 40,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label style={{ fontWeight: 700, fontSize: 16 }}>
                Connected Google Account
              </label>{" "}
              <span style={{ fontWeight: 400, fontSize: 14 }}>
                {connectedAccount}
              </span>
            </div>
          )}
          <Button type="primary" onClick={getSheetAuthorizationUrl}>
            Connect Google Account
          </Button>
        </div>
      </Flex>
      <Table columns={columns} dataSource={connectedSheetAccount} />
      <Modal
        title="Edit Flows"
        open={isFlowModalOpen}
        onOk={() => flowsKeyModalForm.submit()}
        onCancel={handleCancel}
        width={600}
      >
        <Form
          form={flowsKeyModalForm}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
        >
          <Form.Item name="writeToSheet" label="Insert Responses In Sheet">
            <Switch
              checked={isWriteToSheet}
              onChange={(checked) => {
                handleWriteToSheetStatus(checked);
                flowsKeyModalForm.setFieldsValue({ writeToSheet: checked });
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </Flex>
  );
};

export default Flows;

import {
  Button,
  Flex,
  Form,
  Image,
  Input,
  Typography,
  notification,
} from "antd";
import { LockOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { getDomainSettings } from "../../constants/StorageUtil";
import FormItem from "antd/es/form/FormItem";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosLoginApi } from "../../Util/axios";

const ResetPassword = ({ domainSettingData }) => {
  const [domainData, setDomainData] = useState({});
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const query = new URLSearchParams(useLocation().search);
  const username = query.get("username");
  const navigate = useNavigate();
  const token = query.get("token");
  useEffect(() => {
    setDomainData(getDomainSettings());
  }, [domainSettingData]);
  const onFinish = (values) => {
    setLoading(true);
    const payload = {
      username,
      password: values.password,
      token,
    };
    axiosLoginApi
      .post("/api/v1/login/updatePasswordUsingToken", payload)
      .then((response) => {
        if (response.data.success) {
          notification.success({
            message: "Success",
            description: response.data.message,
          });
          moveToLoginScreen();
        } else {
          notification.error({
            message: "Error",
            description: response.data.message,
          });
        }
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const moveToLoginScreen = () => {
    navigate("/");
  };
  const onFinishFailed = (values) => {};
  return (
    <Flex
      vertical={true}
      justify="space-evenly"
      align="center"
      style={{ width: "100vw", height: "100vh" }}
      className="login-form"
    >
      <Image
        alt={domainData?.applicationName}
        src={domainData?.loginLogoUrl}
        preview={false}
        style={{ width: "30vw" }}
      />
      <Typography.Title level={4} style={{ margin: 0 }}>
        Update your cardentials
      </Typography.Title>
      <Form
        form={form}
        labelAlign="left"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 24 }}
        style={{ width: "30vw" }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <FormItem label="Username" name="username">
          <b>{username}</b>
        </FormItem>
        <FormItem
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please enter Password" }]}
        >
          <Input.Password
            inputType="Password"
            placeholder="Password"
            prefix={<LockOutlined className="site-form-item-icon" />}
          ></Input.Password>
        </FormItem>
        <Form.Item>
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            block={true}
            loading={loading}
          >
            Submit
          </Button>
        </Form.Item>
        <Form.Item>
          <Button size="large" block={true} onClick={moveToLoginScreen}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </Flex>
  );
};

export default ResetPassword;

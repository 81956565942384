import { Form, Input } from "antd";
import TemplateBody from "./TemplateBody";
import TemplateButton from "./TemplateButton";
import TemplateHeader from "./TemplateHeader";
import {
  handleBodyChange,
  handleButtonChange,
  handleHeaderChange,
} from "./TemplateUtil";

const TemplateCustom = ({
  templateData,
  setTemplateData,
  templateJSON,
  handleStateChange,
}) => {
  const templateId = Object.keys(templateData)[0];
  return (
    <>
      <TemplateHeader
        allowdDropDownValue={["None", "Image", "Text", "Video", "Document"]}
        templateHeaderData={templateData[templateId].templateHeader}
        setTemplateHeaderData={(updatedHeader) =>
          handleHeaderChange(
            templateId,
            updatedHeader,
            templateData,
            setTemplateData
          )
        }
        id={templateId}
      />
      <TemplateBody
        templateBodyData={templateData[templateId].templateBody}
        setTemplateBodyData={(updatedBody) =>
          handleBodyChange(
            templateId,
            updatedBody,
            templateData,
            setTemplateData
          )
        }
        id={templateId}
      />
      <Form.Item label="Template Footer" style={{ marginTop: 30 }}>
        <Input
          placeholder="Enter Footer value"
          value={templateJSON.footer}
          onChange={(e) => handleStateChange("footer", e.target.value)}
        />
      </Form.Item>
      <TemplateButton
        allowdButtonValue={["QUICK REPLY", "URL", "PHONE_NUMBER"]}
        templateButtonData={templateData[templateId].templateButton}
        setTemplateButtonData={(updatedButton) =>
          handleButtonChange(
            templateId,
            updatedButton,
            templateData,
            setTemplateData
          )
        }
        id={templateId}
      />
    </>
  );
};

export default TemplateCustom;

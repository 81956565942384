import { Button, Flex, Form, Input, Modal, Switch, Table, Typography, notification } from 'antd';
import { useEffect, useState } from 'react';
import { getApi, postApi } from '../../constants/ApiConstant';
import { getStorageObj } from '../../constants/StorageUtil';

const Profile = (props) => {
	const [isFtpSetting, setIsFtpSetting] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [apiKeyModalForm] = Form.useForm();
	useEffect(() => {
		getFTPsetting();
	}, []);
	const getFTPsetting = async () => {
		const response = await getApi('/api/v1/profile/ftp');
		if (response.status === 200) {
			setIsFtpSetting(response.data.active);
		}
	};
	const onFtpChangeHandler = async (checked) => {
		if (!isFtpSetting) {
			setIsModalOpen(true);
			return;
		}
		setIsFtpSetting(checked);
		const payload = {
			active: checked,
			password: 'App_test_password@123',
		};
		const response = await postApi('/api/v1/profile/ftp', payload);
	};
	const handleCancel = () => {
		setIsModalOpen(false);
		apiKeyModalForm.resetFields();
	};
	const onFinish = async (values) => {
		const payload = {
			active: !isFtpSetting && isModalOpen ? true : isFtpSetting,
			password: values.password,
		};
		const response = await postApi('/api/v1/profile/ftp', payload);
		if (response.status === 200) {
			if (!isFtpSetting && isModalOpen) {
				setIsFtpSetting(true);
			}
			notification.success({
				message: 'success',
				description: response.data.message,
			});
		}
		handleCancel();
	};
	const onFinishFailed = (values) => {
		console.log(values);
	};
	const columns = [
		{
			dataIndex: 'header',
			key: 'header',
		},
		{
			dataIndex: 'value',
			key: 'value',
			render: (text, record) => {
				if (record.key === 'FTP') {
					return <Switch checked={isFtpSetting} onChange={onFtpChangeHandler} />;
				}
				return text;
			},
		},
	];

	const data = [
		{
			key: 'UserName',
			header: 'Username',
			value: getStorageObj().userName,
		},
		{
			key: 'FTP',
			header: 'FTP',
			value: '',
		},
	];
	return (
		<Flex vertical gap={'large'}>
			<Flex justify="space-between">
				<Typography.Title level={4} style={{ margin: 0 }}>
					Profile
				</Typography.Title>
				{isFtpSetting && (
					<Button type="primary" onClick={() => setIsModalOpen(true)}>
						Set FTP Password
					</Button>
				)}
			</Flex>
			<Table columns={columns} dataSource={data} showHeader={false} pagination={false} />

			<Modal title={'Set FTP new password'} open={isModalOpen} onOk={() => apiKeyModalForm.submit()} onCancel={handleCancel}>
				<Form
					form={apiKeyModalForm}
					onFinish={onFinish}
					labelCol={{
						span: 7,
					}}
					wrapperCol={{
						span: 16,
					}}
					onFinishFailed={onFinishFailed}
				>
					<Form.Item name="password" label="Password" rules={[{ required: true, message: 'Please input your password!' }]}>
						<Input.Password placeholder="input password" />
					</Form.Item>
				</Form>
			</Modal>
		</Flex>
	);
};

export default Profile;

import {
  Button,
  Divider,
  Flex,
  Form,
  Image,
  Input,
  Typography,
  notification,
} from "antd";
import { useEffect, useState } from "react";
import { getApi, postMultiPartApi } from "../../../constants/ApiConstant";
import UploadFile from "../../SendMessage/CommonComponents/UploadComponrnt/UploadFile";

const DomainSettings = () => {
  const [domainSettingJson, setDomainSettingJson] = useState({});
  const [isDomainConfigEditMode, setIsDomainConfigEditMode] = useState(false);
  const [domainConfigKeyModalForm] = Form.useForm();
  const [connectedGmail, setConnectedGmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [googleSignInCol, setGoogleSignInCol] = useState({});
  let formData = new FormData();
  const editDomainSettingHandler = () => {
    domainConfigKeyModalForm.setFieldsValue(domainSettingJson);
    setIsDomainConfigEditMode(true);
  };

  const getDomainConfig = async () => {
    const response = await getApi("/api/v1/reseller/domain/config");
    if (response && response.status === 200) {
      setDomainSettingJson({ ...response.data });
    } else {
      notification.error({
        message: "Error",
        description: "Something went wrong on server please try again !!",
      });
    }
  };

  const getConnectedEmail = async () => {
    const response = await getApi("/api/v1/reseller/connected/email");
    if (response.status === 200) {
      setConnectedGmail(response.data.email);
      if (response.data.email) {
        let obj = {
          offset: 19,
          span: 15,
        };
        setGoogleSignInCol(obj);
      } else {
        let obj = {
          offset: 21,
          span: 15,
        };
        setGoogleSignInCol(obj);
      }
    }
  };
  useEffect(() => {
    getDomainConfig();
    getConnectedEmail();
  }, []);

  const handleCancel = () => {
    domainConfigKeyModalForm.resetFields();
    setIsDomainConfigEditMode(false);
  };

  const onFinish = async (values) => {
    setLoading(true);
    for (const key in values) {
      if (values.hasOwnProperty(key) && values[key]) {
        formData.append([key], values[key]);
      }
    }
    formData.append("id", domainSettingJson.id);
    const response = await postMultiPartApi(
      "/api/v1/reseller/domain/config",
      formData
    );
    if (response.status === 200) {
      notification.success({
        message: "Success",
        description: response.data.message,
      });
    } else {
      notification.error({
        message: "Error",
        description: response.data.message,
      });
    }
    getDomainConfig();
    handleCancel();
    setLoading(false);
  };
  const onFinishFailed = (values) => { };
  const onGooleSignInHandler = async () => {
    const response = await getApi(
      "/api/v1/oath/google/getGmailAuthorizationUrl"
    );
    if (response.status === 200) {
      window.open(response.data, "_blank");
    }
  };
  const handleHeaderFileChange = (info, isFileUpload, fileUrl, sectionName) => {
    const currentValues = domainConfigKeyModalForm.getFieldsValue();
    currentValues[sectionName] = info.originFileObj;
    domainConfigKeyModalForm.setFieldsValue({
      ...currentValues,
    });
  };

  return (
    <>
      {Object.keys(domainSettingJson).length > 0 ? (
        <Flex vertical gap={"large"}>
          <Flex justify="space-between">
            <Typography.Title level={4} style={{ margin: 0 }}>
              Whitelabel Settings
            </Typography.Title>
          </Flex>
          {!isDomainConfigEditMode && (
            <Form
              labelCol={{
                span: 5,
              }}
              wrapperCol={{
                span: 20,
              }}
              labelAlign="left"
              style={{
                backgroundColor: "#FFF",
                border: "1px solid #CCC",
                borderRadius: 5,
                padding: 30,
              }}
            >
              <Form.Item name="hostName" label={<b>Host Name</b>}>
                <b>
                  <span>{domainSettingJson.hostName}</span>
                </b>
              </Form.Item>
              <Divider />
              <Form.Item name="applicationName" label="Application Name">
                <span>{domainSettingJson.applicationName}</span>
              </Form.Item>
              <Form.Item name="supportPhone" label="Support Phone">
                <span>{domainSettingJson.supportPhone}</span>
              </Form.Item>
              <Form.Item name="supportEmail" label="Support Email">
                <span>{domainSettingJson.supportEmail}</span>
              </Form.Item>
              <Form.Item name="loginLogoUrl" label="Login Logo">
                <Image
                  src={domainSettingJson?.loginLogoUrl}
                  width={200}
                  height={100}
                />
              </Form.Item>
              <Form.Item name="sidebarLogoUrl" label="Sidebar Logo">
                <Image
                  src={domainSettingJson?.sidebarLogoUrl}
                  width={200}
                  height={100}
                />
              </Form.Item>
              <Form.Item name="favIcoUrl" label="Fav Icon">
                <Image
                  src={domainSettingJson?.favIcoUrl}
                  width={200}
                  height={100}
                />
              </Form.Item>
              <Form.Item>
                {connectedGmail && (
                  <>
                    <div>
                      <label style={{ marginRight: 5, fontSize: 16 }}>
                        <b>Connected Gmail Account :</b>
                      </label>
                      <span>{connectedGmail}</span>
                    </div>
                    <b>(Used for sending password reset emails)</b>
                  </>
                )}
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  offset: 23,
                  span: 10,
                }}
              >
                <Button type="primary" onClick={editDomainSettingHandler}>
                  Edit
                </Button>
              </Form.Item>
            </Form>
          )}
          {isDomainConfigEditMode && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Form
                form={domainConfigKeyModalForm}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                labelCol={{
                  span: 5,
                }}
                wrapperCol={{
                  span: 20,
                }}
                style={{
                  backgroundColor: "#FFF",
                  border: "1px solid #CCC",
                  borderRadius: 5,
                  padding: 30,
                  width: "100%",
                }}
              >
                <Form.Item name="hostName" label="Host Name">
                  <Input placeholder="Host Name" disabled />
                </Form.Item>
                <Form.Item name="applicationName" label="Application Name">
                  <Input placeholder="Application Name" />
                </Form.Item>
                <Form.Item name="supportPhone" label="Support Phone Number">
                  <Input placeholder="Support Phone no" />
                </Form.Item>
                <Form.Item name="supportEmail" label="Support Email">
                  <Input placeholder="Support Email" />
                </Form.Item>
                {domainSettingJson?.loginLogoUrl && (
                  <Form.Item label=" ">
                    <Image
                      src={domainSettingJson?.loginLogoUrl}
                      width={200}
                      height={100}
                    />
                  </Form.Item>
                )}
                <Form.Item name="loginLogo" label="Login Logo">
                  <UploadFile
                    uploadFileName="Upload Image"
                    uploadFileChangeHandler={handleHeaderFileChange}
                    resetValue={false}
                    tabName="IMAGE"
                    sectionName="loginLogo"
                    isHideField={true}
                  />
                </Form.Item>
                {domainSettingJson?.sidebarLogoUrl && (
                  <Form.Item label=" ">
                    <Image
                      src={domainSettingJson?.sidebarLogoUrl}
                      width={200}
                      height={100}
                    />
                  </Form.Item>
                )}
                <Form.Item name="sidebarLogo" label="Sidebar Logo">
                  <UploadFile
                    uploadFileName="Upload Image"
                    uploadFileChangeHandler={handleHeaderFileChange}
                    resetValue={false}
                    tabName="IMAGE"
                    sectionName="sidebarLogo"
                    isHideField={true}
                  />
                </Form.Item>
                {domainSettingJson?.favIcoUrl && (
                  <Form.Item label=" ">
                    <Image
                      src={domainSettingJson?.favIcoUrl}
                      width={200}
                      height={100}
                    />
                  </Form.Item>
                )}
                <Form.Item name="favIco" label="Fav Icon">
                  <UploadFile
                    uploadFileName="Upload Image"
                    uploadFileChangeHandler={handleHeaderFileChange}
                    resetValue={false}
                    tabName="IMAGE"
                    sectionName="favIco"
                    isHideField={true}
                  />
                </Form.Item>
                <Form.Item
                  wrapperCol={{
                    offset: 18,
                    span: 19,
                  }}
                >
                  <div style={{ textAlign: "right" }}>
                    <Button onClick={handleCancel} style={{ marginRight: 10 }}>
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      loading={loading}
                      onClick={() => domainConfigKeyModalForm.submit()}
                    >
                      Submit
                    </Button>
                  </div>
                </Form.Item>
                <Form.Item>
                  {connectedGmail && (
                    <>
                      <div>
                        <label style={{ marginRight: 5, fontSize: 16 }}>
                          <b>Connected Gmail Account :</b>
                        </label>
                        <span>{connectedGmail}</span>
                      </div>
                      <b>Used for sending password reset emails</b>
                    </>
                  )}
                </Form.Item>
                <Form.Item wrapperCol={googleSignInCol}>
                  <Button type="primary" onClick={onGooleSignInHandler}>
                    {connectedGmail ? "Change google account" : "Google SignIn"}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          )}
        </Flex>
      ) : (
        <p>Please Connect to admin to see this confiration</p>
      )}
    </>
  );
};

export default DomainSettings;

// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearAllData } from '../Store/action';
import { getStorageObj, setSorageObj } from '../constants/StorageUtil';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const isTokenExpire = useSelector((state) => state.isTokenExpire)
  const [isLoggedIn, setLoggedIn] = useState(() => {
    const storageObj = getStorageObj() ? getStorageObj() : {};
    const storedAuthState = storageObj['authState'] ?  storageObj['authState']:false;
    return storedAuthState;
  });

  useEffect(() => {
      const storageObj = getStorageObj();
      let updatedStorageObj = {...storageObj, authState:isLoggedIn};
      setSorageObj(updatedStorageObj);
  }, [isLoggedIn]);

  useEffect(() => {
    if(isTokenExpire) {
      logout();
    }
  },[isTokenExpire])
  const login = () => {
    setLoggedIn(true);
  };

  const logout = () => {
    dispatch(clearAllData({}));
    setLoggedIn(false);
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

import { useEffect } from "react";
import TemplateBody from "./TemplateBody";
import TemplateButton from "./TemplateButton";
import TemplateHeader from "./TemplateHeader";
import { Form, Input, Select } from "antd";
import {
  handleBodyChange,
  handleButtonChange,
  handleHeaderChange,
} from "./TemplateUtil";
const flowActionJSON = [
  {
    label: "Navigate",
    value: "NAVIGATE",
    id: "NAVIGATE",
  },
  {
    label: "Data Exchange",
    value: "DATA_EXCHANGE",
    id: "DATA_EXCHANGE",
  },
];
const TemplateFlow = ({
  templateData,
  setTemplateData,
  templateJSON,
  handleStateChange,
}) => {
  const templateId = Object.keys(templateData)[0];
  useEffect(() => {
    const updatedButtonJSON = JSON.parse(JSON.stringify(templateData));
    let obj = {
      type: "FLOW",
      id: Math.floor(Math.random() * 2000),
      text: "Register",
    };
    updatedButtonJSON[templateId].templateButton.push(obj);
    setTemplateData(updatedButtonJSON);
  }, [templateId]);
  const onInputChangeHandler = (field, value) => {
    const updateTemplateData = { ...templateData };
    updateTemplateData[templateId].templateButton[0][field] = value;
    setTemplateData(updateTemplateData);
  };
  return (
    <>
      <Form.Item label="Flow Id" name="flowId">
        <Input
          type="text"
          placeholder="Enter Flow Id"
          value={templateData[templateId].templateButton?.flow_id || ""}
          onChange={(e) => onInputChangeHandler("flow_id", e.target.value)}
        />
      </Form.Item>
      <Form.Item label="Flow Action" name="flow_action">
        <Select
          value={templateData[templateId].templateButton?.flow_action}
          onChange={(e) => onInputChangeHandler("flow_action", e)}
        >
          {flowActionJSON.map((rec) => (
            <Select.Option key={rec.id} value={rec.value}>
              {rec.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Navigate Screen" name="navigate_screen">
        <Input
          type="text"
          placeholder="Enter navigate screen name"
          value={templateData[templateId].templateButton?.navigate_screen || ""}
          onChange={(e) =>
            onInputChangeHandler("navigate_screen", e.target.value)
          }
        />
      </Form.Item>
      <TemplateHeader
        allowdDropDownValue={["None", "Image", "Text", "Video", "Document"]}
        templateHeaderData={templateData[templateId].templateHeader}
        setTemplateHeaderData={(updatedHeader) =>
          handleHeaderChange(
            templateId,
            updatedHeader,
            templateData,
            setTemplateData
          )
        }
        id={templateId}
      />
      <TemplateBody
        templateBodyData={templateData[templateId].templateBody}
        setTemplateBodyData={(updatedBody) =>
          handleBodyChange(
            templateId,
            updatedBody,
            templateData,
            setTemplateData
          )
        }
        id={templateId}
      />
      <Form.Item label="Template Footer" style={{ marginTop: 30 }}>
        <Input
          placeholder="Enter Footer value"
          value={templateJSON.footer}
          onChange={(e) => handleStateChange("footer", e.target.value)}
        />
      </Form.Item>
      <TemplateButton
        allowdButtonValue={["QUICK REPLY"]}
        templateButtonData={templateData[templateId].templateButton}
        setTemplateButtonData={(updatedButton) =>
          handleButtonChange(
            templateId,
            updatedButton,
            templateData,
            setTemplateData
          )
        }
        hideAddButton={true}
        id={templateId}
      />
    </>
  );
};

export default TemplateFlow;

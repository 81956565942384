import { Card, Col, Form, Input, Row, notification } from 'antd';
import { useImperativeHandle, useState } from 'react';
import { TEXT_AREA_SIZE } from '../../constants/SizeLimit';
import MessagePreview from '../MessagePreview/MessagePreview';
import UploadFile from './CommonComponents/UploadComponrnt/UploadFile';
const { TextArea } = Input;
const SendDocumentMessage = (props) => {
	const { childFormRef, formData, messageForm } = props;
	const [uplodedFile, setUplodedFile] = useState({});
	const [fileUrl, setFileUrl] = useState('');
	const [isFileUpload, setIsFileUplod] = useState(false);
	const [textValue, setTextValue] = useState('');
	const [restUplodFile, setRestUplodFile] = useState(false);

	const resetFormField = () => {
		setUplodedFile({});
		setFileUrl('');
		setIsFileUplod(false);
		setTextValue('');
		setRestUplodFile(!restUplodFile);
	};
	const ValidationFormField = () => {
		if (Object.keys(uplodedFile).length === 0) {
			notification.error({
				message: 'Error',
				description: 'Please Upload document!!',
			});
			return false;
		}
		return true;
	};

	const uploadFileChangeHandler = (data, isFileUpload, fileUrl) => {
		setUplodedFile(data);
		setFileUrl(fileUrl);
		setIsFileUplod(isFileUpload);
	};
	const changeHandler = (e) => {
		setTextValue(e.target.value);
	};
	const getFormData = () => {
		return new Promise((resolve, reject) => {
			messageForm
				.validateFields()
				.then((values) => {
					const isValid = ValidationFormField();
					if (!isValid) {
						return true;
					}
					if(isFileUpload) {
						formData.append('uploadFile', uplodedFile.originFileObj);
					} else {
						formData.append('fileUrl', uplodedFile);
					}
					formData.append('caption', textValue);
					resolve(formData);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};
	useImperativeHandle(childFormRef, () => ({
		formData: getFormData,
		resetData: resetFormField,
	}));
	return (
		<div
			style={{
				padding: '20px 0px',
			}}
		>
			<Row style={{ display: 'flex', justifyContent: 'space-between' }}>
				<Col xs={24} sm={15} md={15} lg={15} xl={15}>
					<Card style={{ width: '100%', marginBottom: 20 }}>
						<Form.Item>
							<UploadFile
								uploadFileName={'Upload Document...'}
								uploadFileChangeHandler={uploadFileChangeHandler}
								resetValue={restUplodFile}
								tabName={props.tabName}
							/>
						</Form.Item>
						<Form.Item>
							<label style={{ marginBottom: 5 }}>Caption:</label>
							<TextArea
								showCount
								maxLength={TEXT_AREA_SIZE}
								placeholder={`Enter text message upto ${TEXT_AREA_SIZE}`}
								style={{
									height: 120,
									resize: 'none',
								}}
								value={textValue}
								onChange={changeHandler}
							/>
						</Form.Item>
					</Card>
				</Col>
				<MessagePreview type="Document" payload={{ textValue: textValue, fileUrl: fileUrl }} title="Preview" />
			</Row>
		</div>
	);
};

export default SendDocumentMessage;

const VideoMessagePreview = ({ data }) => {
  return (
    <div>
      {!data.link && <div className="media-container video-placeholder"></div>}
      {data.link && (
        <div className="media-container">
          <video width="100%" height="100%" controls>
            <source src={data.link} type="video/mp4" />
          </video>
        </div>
      )}
      {data.caption && <pre>{data.caption}</pre>}
    </div>
  );
};

export default VideoMessagePreview;

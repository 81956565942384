import { Button, Card, Col, Form, Input, Row, notification } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { CANNED_MESSAGE_API_URL } from '../../constants/Constant';
import CustomTabs from '../CustomTabs/CustomTabs';
import { postMultiPartApi } from '../../constants/ApiConstant';

const AddUpdateCannedMessage = ({ hideCannedMessage, jsonData }) => {
	const [value, setValue] = useState(jsonData?.name || '');
	const [isLoading, setIsLoading] = useState(false);
	const tabJSON = ['Text', 'Image', 'Video', 'Document', 'Audio', 'Location', 'Contacts', 'Template', 'Interactive'];
	const [cannedMessageForm] = Form.useForm();
	const selectedTabName = useSelector((state) => state.selectedTab);
	let formData = new FormData();
	const childFormRef = useRef(null);

	useEffect(() => {
		cannedMessageForm.resetFields();
	}, [selectedTabName, cannedMessageForm]);
	const onChangeHandler = (e) => {
		setValue(e.target.value);
	};

	const validateGlobalFields = () => {
		if (!value) {
			const description = 'Please Enter Name !!';
			notification.error({
				message: 'Error',
				description: description,
			});
			return false;
		}
		return true;
	};

	const onFinish = async (values) => {
		const isValid = validateGlobalFields();
		if (!isValid) {
			return;
		}
		formData = await childFormRef.current.formData();
		if (typeof formData === 'boolean') {
			return;
		}
		formData.append('name', value);
		if (jsonData?.id) {
			formData.append('id', jsonData?.id);
		}
		setIsLoading(true);
		try {
			const response = await postMultiPartApi(CANNED_MESSAGE_API_URL, formData);
			if (response && response.status === 200) {
				cannedMessageForm.resetFields();
				notification.success({
					message: 'Success',
					description: response.data.message,
				});
				setIsLoading(false);
				hideCannedMessage();
				childFormRef.current.resetData();
			} else {
				setIsLoading(false);
			}
		} catch (error) {
			setIsLoading(false);
			console.error('Error:', error);
		}
	};
	const onFinishFailed = (values) => {
		console.log(values);
	};
	return (
		<div>
			<Row>
				<Col xs={22} sm={22} md={22} lg={22} xl={22}>
					<Card title="Canned Message" style={{ width: '100%', marginBottom: 20 }}>
						<div
							style={{
								display: 'flex',
								marginBottom: 10,
								alignItems: 'center',
							}}
						>
							<label style={{ paddingRight: 10 }}>Name:</label>
							<Input onChange={onChangeHandler} value={value} style={{ flex: 1 }} />
						</div>
						<Form
							form={cannedMessageForm}
							name="cannedMessage"
							style={{
								maxWidth: '100%',
							}}
							initialValues={{
								remember: true,
							}}
							onFinish={onFinish}
							onFinishFailed={onFinishFailed}
							autoComplete="off"
						>
							<Form.Item>
								<CustomTabs tabData={tabJSON} childFormRef={childFormRef} formData={formData} messageForm={cannedMessageForm} />
							</Form.Item>
							<Form.Item
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									margin: 0,
								}}
							>
								<Button type="primary" htmlType="submit" loading={isLoading}>
									Submit
								</Button>
								<Button onClick={() => hideCannedMessage()} style={{marginLeft:10}}>
									Cancel
								</Button>
							</Form.Item>
						</Form>
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default AddUpdateCannedMessage;

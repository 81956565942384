import { CloseCircleOutlined } from '@ant-design/icons';
import { faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Descriptions, Flex, Form, Input, Select, Table, Tag, Typography, notification } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setContactAttributes, setContactLabel } from '../../Store/action';
import { postApi } from '../../constants/ApiConstant';
import { getDateFromTimeStamp } from '../../constants/DateConstants';
import './Contact.css';
const Contacts = () => {
	const [selectedContact, setSelectedContact] = useState(null);
	const [selectedContactList, setSelectedContactList] = useState([]);
	const contactAttributes = useSelector((state) => state.contactAttributes);
	const contactLabel = useSelector((state) => state.contactLabel);
	const [tableData, setTableData] = useState([]);
	const [attributesList, setAttributesList] = useState([]);
	const [contactLabelList, setContactLabelList] = useState([]);
	const [isAddEditAttribute, setIsAddEditAttribute] = useState(false);
	const [isAddContactLabel, setIsAddContactLabel] = useState(false);
	const [editingAttribute, setEditingAttribute] = useState(null);
	const selectedPhoneRec = useSelector((state) => state.selectedPhoneRec);
	const [contactAttributeForm] = Form.useForm();
	const [contactLabelForm] = Form.useForm();
	const dispatch = useDispatch();

	const columns = [
		{
			title: 'Id',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Whatsapp Name',
			dataIndex: 'whatsappName',
			key: 'whatsappName',
		},
		{
			title: 'Phone Number',
			dataIndex: 'phoneNumber',
			key: 'phoneNumber',
		},
		{
			title: 'Creation Time',
			dataIndex: 'creationTime',
			key: 'creationTime',
			sorter: (a, b) => true,
			defaultSortOrder: 'descend',
			render: (_, record) => <Typography.Text>{getDateFromTimeStamp(record.creationTime)}</Typography.Text>,
		},
		{
			title: 'LastUpdate Time',
			dataIndex: 'lastUpdateTime',
			key: 'lastUpdateTime',
			sorter: (a, b) => true,
			render: (_, record) => <Typography.Text>{getDateFromTimeStamp(record.creationTime)}</Typography.Text>,
		},
		{
			title: 'Action',
			dataIndex: 'Action',
			key: 'Action',
			render: (_, record) => (
				<Flex gap="small">
					<Button type="primary" onClick={() => handleRowClick(record)}>
						Details
					</Button>
				</Flex>
			),
		},
	];
	useEffect(() => {
		if (contactLabel?.length === 0) {
			getLabelData();
		}
		if (contactAttributes?.length === 0) {
			getAttributeData();
		}
		getContactsData();
	}, [selectedPhoneRec]);
	const getContactsData = async (refreshAttributeList) => {
		let payload = {
			order: [
				{
					fieldName: 'creationTime',
					dir: 'desc',
				},
			],
		};
		const response = await postApi('/api/v1/contact/list', payload);
		if (response && response.status === 200) {
			setTableData(response.data.results);
			if (refreshAttributeList === 'addAttribute' || refreshAttributeList === 'deleteAttr') {
				const selectedContactRec = response.data.results.find((rec) => {
					return rec.id === selectedContact.id;
				});
				handleRowClick(selectedContactRec);
			}
		}
	};
	const getAttributeData = async () => {
		let obj = {
			order: [
				{
					fieldName: 'creationTime',
					dir: 'desc',
				},
			],
		};
		const response = await postApi('/api/v1/contact/attribute/list', obj);
		if (response && response.status === 200) {
			dispatch(setContactAttributes(response.data.results));
		}
	};
	const getLabelData = async () => {
		let obj = {
			order: [
				{
					fieldName: 'creationTime',
					dir: 'desc',
				},
			],
		};
		const response = await postApi('/api/v1/contact/label/list', obj);
		if (response && response.status === 200) {
			dispatch(setContactLabel(response.data.results));
		}
	};
	const handleRowClick = (record) => {
		const updatedAttributesData = [];
		const updatedContactLabelList = [];
		if (record?.attributes) {
			Object.entries(record.attributes).forEach(([key, value]) => {
				const attributeRec = contactAttributes?.find((rec) => {
					return rec.id === key;
				});
				if (attributeRec) {
					let obj = {
						label: attributeRec.name,
						value: value,
						id: attributeRec.id,
					};
					updatedAttributesData.push(obj);
				}
			});
		}
		if (record.labels && record.labels.length > 0) {
			for (let i = 0; i < record.labels.length; i++) {
				const contactLabelRec = contactLabel?.find((rec) => {
					return rec.id === record.labels[i];
				});
				if (contactLabelRec) {
					updatedContactLabelList.push({
						id: contactLabelRec.id,
						name: contactLabelRec.name,
						colorCode: contactLabelRec.colorCode,
					});
				}
			}
		}
		setContactLabelList(updatedContactLabelList);
		setAttributesList(updatedAttributesData);
		const editContactList = [
			{
				key: '1',
				label: 'Name',
				children: record.name,
			},
			{
				key: '2',
				label: 'Whatsapp Name',
				children: record.whatsappName,
			},
			{
				key: '3',
				label: 'Phone Number',
				children: record.phoneNumber,
			},
			{
				key: '4',
				label: 'Creation Time',
				children: getDateFromTimeStamp(record.creationTime),
			},
			{
				key: '5',
				label: 'Last Update Time',
				children: getDateFromTimeStamp(record.lastUpdateTime),
			},
		];
		setSelectedContactList(editContactList);
		setSelectedContact(record);
	};
	const closeContactDetails = () => {
		setSelectedContact(null);
		setSelectedContactList([]);
	};
	const addAttributeHandler = (event) => {
		event.stopPropagation();
		contactAttributeForm.resetFields();
		setEditingAttribute(null);
		setIsAddEditAttribute(true);
	};
	const addLabelHandler = (event) => {
		event.stopPropagation();
		contactLabelForm.resetFields();
		setIsAddContactLabel(true);
	};
	const onLabelFinish = async (values) => {
		const payload = {
			...values,
			contactId: selectedContact.id,
		};
		const response = await postApi('/api/v1/contact/setLabel', payload);
		if (response && response.status === 200) {
			setIsAddContactLabel(false);
			contactLabelForm.resetFields();
			getContactsData('addAttribute');
		}
	};
	const onFinish = async (values) => {
		const payload = {
			...values,
			contactId: selectedContact.id,
		};
		const response = await postApi('/api/v1/contact/setAttribute', payload);
		if (response && response.status === 200) {
			setIsAddEditAttribute(false);
			contactAttributeForm.resetFields();
			getContactsData('addAttribute');
			setEditingAttribute(null);
		}
	};
	const onFinishFailed = (values) => {
		console.log(values);
	};
	const editHandler = (attributeId) => {
		const attribute = attributesList.find((attr) => attr.id === attributeId);
		setEditingAttribute(attribute);
		contactAttributeForm.setFieldsValue({
			attributeId: attribute.id,
			attributeValue: attribute.value,
		});
		setIsAddEditAttribute(true);
	};
	const deleteContactLabel = async (labelId) => {
		const payload = {
			labelId: labelId,
			contactId: selectedContact.id,
		};
		const response = await postApi('/api/v1/contact/deleteLabel', payload);
		if (response && response.status === 200) {
			notification.success({
				message: 'success',
				description: response.data.message,
			});
			setIsAddContactLabel(false);
			contactLabelForm.resetFields();
			getContactsData('deleteAttr');
		}
	};
	const deleteContactAttrHandler = async (attrId) => {
		const payload = {
			attributeId: attrId,
			contactId: selectedContact.id,
		};
		const response = await postApi('/api/v1/contact/deleteAttribute', payload);
		if (response && response.status === 200) {
			notification.success({
				message: 'success',
				description: response.data.message,
			});
			setIsAddEditAttribute(false);
			contactAttributeForm.resetFields();
			getContactsData('deleteAttr');
			setEditingAttribute(null);
		}
	};
	const saveContactDetails = async() => {
		const payload = {
			contactId: selectedContact.id,
		};
		const response = await postApi('/api/v1/contact/save', payload);
			if (response && response.status === 200) {
				getContactsData();
			};
	};
	const displayContactAttributes = (attributesList) => {
		const columns = [
			{
				title: 'Name',
				dataIndex: 'label',
				key: 'label',
				render: (text) => <Tag>{text}</Tag>,
			},
			{
				title: 'Value',
				dataIndex: 'value',
				key: 'value',
			},
			{
				title: 'Action',
				dataIndex: 'Action',
				key: 'Action',
				render: (_, record) => (
					<Flex gap="small">
						<Button onClick={() => editHandler(record.id)}>
							<FontAwesomeIcon icon={faPenToSquare} />
						</Button>
						<Button onClick={() => deleteContactAttrHandler(record.id)}>
							<FontAwesomeIcon icon={faTrash} />
						</Button>
					</Flex>
				),
			},
		];
		return <Table size="small" locale={{emptyText: (<Typography.Text type="secondary">No Attributes</Typography.Text>)}} pagination={false} showHeader={false} columns={columns} dataSource={attributesList} />;
	};

	const displayContactLabels = (contactLabelList) => {
		const columns = [
			{
				title: 'Name',
				dataIndex: 'label',
				key: 'label',
				render: (_, record) => <Tag color={record.colorCode}>{record.name}</Tag>,
			},
			{
				title: 'Action',
				dataIndex: 'Action',
				key: 'Action',
				render: (_, record) => (
					<Button onClick={() => deleteContactLabel(record.id)}>
						<FontAwesomeIcon icon={faTrash} />
					</Button>
				),
			},
		];
		return <Table size="small" locale={{emptyText: (<Typography.Text type="secondary">No Labels</Typography.Text>)}} pagination={false} showHeader={false} columns={columns} dataSource={contactLabelList} />;
	};

	return (
		<Flex horizontal vertical gap={'large'}>
			<Flex justify="space-between">
				<Typography.Title level={4} style={{ margin: 0 }}>
					Contacts
				</Typography.Title>
			</Flex>
			<div style={{ flex: 1 }}>
				<Table
					columns={columns}
					dataSource={tableData}
					onRow={(record) => ({
						onClick: () => handleRowClick(record),
					})}
				/>
			</div>
			{selectedContactList.length > 0 && (
				<Flex vertical gap={'middle'} style={{ width: '30%' }}>
					<Flex justify="space-between">
						<Typography.Title level={5} style={{ margin: 0 }}>
							Contact Info
						</Typography.Title>
						<CloseCircleOutlined onClick={closeContactDetails} />
					</Flex>
					<Descriptions
						bordered
						size="small"
						column={1}
						items={selectedContactList?.map((item) => {
							return {
								key: item.key,
								label: item.label,
								children: item.children,
							};
						})}
					></Descriptions>
					<Flex justify="space-between">
						<Typography.Text strong>Attributes</Typography.Text>
						<Button onClick={(event) => addAttributeHandler(event)} type="primary" size="small">
							Add
						</Button>
					</Flex>
					{displayContactAttributes(attributesList)}
					{isAddEditAttribute && (
						<div style={{ border: '1px solid #CCC', padding: 10 }}>
							<Form
								form={contactAttributeForm}
								name="textmessage"
								labelCol={{
									span: 10,
								}}
								wrapperCol={{
									span: 14,
								}}
								style={{
									maxWidth: '100%',
								}}
								onFinish={onFinish}
								onFinishFailed={onFinishFailed}
								autoComplete="off"
							>
								<Form.Item label="Select Attribute" name="attributeId">
									<Select
										disabled={editingAttribute?.id ? true : false}
										style={{
											width: 220,
											marginRight: 10,
										}}
									>
										{contactAttributes.map((phone) => (
											<Select.Option key={phone.id} value={phone.id}>
												{phone.name}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
								<Form.Item label="Value" name="attributeValue">
									<Input />
								</Form.Item>

								<Form.Item
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										margin: 0,
									}}
								>
									<Button type="primary" htmlType="submit">
										Submit
									</Button>
								</Form.Item>
							</Form>
						</div>
					)}
					<Flex justify="space-between">
						<Typography.Text strong>Labels</Typography.Text>
						<Button onClick={(event) => addLabelHandler(event)} type="primary" size="small">
							Add
						</Button>
					</Flex>
					{displayContactLabels(contactLabelList)}

					<div>
						<div className="contact-container">
							{isAddContactLabel && (
								<div
									style={{
										border: '1px solid #CCC',
										padding: 10,
										marginBottom: 50,
									}}
								>
									<Form
										form={contactLabelForm}
										name="textmessage"
										labelCol={{
											span: 10,
										}}
										wrapperCol={{
											span: 14,
										}}
										style={{
											maxWidth: '100%',
										}}
										onFinish={onLabelFinish}
										onFinishFailed={onFinishFailed}
										autoComplete="off"
									>
										<Form.Item label="Select Label" name="labelId">
											<Select
												style={{
													width: 220,
													marginRight: 10,
												}}
											>
												{contactLabel.map((laebl) => (
													<Select.Option key={laebl.id} value={laebl.id}>
														{laebl.name}
													</Select.Option>
												))}
											</Select>
										</Form.Item>

										<Form.Item
											style={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												margin: 0,
											}}
										>
											<Button type="primary" htmlType="submit">
												Submit
											</Button>
										</Form.Item>
									</Form>
								</div>
							)}
						</div>
					</div>
				</Flex>
			)}
		</Flex>
	);
};

export default Contacts;

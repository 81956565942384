import { DeleteOutlined } from '@ant-design/icons';
import { Button, Card, Collapse, Form, Input, notification } from 'antd';
import { useImperativeHandle, useState } from 'react';
const { TextArea } = Input;
const ListButtonElementJSON = {
	sectionDetails: [
		{
			title: 'Section Title(Optional)',
			titleName: 'listButton.sections[0].title',
			isRequired: false,
			itemJSON: [
				{
					itemTitle: 'Item Title',
					itemTitleName: 'listButton.sections[0].rows[0].title',
					isItemTitleRequired: true,
					itemDescTitle: 'Item description(Optional)',
					itemDescName: 'listButton.sections[0].rows[0].description',
					isItemDescRequired: false,
				},
			],
		},
	],
};

const SendListButtonMessage = (props) => {
	const [listButtonJSON, setListButtonJSON] = useState(ListButtonElementJSON);
	const { childFormRef, formData, messageForm } = props;
	const [activeKey, setActiveKey] = useState('0');

	const handleChange = (key) => {
		setActiveKey((prevKey) => (prevKey === key ? '' : key));
	};

	const resetAllFields = () => {
		setListButtonJSON(ListButtonElementJSON);
		messageForm.resetFields();
		setActiveKey('0');
	};
	const addNewItem = (sectionIndex) => {
		let obj = {
			itemTitle: 'Item Title',
			isItemTitleRequired: true,
			itemTitleName: `listButton.sections[${listButtonJSON.sectionDetails.length - 1}].rows[${
				listButtonJSON.sectionDetails[sectionIndex].itemJSON.length
			}].title`,
			itemDescName: `listButton.sections[${listButtonJSON.sectionDetails.length - 1}].rows[${
				listButtonJSON.sectionDetails[sectionIndex].itemJSON.length
			}].description`,
			itemDescTitle: 'Item description(Optional)',
			isItemDescRequired: false,
		};
		const updatedJSON = JSON.parse(JSON.stringify(listButtonJSON));
		updatedJSON.sectionDetails[sectionIndex].itemJSON.push(obj);
		setListButtonJSON(updatedJSON);
	};
	const addNewSection = () => {
		let obj = {
			title: 'Section Title(Optional)',
			titleName: `listButton.sections[${listButtonJSON.sectionDetails.length}].title`,
			isRequired: false,
			itemJSON: [
				{
					itemTitle: 'Item Title',
					isItemTitleRequired: true,
					itemTitleName: `listButton.sections[${listButtonJSON.sectionDetails.length}].rows[0].title`,
					itemDescName: `listButton.sections[${listButtonJSON.sectionDetails.length}].rows[0].description`,
					itemDescTitle: 'Item description(Optional)',
					isItemDescRequired: false,
				},
			],
		};
		const updatedJSON = JSON.parse(JSON.stringify(listButtonJSON));
		updatedJSON.sectionDetails.push(obj);
		setListButtonJSON(updatedJSON);

		setActiveKey((prevKey) => (prevKey === updatedJSON.sectionDetails.length - 1 ? '' : updatedJSON.sectionDetails.length - 1));
	};
	const handleDeleteSection = (deleteIndex) => {
		const updatedJSON = JSON.parse(JSON.stringify(listButtonJSON));
		if (updatedJSON.sectionDetails.length > 1) {
			updatedJSON.sectionDetails.splice(deleteIndex, 1);
			setListButtonJSON(updatedJSON);
		} else {
			notification.error({
				message: 'Error',
				description: 'At least One section Required!!!',
			});
		}
	};
	const getFormData = () => {
		return new Promise((resolve, reject) => {
			messageForm
				.validateFields()
				.then((values) => {
					for (const [key, value] of Object.entries(values)) {
						formData.append(key, value);
					}
					resolve(formData);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};
	useImperativeHandle(childFormRef, () => ({
		formData: getFormData,
		resetData: resetAllFields,
	}));
	const handleDeleteItem = (deleteIndex, sectionIndex) => {
		const updatedJSON = JSON.parse(JSON.stringify(listButtonJSON));
		if (updatedJSON.sectionDetails[sectionIndex].itemJSON.length > 1) {
			updatedJSON.sectionDetails[sectionIndex].itemJSON.splice(deleteIndex, 1);
			setListButtonJSON(updatedJSON);
		} else {
			notification.error({
				message: 'Error',
				description: 'At least One Item is Required!!!',
			});
		}
	};
	return (
		<Form.Item>
			<Card>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						flexWrap: 'wrap',
					}}
				>
					<Form.Item
						name="listButton.button"
						labelCol={{ span: 7 }}
						style={{ width: '48%' }}
						label="List Button Text"
						rules={[
							{
								required: true,
								message: 'Please enter Header Button Text',
							},
						]}
					>
						<Input placeholder="Enter value" />
					</Form.Item>
					<Form.Item
						name="listButton.body"
						label="Body"
						labelCol={{ span: 7 }}
						style={{ width: '48%' }}
						rules={[
							{
								required: true,
								message: 'Please enter Body',
							},
						]}
					>
						<TextArea rows={4} placeholder={`Enter text Body`} />
					</Form.Item>
					<Form.Item name="listButton.header" label="Title(Optional)" labelCol={{ span: 7 }} style={{ width: '48%' }}>
						<TextArea rows={2} placeholder={`Enter text Title`} />
					</Form.Item>
					<Form.Item name="listButton.footer" label="Footer(Optional)" labelCol={{ span: 7 }} style={{ width: '48%' }}>
						<TextArea rows={2} placeholder={`Enter text Footer`}
						/>
					</Form.Item>
				</div>

				{listButtonJSON.sectionDetails.map((sectionRec, index) => {
					return (
						<Collapse activeKey={activeKey} onChange={handleChange}>
							<Collapse.Panel
								header={
									<div
										style={{
											display: 'flex',
											justifyContent: 'space-between',
										}}
									>
										<span>{'Section ' + parseInt(index + 1)}</span>
										<Button type="text" icon={<DeleteOutlined />} onClick={() => handleDeleteSection(index)} />
									</div>
								}
								key={index}
							>
								<Card>
									<Form.Item
										name={sectionRec.titleName}
										label={sectionRec.title}
										rules={[
											{
												required: sectionRec.isRequired,
												message: 'Enter values',
											},
										]}
									>
										<TextArea placeholder={`Enter section Title`} rows={2} />
									</Form.Item>
									<Form.Item>
										<div
											style={{
												display: 'flex',
												justifyContent: 'space-between',
												width: '100%',
												flexFlow: 'row wrap',
											}}
										>
											{sectionRec.itemJSON.map((itemRec, itemIndex) => {
												return (
													<>
														<Form.Item
															name={itemRec.itemTitleName}
															label={itemRec.itemTitle}
															rules={[
																{
																	required: itemRec.isItemTitleRequired,
																	message: 'Enter values',
																},
															]}
															style={{ width: '30%' }}
														>
															<Input placeholder="Enter value" />
														</Form.Item>
														<Form.Item
															name={itemRec.itemDescName}
															label={itemRec.itemDescTitle}
															rules={[
																{
																	required: itemRec.isItemDescRequired,
																	message: 'Enter values',
																},
															]}
															style={{ width: '50%' }}
														>
															<TextArea rows={2} placeholder={`Enter item description`}
															/>
														</Form.Item>
														<Form.Item style={{ width: 'auto' }}>
															<Button type="text" icon={<DeleteOutlined />} onClick={() => handleDeleteItem(itemIndex, index)} />
														</Form.Item>
													</>
												);
											})}
										</div>
									</Form.Item>
									<Form.Item>
										<Button title="Add Item" onClick={() => addNewItem(index)}>
											Add Item
										</Button>
									</Form.Item>
								</Card>
							</Collapse.Panel>
						</Collapse>
					);
				})}
				<Form.Item>
					<Button style={{ marginTop: 10 }} title="Add Item" onClick={() => addNewSection()}>
						Add Section
					</Button>
				</Form.Item>
			</Card>
		</Form.Item>
	);
};

export default SendListButtonMessage;

import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Col, Flex, Row, Table, Typography } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getDateFromTimeStamp } from "../../constants/DateConstants";
import AddUpdateCannedMessage from "./AddUpdateCannedMessage";
import { getApi } from "../../constants/ApiConstant";
import MessageReportingPreview from "../MessagePreview/MessageReporingPreview";
import OutSendMessagePreview from "../MessagePreview/OutSendMessagePreview";

const CannedMessage = () => {
  const [isAddUpdate, setIsAddUpdate] = useState(false);
  const [cannedMessageList, setCannedMessageList] = useState([]);
  const [editCannedMessage, setEditCannedMessage] = useState({});
  const selectedPhoneRec = useSelector((state) => state.selectedPhoneRec);
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Creation Time",
      dataIndex: "creationTime",
      key: "creationTime",
      sorter: true,
      defaultSortOrder: "descend",
      render: (_, record) => (
        <Typography.Text>
          {getDateFromTimeStamp(record.creationTime)}
        </Typography.Text>
      ),
    },
    {
      title: "Update time",
      dataIndex: "lastUpdateTime",
      key: "lastUpdateTime",
      render: (_, record) => (
        <Typography.Text>
          {getDateFromTimeStamp(record.lastUpdateTime)}
        </Typography.Text>
      ),
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      render: (_, record) => (
        <Flex gap="small">
          <Button onClick={() => editHandler(record)}>
            <EditOutlined />
          </Button>
          <Button onClick={() => deleteHandler(record.id)}>
            <DeleteOutlined />
          </Button>
        </Flex>
      ),
    },
  ];
  const editHandler = (rec) => {
    setIsAddUpdate(true);
    setEditCannedMessage({ id: rec.id, name: rec.name });
  };
  const deleteHandler = (deleteId) => {
    console.log(deleteId);
  };
  useEffect(() => {
    if (!isAddUpdate) {
      getpreComposedMessageList();
    }
  }, [isAddUpdate, selectedPhoneRec]);
  const getpreComposedMessageList = async () => {
    const response = await getApi("/api/v1/message/preComposed/list");
    if (response) {
      setCannedMessageList(response.data);
    }
  };
  const createCannedMessage = () => {
    setIsAddUpdate(true);
    setEditCannedMessage({});
  };
  const hideCannedMessage = () => {
    setIsAddUpdate(false);
    setEditCannedMessage({});
  };
  return (
    <>
      {!isAddUpdate && (
        <Flex vertical gap={"large"}>
          <Flex justify="space-between">
            <Typography.Title level={4} style={{ margin: 0 }}>
              Canned Messages
            </Typography.Title>
            <Button type="primary" onClick={createCannedMessage}>
              Create Canned Message
            </Button>
          </Flex>
          <Table
            columns={columns}
            dataSource={cannedMessageList}
            expandable={{
              expandedRowRender: (record) => (
                <Row style={{ alignItems: "center" }}>
                  <Col span={10} offset={1}>
                    <OutSendMessagePreview data={record.message} />
                  </Col>
                </Row>
              ),

              rowExpandable: (record) => record.name !== "Not Expandable",
            }}
            rowKey={(record) => record.id}
          />
        </Flex>
      )}
      {isAddUpdate && (
        <AddUpdateCannedMessage
          hideCannedMessage={hideCannedMessage}
          jsonData={editCannedMessage}
        />
      )}
    </>
  );
};

export default CannedMessage;

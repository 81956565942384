export const createBodyPayload = (type, text, variableCountJSON) => {
  if (variableCountJSON && variableCountJSON.length > 0) {
    return {
      type,
      text,
      example: {
        body_text: [[...variableCountJSON]],
      },
    };
  } else {
    return {
      type,
      text,
    };
  }
};

export const createHeaderPayload = (type, format, text, countValue) => {
  if (format === "TEXT" && countValue) {
    return {
      type,
      format,
      text,
      example: {
        header_text: [countValue],
      },
    };
  } else if (format === "TEXT" && !countValue) {
    return {
      type,
      format,
      text,
    };
  } else {
    return {
      type,
      format,
    };
  }
};
export const createCarouselPayload = (
  templateBodyJSON,
  templateJSON,
  templateData
) => {
  const bodyPayload = createBodyPayload(
    templateBodyJSON.type,
    templateBodyJSON.bodyContent,
    templateBodyJSON?.variableCountJSON
  );
  let payload = {
    name: templateJSON.name,
    language: templateJSON.language,
    category: templateJSON.category,
    components: [
      {
        ...bodyPayload,
      },
    ],
    files: [],
  };
  let cardObj = {
    type: templateJSON.type,
    cards: [],
  };
  for (let i = 0; i < Object.keys(templateData).length; i++) {
    const key = Object.keys(templateData)[i];
    let obj = {
      components: [],
    };
    const bodyPayload = createBodyPayload(
      "BODY",
      templateData[key].templateBody.bodyContent,
      templateData[key].templateBody?.variableCountJSON
    );
    if (
      templateData[key].templateHeader.type === "IMAGE" ||
      templateData[key].templateHeader.type === "VIDEO"
    ) {
      if (!templateData[key].templateHeader.fileUrl) {
        const imageObj = {
          position: i,
          base64File: {
            name: templateData[key].templateHeader.fileList[0].name,
            body: extractBase64Body(
              templateData[key].templateHeader.type,
              templateData[key].templateHeader.fileList[0].base64
            ),
          },
        };

        function extractBase64Body(type, base64Data) {
          switch (type) {
            case "IMAGE":
              return base64Data.replace(/^data:image\/[a-z]+;base64,/, "");
            case "VIDEO":
              return base64Data.replace(
                /^data:video\/[a-zA-Z0-9]+;base64,/,
                ""
              );
            default:
              return base64Data;
          }
        }
        payload.files.push(imageObj);
      } else {
        const fileUrlObj = {
          position: i,
          fileUrl: templateData[key].templateHeader.fileUrl,
        };
        payload.files.push(fileUrlObj);
      }

      const headerPayload = createHeaderPayload(
        "HEADER",
        templateData[key].templateHeader.type,
        templateData[key].templateHeader.name,
        ""
      );
      obj.components.push(headerPayload);
    } else {
      const headerPayload = createHeaderPayload(
        "HEADER",
        templateData[key].templateHeader.type,
        templateData[key].templateHeader.name,
        templateData[key].templateHeader?.headerCount
      );
      obj.components.push(headerPayload);
    }

    obj.components.push(bodyPayload);
    let buttonObj = {
      type: "BUTTONS",
      buttons: [],
    };
    for (let k = 0; k < templateData[key].templateButton.length; k++) {
      if (templateData[key].templateButton[k].type === "QUICK REPLY") {
        let buttonPayload = {
          type: "QUICK_REPLY",
          text: templateData[key].templateButton[k].text,
        };
        buttonObj.buttons.push(buttonPayload);
      } else if (templateData[key].templateButton[k].type === "URL") {
        let buttonPayload = {
          type: "URL",
          text: templateData[key].templateButton[k].urlButtonText,
          url: templateData[key].templateButton[k].WebSiteUrl,
        };
        buttonObj.buttons.push(buttonPayload);
      }
    }
    obj.components.push(buttonObj);
    cardObj.cards.push(obj);
  }
  payload.components.push(cardObj);
  return payload;
};
export const createPayload = (templateData, templateJSON) => {
  const key = Object.keys(templateData)[0];
  const payload = {
    name: templateJSON.name,
    language: templateJSON.language,
    category: templateJSON.category,
    components: [],
  };
  if (templateJSON.footer) {
    let obj = {
      type: "FOOTER",
      text: templateJSON.footer,
    };
    payload.components.push(obj);
  }
  const bodyPayload = createBodyPayload(
    "BODY",
    templateData[key].templateBody.bodyContent,
    templateData[key].templateBody?.variableCountJSON
  );
  payload.components.push(bodyPayload);
  if (
    templateData[key].templateHeader.type === "IMAGE" ||
    templateData[key].templateHeader.type === "VIDEO" ||
    templateData[key].templateHeader.type === "DOCUMENT"
  ) {
    if (templateData[key].templateHeader.type === "IMAGE") {
      payload.base64File = {
        name: templateData[key].templateHeader.fileList[0].name,
        body: templateData[key].templateHeader.fileList[0].base64.replace(
          /^data:image\/[a-z]+;base64,/,
          ""
        ),
      };
    } else if (templateData[key].templateHeader.type === "VIDEO") {
      payload.base64File = {
        name: templateData[key].templateHeader.fileList[0].name,
        body: templateData[key].templateHeader.fileList[0].base64.replace(
          /^data:video\/[a-zA-Z0-9]+;base64,/,
          ""
        ),
      };
    } else if (templateData[key].templateHeader.type === "DOCUMENT") {
      payload.base64File = {
        name: templateData[key].templateHeader.fileList[0].name,
        body: templateData[key].templateHeader.fileList[0].base64.replace(
          /^data:application\/[a-zA-Z0-9.-]+;base64,/,
          ""
        ),
      };
    }

    const headerPayload = createHeaderPayload(
      "HEADER",
      templateData[key].templateHeader.type,
      templateData[key].templateHeader.name,
      ""
    );
    payload.components.push(headerPayload);
  }
  let buttonObj = {
    type: "BUTTONS",
    buttons: [],
  };
  for (let k = 0; k < templateData[key].templateButton.length; k++) {
    if (
      templateData[key].templateButton[k].type === "QUICK REPLY" ||
      templateData[key].templateButton[k].type === "FLOW"
    ) {
      let buttonPayload = {
        type:
          templateData[key].templateButton[k].type === "QUICK REPLY"
            ? "QUICK_REPLY"
            : templateData[key].templateButton[k].type,
        text: templateData[key].templateButton[k].text,
      };
      if (templateData[key].templateButton[k].type === "FLOW") {
        buttonPayload.flow_id = templateData[key].templateButton[k].flow_id;
        buttonPayload.flow_action =
          templateData[key].templateButton[k].flow_action;
        buttonPayload.navigate_screen =
          templateData[key].templateButton[k].navigate_screen;
      }
      buttonObj.buttons.push(buttonPayload);
    } else if (templateData[key].templateButton[k].type === "URL") {
      let buttonPayload = {
        type: "URL",
        text: templateData[key].templateButton[k].urlButtonText,
        url: templateData[key].templateButton[k].WebSiteUrl,
      };
      buttonObj.buttons.push(buttonPayload);
    }
  }
  payload.components.push(buttonObj);
  return payload;
};

export const handleHeaderChange = (
  id,
  updatedHeaderData,
  templateData,
  setTemplateData
) => {
  const newTemplateData = {
    ...templateData,
    [id]: { ...templateData[id], templateHeader: updatedHeaderData },
  };
  setTemplateData(newTemplateData);
};
export const handleBodyChange = (
  id,
  updatedBodyData,
  templateData,
  setTemplateData
) => {
  const newTemplateData = {
    ...templateData,
    [id]: { ...templateData[id], templateBody: updatedBodyData },
  };
  setTemplateData(newTemplateData);
};
export const handleButtonChange = (
  id,
  updatedButtonData,
  templateData,
  setTemplateData
) => {
  const newTemplateData = {
    ...templateData,
    [id]: { ...templateData[id], templateButton: updatedButtonData },
  };
  setTemplateData(newTemplateData);
};

export const validateParentheses = (str) => {
  const openingCount = (str.match(/[\(\[\{]/g) || []).length;
  const closingCount = (str.match(/[\)\]\}]/g) || []).length;
  return openingCount === closingCount;
};
export const checkVariableNumber = (arr) => {
  let countObj = 0;
  let updatedStr = "";
  for (let i = 0; i < arr.length; i++) {
    var pattern = /\{\{(\d+)/;
    var match = arr[i].match(pattern);
    if (match) {
      if (countObj + 1 === parseInt(match[0])) {
        countObj = parseInt(match[0]);
        updatedStr = updatedStr + arr[i] + "}}";
      } else {
        countObj = countObj + 1;
        arr[i] = arr[i].slice(0, -1) + countObj;
        updatedStr = updatedStr + arr[i] + "}}";
      }
    } else {
      updatedStr = updatedStr + arr[i];
    }
  }
  return updatedStr;
};

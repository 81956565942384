import { Card, Col, Form, Input, Row, notification } from 'antd';
import React, { useImperativeHandle } from 'react';

const SendLocationMessage = (props) => {
	const { childFormRef, formData, messageForm } = props;
	const validationFormField = (values) => {
		if (!values.name) {
			notification.error({
				message: 'Error',
				description: 'Please Enter Name!!',
			});
			return false;
		}
		if (!values.address) {
			notification.error({
				message: 'Error',
				description: 'Please Enter Address!!',
			});
			return false;
		}
		if (!values.latitude) {
			notification.error({
				message: 'Error',
				description: 'Please Enter Latitude!!',
			});
			return false;
		}
		if (!values.longitude) {
			notification.error({
				message: 'Error',
				description: 'Please Enter Longitude!!',
			});
			return false;
		}
		return true;
	};

	const getFormData = () => {
		return new Promise((resolve, reject) => {
			messageForm
				.validateFields()
				.then((values) => {
					const isValid = validationFormField(values);
					if (!isValid) {
						return true;
					}

					formData.append('location.longitude', values.longitude);
					formData.append('location.latitude', values.latitude);
					formData.append('location.name', values.name);
					formData.append('location.address', values.address);
					resolve(formData);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};
	const resetFormField = () => {
		messageForm.resetFields();
	};
	useImperativeHandle(childFormRef, () => ({
		formData: getFormData,
		resetData: resetFormField,
	}));
	return (
		<div
			style={{
				padding: '20px 0px',
			}}
		>
			<Row>
				<Col xs={24} sm={15} md={15} lg={15} xl={15}>
					<Card style={{ width: '100%', marginBottom: 20 }}>
						<Form.Item label="Name" name="name">
							<Input placeholder="Enter Name..." />
						</Form.Item>
						<Form.Item label="Address" name="address">
							<Input placeholder="Enter Address..." />
						</Form.Item>
						<Form.Item label="Latitude" name="latitude">
							<Input placeholder="Enter Latitude..." />
						</Form.Item>
						<Form.Item label="Longitude" name="longitude">
							<Input placeholder="Enter Longitude..." />
						</Form.Item>
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default SendLocationMessage;

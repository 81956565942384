const DocumentMessagePreview = ({ data }) => {
  return (
    <div>
      {!data.link && (
        <div className="media-container document-placeholder"></div>
      )}
      {data.link && (
        <div className="media-container">
          <iframe
            src={data.link}
            width="100%"
            height="100%"
            title={data.filename}
          />
        </div>
      )}
      {data.caption && <pre>{data.caption}</pre>}
    </div>
  );
};

export default DocumentMessagePreview;
